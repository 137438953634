import { FaInfo} from 'react-icons/fa'
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip'
import { Button } from '@progress/kendo-react-buttons'
import { useState, useRef } from 'react'

const CompanyContactInfoCell = props => {
  const [show, setShow] = useState(false)
  const refTd = useRef(null)
  if (props.rowType === 'data') {
    const dataItem = props.dataItem
    const contactId = props.dataItem['id']
    const fullName = props.dataItem['title']
      ? `${props.dataItem['title']} ${props.dataItem['firstname']} ${props.dataItem['lastname']}`
      : `${props.dataItem['firstname']} ${props.dataItem['lastname']}`

    return (
      <td {...props} ref={refTd}>
        <span className="flex flex-row items-center">
          <FaInfo
            className="mr-2 cursor-pointer"
            onClick={(test, e) => {
              setShow(true)
            }}
          />
          <span
            className="truncate"
            style={{ whiteSpace: 'nowrap', width: '100%' }}>
            {contactId}
          </span>
        </span>
        <Popover
          show={show}
          className={'w-96'}
          position={'right'}
          anchor={refTd && refTd.current}
          title={
            <>
              <b className="text-lg">{fullName}</b>
              <span className="text-lg">
                {' '}
                ({props.dataItem['company'].name})
              </span>
            </>
          }>
          Department: {dataItem['department'] ?? 'N/A'}
          <br />
          <br />
          <a
            href={`mailto:${dataItem['email']}`}
            target="_blank"
            rel="noreferrer">
            E-Mail: {dataItem['email']}
          </a>
          <br />
          Phone: {dataItem['phone'] ?? 'N/A'}
          <br />
          Mobile: {dataItem['mobile'] ?? 'N/A'}
          <br />
          <br />
          {dataItem['remark']}
          <PopoverActionsBar>
            <Button onClick={() => setShow(false)}>Close</Button>
          </PopoverActionsBar>
        </Popover>
      </td>
    )
  } else {
    return <td></td>
  }
}
export default CompanyContactInfoCell
