const CompanyContactCell = props => {
  if (props.rowType === 'data') {
    const field = props.field || ''
    const title = props.dataItem[field]?.title
      ? `${props.dataItem[field]?.title} `
      : ''
    const name =
      props.dataItem[field]?.firstname && props.dataItem[field]?.lastname
        ? `${props.dataItem[field]?.firstname} ${props.dataItem[field]?.lastname}`
        : ''
    return (
      <td {...props}>
        <span className="flex flex-row">
          <span
            className="truncate"
            title={title + name}
            style={{ whiteSpace: 'nowrap', width: '100%' }}>
            {props.dataItem[field]?.email ? (
              <a
                href={`/crm/company-contacts?company=${props.dataItem[field]?.company.name}`}
                target="_blank"
                title={title + name}
                rel="noreferrer">
                {title + name}
              </a>
            ) : (
              <span>{title + name}</span>
            )}
          </span>
        </span>
      </td>
    )
  } else {
    return <td></td>
  }
}
export default CompanyContactCell
