import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import { useIssues } from 'hooks/issuesDetails'
import {
  getTimeFormated,
  getDateFormated,
  getTimeFormatedNotUTC
} from 'lib/convinience'

import { useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import {
  FormDatePicker,
  FormTimePicker,
  FormInput,
  FormCheckbox,
  FormDropDownList,
  FormTextArea,
  FormNumericTextBox,
  FormMaskedTextBox
} from 'components/ReactFormComponents/Index'
import InputError from 'components/InputError'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'
import { useAuth } from 'hooks/auth'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import {
  requiredValidator,
  requiredValidatorMaskedTime
} from 'components/ReactFormComponents/Validators'
import { FaPen, FaTrashAlt } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import DeleteModal from 'components/DeleteModal/Index'
import { useNavigate } from 'react-router-dom'

const Issues = props => {
  const params = useParams()

  const {
    issueHook,
    patchHook,
    postTimeRecord,
    postExpense,
    deleteTimeRecordOrExpense
  } = useIssues(params.issueId)
  const { user } = useAuth()
  const [item, setItem] = useState(0)
  const [docTitle, setDocTitle] = useState('TimeRecord')
  document.title = docTitle
  const [issue, setIssue] = useState([])
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [editItem, setEditItem] = useState(null)
  const [formButtonsDisabled, setFormButtonsDisabled] = useState(false)

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const toggleDeleteModel = item => {
    if (item) {
      setEditItem(item)
    } else {
      setEditItem(null)
    }
    setDeleteModalVisible(!deleteModalVisible)
  }
  const handleSelectTab = e => {
    setSelectedTab(e.selected)
  }
  const handleSubmit = (dataItem, e) => {
    dataItem.issueId = params.issueId
    dataItem.userId = user.id
    var issueState = dataItem.issueState.value ?? dataItem.issueState
    dataItem.date = new Date(dataItem.date.setHours(12))
    if (issueState != issue.state) {
      patchHook({ dataItem: { state: String(issueState), id: issue.id } })
      setIssue({ ...issue, state: issueState })
    }
    if (e.target.dataset.item == 'timeRecord') {
      var tmpStart = dataItem.startTime
      var tmpEnd = dataItem.endTime
      dataItem.startTime = getTimeFromString(dataItem.startTime)
      dataItem.endTime = getTimeFromString(dataItem.endTime)
      postTimeRecord({ setErrors, setStatus, dataItem })
      dataItem.startTime = tmpStart
      dataItem.endTime = tmpEnd
    } else {
      postExpense({ setErrors, setStatus, dataItem })
    }
    if (isMobile) navigate('/crm/mobile-time-tracking' + `?eventId=${issue.event?.id}`)
  }

  const handleItemsUpdate = (type, formItem) => {
    if (formItem.startTime) {
      setIssue({
        ...issue,
        timeRecords: [...issue.timeRecords, formItem]
      })
    } else {
      setIssue({
        ...issue,
        expenses: [...issue.expenses, formItem]
      })
    }
  }
  useEffect(() => {
    issueHook ? setIssue(issueHook?.data) : null
    setDocTitle(
      `TR ${issueHook?.data.event?.name}: ${issueHook?.data.label}` ??
        'TimeRecord'
    )
  }, [issueHook])

  useEffect(() => {
    if (status?.status == 201) {
      handleItemsUpdate('add', status.data.data)
      setStatus(null)
      setItem(item + 1)
    }
  }, [status])
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">
            {issue.event?.name ?? ''}: {issue.label}
          </Label>
          <DeleteModal
            modalVisible={deleteModalVisible}
            toggleModal={toggleDeleteModel}
            item={editItem}
            deleteHook={deleteTimeRecordOrExpense}
            formButtonsDisabled={formButtonsDisabled}
            setFormButtonsDisabled={setFormButtonsDisabled}
            details={
              selectedTab == 0
                ? [
                  { id: 'id', label: 'ID' },
                  { id: 'workedTime', label: 'WorkedTime:' },
                  { id: 'invoiceText', label: 'Invoice:' },
                  { id: 'remark', label: 'Remark:' }
                ]
                : [
                  { id: 'id', label: 'ID' },
                  { id: 'amount', label: 'Amount:' },
                  { id: 'invoiceText', label: 'Invoice:' },
                  { id: 'remark', label: 'Remark:' }
                ]
            }
            detailsText={'Also following tables are involved in deletion: -'}
            title={`Delete ${selectedTab == 0 ? 'Timerecord' : 'Expense'}?`}
          />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                xs={12}
                lg={12}
                xl={12}
                display={{ xs: 'none', lg: 'block' }}>
                <Paper className="p-2 flex flex-col shadow-lg">
                  {issue.length != 0 ? (
                    <div className="flex">
                      <table className="issue-2-col ml-2" style={{ flex: 8 }}>
                        <tbody>
                          <tr>
                            <td>Category:</td>
                            <td>
                              {issue.issueCategory?.label
                                ? issue.issueCategory.label
                                : ''}{' '}
                              {issue.issueSubcategory?.label
                                ? `- ${issue.issueSubcategory.label}`
                                : ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Description:</td>
                            <td>{issue.remark}</td>
                          </tr>
                          <tr>
                            <td>Due date:</td>
                            <td>
                              {issue.dueDate
                                ? getDateFormated(new Date(issue.dueDate))
                                : 'No date assigned'}
                            </td>
                          </tr>
                          <tr>
                            <td>State:</td>
                            <td>{issue.state}</td>
                          </tr>
                          <tr>
                            <td>Assigned to:</td>
                            <td>
                              {issue.user?.name
                                ? `${issue.user.name} - ${issue.user.email}`
                                : ''}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="flex flex-col flex-1 border-l">
                        <span className="flex-1 text-center flex justify-center items-center text-2xl border-b">
                          <br />
                          <b>{(issue.totalWorkedTime / 60).toFixed(1)} h</b>
                        </span>
                        <span className="flex-1 text-center flex justify-center items-center text-2xl">
                          <br />
                          <b>{issue.totalExpenses.toFixed(2)} €</b>
                        </span>
                      </div>
                    </div>
                  ) : null}
                </Paper>
              </Grid>
              {issue.length != 0 && (
                <>
                  <Grid
                    xs={6}
                    lg={12}
                    xl={12}
                    display={{ xs: 'block', lg: 'none' }}>
                    <Paper className="p-2 flex flex-col shadow-lg">
                      <b>{(issue.totalWorkedTime / 60).toFixed(1)} h</b>
                    </Paper>
                  </Grid>
                  <Grid
                    xs={6}
                    lg={12}
                    xl={12}
                    display={{ xs: 'block', lg: 'none' }}>
                    <Paper className="p-2 flex flex-col shadow-lg">
                      <b>{issue.totalExpenses.toFixed(2)} €</b>
                    </Paper>
                  </Grid>
                </>
              )}
              <TabStrip
                className="w-full"
                selected={selectedTab}
                onSelect={handleSelectTab}>
                <TabStripTab style={{ width: '100%' }} title="Time Records">
                  {issue.length != 0 ? (
                    <Grid xs={12} lg={8} xl={6}>
                      <Paper className="p-2 flex flex-col shadow-lg">
                        <Form
                          className="w-full"
                          onSubmit={handleSubmit}
                          initialValues={{
                            issueState: issue.state,
                            date: new Date(),
                            startTime: `${`0${new Date().getHours()}`.slice(
                              -2
                            )}:${`0${new Date().getMinutes()}`.slice(-2)}`
                            /* endTime: `${`0${new Date().getHours()}`.slice(
                              -2
                            )}:${`0${new Date().getMinutes()}`.slice(-2)}` */
                          }}
                          key={item}
                          render={formRenderProps => (
                            <FormElement data-item="timeRecord">
                              <fieldset className={'k-form-fieldset'}>
                                <legend className={'k-form-legend'}>
                                  Create new time record:
                                </legend>
                                <Grid
                                  container
                                  spacing={2}
                                  className="flex flex-col">
                                  <Grid xs={12} lg={6} xl={6}>
                                    <div>
                                      <Field
                                        name={'date'}
                                        validator={requiredValidator}
                                        required
                                        component={FormDatePicker}
                                        label={'Date'}
                                        format="dd.MM.yyyy"
                                      />
                                      <InputError
                                        messages={errors?.internId}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid xs={4} lg={2} xl={2}>
                                    <div>
                                      <Field
                                        name={'startTime'}
                                        validator={requiredValidatorMaskedTime}
                                        required
                                        component={FormMaskedTextBox}
                                        label={'Start time'}
                                        mask="00:00"
                                        format={'HH:mm'}
                                        onChange={e => {
                                          if (
                                            requiredValidatorMaskedTime(
                                              formRenderProps.valueGetter(
                                                'startTime'
                                              )
                                            ) == '' &&
                                            requiredValidatorMaskedTime(
                                              formRenderProps.valueGetter(
                                                'endTime'
                                              )
                                            ) == ''
                                          ) {
                                            formRenderProps.onChange(
                                              'testTime',
                                              {
                                                value:
                                                  (getTimeFromString(
                                                    formRenderProps.valueGetter(
                                                      'endTime'
                                                    )
                                                  ) -
                                                    getTimeFromString(
                                                      formRenderProps.valueGetter(
                                                        'startTime'
                                                      )
                                                    )) /
                                                  (1000 * 60)
                                              }
                                            )
                                          }
                                        }}
                                      />
                                      <InputError
                                        messages={errors?.name}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid xs={4} lg={2} xl={2}>
                                    <div
                                      onClick={() => {
                                        if (
                                          formRenderProps.valueGetter(
                                            'endTime'
                                          ) == '__:__' ||
                                          !formRenderProps.valueGetter(
                                            'endTime'
                                          )
                                        ) {
                                          formRenderProps.onChange('endTime', {
                                            value: getTimeFormatedNotUTC(
                                              new Date()
                                            )
                                          })
                                        }
                                        if (
                                          requiredValidatorMaskedTime(
                                            formRenderProps.valueGetter(
                                              'startTime'
                                            )
                                          ) == '' &&
                                          requiredValidatorMaskedTime(
                                            formRenderProps.valueGetter(
                                              'endTime'
                                            )
                                          ) == ''
                                        ) {
                                          formRenderProps.onChange('testTime', {
                                            value:
                                              (getTimeFromString(
                                                formRenderProps.valueGetter(
                                                  'endTime'
                                                )
                                              ) -
                                                getTimeFromString(
                                                  formRenderProps.valueGetter(
                                                    'startTime'
                                                  )
                                                )) /
                                              (1000 * 60)
                                          })
                                        }
                                      }}>
                                      <Field
                                        name={'endTime'}
                                        validator={requiredValidatorMaskedTime}
                                        required
                                        component={FormMaskedTextBox}
                                        mask="00:00"
                                        label={'End time'}
                                        format={'HH:mm'}
                                        onClick={e => {
                                          formRenderProps.onChange('endTime', {
                                            value: getTimeFormated(new Date())
                                          })
                                        }}
                                        onChange={e => {
                                          if (
                                            requiredValidatorMaskedTime(
                                              formRenderProps.valueGetter(
                                                'startTime'
                                              )
                                            ) == '' &&
                                            requiredValidatorMaskedTime(
                                              formRenderProps.valueGetter(
                                                'endTime'
                                              )
                                            ) == ''
                                          ) {
                                            formRenderProps.onChange(
                                              'testTime',
                                              {
                                                value:
                                                  (getTimeFromString(
                                                    formRenderProps.valueGetter(
                                                      'endTime'
                                                    )
                                                  ) -
                                                    getTimeFromString(
                                                      formRenderProps.valueGetter(
                                                        'startTime'
                                                      )
                                                    )) /
                                                  (1000 * 60)
                                              }
                                            )
                                          }
                                        }}
                                      />
                                      <InputError
                                        messages={errors?.name}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid xs={4} lg={2} xl={2}>
                                    <div>
                                      <Field
                                        name={'testTime'}
                                        disabled
                                        component={FormInput}
                                        label={'Minutes'}
                                      />
                                      <InputError
                                        messages={errors?.projectEmails}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid xs={12} lg={12} xl={12}>
                                    <div>
                                      <Field
                                        name={'invoiceText'}
                                        validator={requiredValidator}
                                        required
                                        component={FormInput}
                                        label={'Invoice Text'}
                                      />
                                      <InputError
                                        messages={errors?.projectEmails}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  spacing={2}
                                  className="flex flex-col"></Grid>
                                <Grid xs={12} lg={12} xl={12}>
                                  <div>
                                    <Field
                                      name={'checkRelevant'}
                                      component={FormCheckbox}
                                      label={'Zusatzaufwand'}
                                    />
                                    <InputError
                                      messages={errors?.projectEmails}
                                      className="mt-2"
                                    />
                                  </div>
                                </Grid>
                                <Grid
                                  container
                                  spacing={2}
                                  className="flex flex-col">
                                  {/* <Grid xs={4} lg={4} xl={4}>
                                    <div>
                                      <Field
                                        name={'issueState'}
                                        component={FormDropDownList}
                                        label={'Issue State'}
                                        textField="label"
                                        dataItemKey="value"
                                        data={[
                                          {
                                            value: '0',
                                            label: 'Open'
                                          },
                                          {
                                            value: '1',
                                            label: 'Waiting'
                                          },
                                          {
                                            value: '2',
                                            label: 'Closed'
                                          },
                                          {
                                            value: '3',
                                            label: 'Plan-inital'
                                          }
                                        ]}
                                      />
                                    </div>
                                  </Grid> */}

                                  <Grid xs={12} lg={12} xl={12}>
                                    <div>
                                      <Field
                                        id={'remark'}
                                        optional={true}
                                        name={'remark'}
                                        label={'Comment'}
                                        autoSize={true}
                                        component={FormTextArea}
                                      />
                                      <InputError
                                        messages={errors?.remark}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="k-form-buttons">
                                  <Button
                                    themeColor={'primary'}
                                    type={'submit'}
                                    disabled={!formRenderProps.allowSubmit}>
                                    Create time record
                                  </Button>
                                  <Button onClick={formRenderProps.onFormReset}>
                                    Clear
                                  </Button>
                                </div>
                              </fieldset>
                            </FormElement>
                          )}
                        />
                      </Paper>
                    </Grid>
                  ) : null}
                  {issue.timeRecords
                    ? [...issue.timeRecords].reverse().map(record => (
                      <Grid xs={12} lg={12} xl={12} key={record.id}>
                        <Paper
                          className="p-2 flex flex-col shadow-lg "
                          style={{
                            backgroundColor: `${
                                user?.id == record.user.id ? 'LightGray' : ''
                              }`
                          }}>
                          <div className="flex flex-row w-full">
                            <span className="flex-1 flex justify-center items-center text-xl w-64 border-r">
                              <b>
                                {record.workedTime}
                                  &nbsp;min
                              </b>
                            </span>
                            <table
                              className="ml-2 issue-2-col"
                              style={{
                                flex: 10
                              }}>
                              <tbody>
                                <tr>
                                  <td>{record.user.name}:</td>
                                  <td>{record.remark}</td>
                                </tr>
                                <tr>
                                  <td>Invoice text:</td>
                                  <td>{record.invoiceText}</td>
                                </tr>
                                <tr>
                                  <td>Worktime:</td>
                                  <td>
                                    {getDateFormated(new Date(record.date))}{' '}
                                    {getTimeFormated(
                                      new Date(record.startTime)
                                    )}
                                      -
                                    {getTimeFormated(
                                      new Date(record.endTime)
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <span className="w-12 flex flex-col">
                              <button
                                className="btn btn-secondary-red mr-1 flex-1"
                                onClick={e => {
                                  toggleDeleteModel({
                                    ...record,
                                    action: 'deleteTimeRecord'
                                  })
                                }}>
                                <FaTrashAlt />
                              </button>
                            </span>
                          </div>
                        </Paper>
                      </Grid>
                    ))
                    : ''}
                </TabStripTab>
                <TabStripTab title="Expenses">
                  {issue.length != 0 ? (
                    <Grid xs={12} lg={10} xl={8}>
                      <Paper className="p-2 flex flex-col shadow-lg">
                        <Form
                          className="w-full"
                          onSubmit={handleSubmit}
                          initialValues={{
                            issueState: issue.state,
                            date: new Date()
                          }}
                          key={item}
                          render={formRenderProps => (
                            <FormElement data-item="expense">
                              <fieldset className={'k-form-fieldset'}>
                                <legend className={'k-form-legend'}>
                                  Create new expense:
                                </legend>
                                <Grid
                                  container
                                  spacing={2}
                                  className="flex flex-col">
                                  <Grid xs={6} lg={2} xl={2}>
                                    <div>
                                      <Field
                                        name={'date'}
                                        validator={requiredValidator}
                                        required
                                        component={FormDatePicker}
                                        label={'Date'}
                                        format="dd.MM.yyyy"
                                      />
                                      <InputError
                                        messages={errors?.internId}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid xs={6} lg={2} xl={2}>
                                    <div>
                                      <Field
                                        name={'amount'}
                                        validator={requiredValidator}
                                        required
                                        component={FormNumericTextBox}
                                        format={'n2'}
                                        label={'Amount in €'}
                                      />
                                      <InputError
                                        messages={errors?.internId}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                  <Grid xs={12} lg={8} xl={8}>
                                    <div>
                                      <Field
                                        name={'invoiceText'}
                                        validator={requiredValidator}
                                        required
                                        component={FormInput}
                                        label={'Invoice Text'}
                                      />
                                      <InputError
                                        messages={errors?.projectEmails}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                  {/* <Grid xs={3} lg={2} xl={2}>
                                    <div>
                                      <Field
                                        name={'issueState'}
                                        component={FormDropDownList}
                                        label={'Issue State'}
                                        textField="label"
                                        dataItemKey="value"
                                        data={[
                                          {
                                            value: '0',
                                            label: 'Open'
                                          },
                                          {
                                            value: '1',
                                            label: 'Waiting'
                                          },
                                          {
                                            value: '2',
                                            label: 'Closed'
                                          },
                                          {
                                            value: '3',
                                            label: 'Plan-inital'
                                          }
                                        ]}
                                      />
                                    </div>
                                  </Grid> */}
                                  <Grid xs={12} lg={12} xl={12}>
                                    <div>
                                      <Field
                                        id={'remark'}
                                        optional={true}
                                        name={'remark'}
                                        label={'Comment'}
                                        autoSize={true}
                                        component={FormTextArea}
                                      />
                                      <InputError
                                        messages={errors?.remark}
                                        className="mt-2"
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                <div className="k-form-buttons">
                                  <Button
                                    themeColor={'primary'}
                                    type={'submit'}
                                    disabled={!formRenderProps.allowSubmit}>
                                    Create expense
                                  </Button>
                                  <Button onClick={formRenderProps.onFormReset}>
                                    Clear
                                  </Button>
                                </div>
                              </fieldset>
                            </FormElement>
                          )}
                        />
                      </Paper>
                    </Grid>
                  ) : null}
                  {issue.expenses
                    ? [...issue.expenses].reverse().map(expense => (
                      <Grid xs={12} lg={12} xl={12} key={expense.id}>
                        <Paper
                          className="p-2 flex flex-col shadow-lg"
                          style={{
                            backgroundColor: `${
                                user?.id == expense.user.id ? 'LightGray' : ''
                              }`
                          }}>
                          <div className="flex flex-row">
                            <span className="flex-1 flex justify-center items-center text-xl w-64 border-r">
                              <b>
                                {expense.amount.toFixed(2)}
                                  &nbsp;€
                              </b>
                            </span>
                            <table
                              className="ml-2 issue-2-col"
                              style={{
                                flex: 10
                              }}>
                              <tbody>
                                <tr>
                                  <td>{expense.user.name}:</td>
                                  <td>{expense.remark}</td>
                                </tr>
                                <tr>
                                  <td>Invoice text:</td>
                                  <td>{expense.invoiceText}</td>
                                </tr>
                                <tr>
                                  <td>Date:</td>
                                  <td>
                                    {getDateFormated(new Date(expense.date))}{' '}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <span className="w-12 flex flex-col">
                              <button
                                className="btn btn-secondary-red mr-1 flex-1"
                                onClick={e => {
                                  toggleDeleteModel({
                                    ...expense,
                                    action: 'deleteExpense'
                                  })
                                }}>
                                <FaTrashAlt />
                              </button>
                            </span>
                          </div>
                        </Paper>
                      </Grid>
                    ))
                    : ''}
                </TabStripTab>
              </TabStrip>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Issues

const getTimeFromString = timeString =>
  new Date(
    new Date(new Date().setHours(timeString.slice(0, 2))).setMinutes(
      timeString.slice(3, 5)
    )
  )
