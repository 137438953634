import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useEvents = ({ query }) => {
  const {
    data: eventsHook,
    error,
    mutate
  } = useSWR(`/api/v1/events${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/events${query ? query : ''}`)
      .then(res => {
        var parsedData = res.data.data.map(event => {
          event.startDate = event.startDate ? new Date(event.startDate) : null
          event.endDate = event.endDate ? new Date(event.endDate) : null
          return event
        })
        return { data: parsedData }
      })
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const putEvent = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/events/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postEvent = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/events', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }

  const deleteEvent = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)

    axios.delete(`/api/v1/events/${props.dataItem.id}`).then(status => {
      setStatus(status)
      mutate()
    })
  }

  const getEvents = async ({ query }) =>
    axios
      .get(`/api/v1/events${query ? query : ''}`)
      .then(res => {
        var parsedData = res.data.data.map(event => {
          event.startDate = event.startDate ? new Date(event.startDate) : null
          event.endDate = event.endDate ? new Date(event.endDate) : null
          return event
        })
        return { data: parsedData }
      })
      .catch(error => error.response)

  const getEvent = async ({ eventId, setEvent }) => {
    await csrf()
    axios
      .get(`/api/v1/events/${eventId}`)
      .then(res => {
        var event = res.data.data
        event.startDate = event.startDate ? new Date(event.startDate) : null
        event.endDate = event.endDate ? new Date(event.endDate) : null

        setEvent(event)
      })
      .catch(error => error.response)
  }

  useEffect(() => {}, [eventsHook, error])

  return {
    eventsHook,
    putEvent,
    postEvent,
    deleteEvent,
    getEvent,
    getEvents
  }
}
