import {
  Grid as DataGrid,
  GridColumn as Column
} from '@progress/kendo-react-grid'
import { ColumnMenu } from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import { useTranslation } from 'react-i18next'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import { FaFileDownload } from 'react-icons/fa'
import { leadingZero, getTotalNet, getDateFormated } from 'lib/convinience'

const InvoicesDataGrid = props => {
  const { t } = useTranslation(['crm'])
  const handleDataItem = dataItem => {
    dataItem.state = dataItem.state?.value ?? dataItem.state
    return dataItem
  }

  const CommandCellStart = props2 => (
    <td {...props2}>
      <button
        className="btn btn-blue mr-1"
        onClick={() => props.downloadPDF(props2)}>
        <FaFileDownload />
      </button>
      {props2.dataItem.state == 2 && (
        <button
          className="btn btn-red mr-1"
          onClick={() => props.downloadPDF({ ...props2, storno: true })}>
          <FaFileDownload />
        </button>
      )}
    </td>
  )

  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        KendoForm={KendoForm}
        noRecords="No issues found!"
        itemTitle="Issue"
        noDelete={true}
        redirectUrl={'/crm/issues'}
        initialGroups={props.initialGroups}
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'orderID', label: 'Order ID:' },
          { id: 'accountingArea', label: 'Accounting Area:' }
        ]}
        handleDataItem={handleDataItem}
        deleteDetailText="Also following tables are involved in deletion: Timerecords, Expenses">
        <Column cell={CommandCellStart} width="80px" />
        <Column
          field="orderId"
          title="Rechnungsnummer"
          filter="date"
          width="150px"
          cell={props => (
            <td {...props}>
              {props.dataItem.accountingArea}
              {leadingZero(props.dataItem.orderId, 6)}
            </td>
          )}
          columnMenu={ColumnMenu}
        />

        <Column
          field="recipientData"
          title={'Recipient data'}
          width="400px"
          cell={props => {
            const data = JSON.parse(props.dataItem.invoiceDetails)
            return (
              <td {...props}>
                <span className="truncate">
                  {data.recipientData.split('\n')[0]}
                </span>
              </td>
            )
          }}
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="date"
          title={'Date'}
          width="150px"
          cell={props => {
            const data = JSON.parse(props.dataItem.invoiceDetails)
            return <td {...props}>{getDateFormated(new Date(data.date))}</td>
          }}
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="totalAmount"
          title={'Amount €'}
          width="150px"
          cell={props => {
            const data = JSON.parse(props.dataItem.invoiceDetails)
            return (
              <td {...props}>
                {props.dataItem.state == '2' && '-'}
                {getTotalNet(data.items, data.vat)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                €
              </td>
            )
          }}
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="state"
          title={'State'}
          width="100px"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="accountingArea"
          title={'AccountingArea'}
          width="150px"
          cell={TruncatedTooltipCell}
          className="truncate"
          columnMenu={ColumnMenu}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default InvoicesDataGrid
