import {
  FormTextArea,
  FormDatePicker,
  FormDropDownList,
  FormRadioGroup
} from 'components/ReactFormComponents/Index'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'
import InputError from 'components/InputError'
import { useState, useEffect, cloneElement } from 'react'
import axios from 'lib/axios'
import { filterChange } from 'components/FormsDropdownFilter/Index'
import { useAuth } from 'hooks/auth'

const KendoForm = props => {
  const { user } = useAuth()
  const { errors, handleSubmit, item, mode, cancleEditModal } = props
  const [companies, setCompanies] = useState([])
  const [companiesFiltered, setCompaniesFiltered] = useState([])
  const [events, setEvents] = useState([])
  const [eventsFiltered, setEventsFiltered] = useState([])
  const [companyContacts, setCompanyContacts] = useState([])
  const [users, setUsers] = useState([])
  const [filteredCompanyContacts, setFilteredCompanyContacts] = useState([])
  useEffect(() => {
    if (item && item.length != 0 && companies.length != 0) {
      var test = companies.filter(
        company => company.id == item.companyContact.companyId
      )
      setFilteredCompanyContacts(test[0].companyContacts)
    } else {
      setFilteredCompanyContacts([])
    }
  }, [item, companies])
  useEffect(async () => {
    var dataCompanies = await axios.get(
      '/api/v1/companies?order_by=name&includeCompanyContacts=true'
    )
    setCompanies(dataCompanies.data.data)
    setCompaniesFiltered(dataCompanies.data.data)
    var dataCompanyContacts = await axios.get(
      '/api/v1/company-contacts?order_by=lastname'
    )
    setCompanyContacts(dataCompanyContacts.data.data)
    var dataUsers = await axios.get(
      '/api/v1/users?order_by=name&is_active[eq]=1'
    )
    setUsers(dataUsers.data.data)
    var dataEvents = await axios.get('/api/v1/events?order_by=-name')
    setEvents(dataEvents.data.data)
    setEventsFiltered(dataEvents.data.data)
  }, [])
  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      initialValues={{
        ...item,
        companyId: item?.companyContact?.companyId ?? null,
        date: item?.date ?? new Date(),
        userId: item?.userId ?? user.id
      }}
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 800
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'userId'}
                  component={FormDropDownList}
                  itemRender={itemRender}
                  valueRender={valueRender}
                  label={'Employee'}
                  textField="name"
                  dataItemKey="id"
                  data={users}
                />
                <InputError
                  messages={errors.data?.errors?.eventId}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'date'}
                  component={FormDatePicker}
                  label={'Date'}
                  format="dd.MM.yyyy"
                />
                <InputError messages={errors?.name} className="mt-2" />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'eventId'}
                  component={FormDropDownList}
                  filterable={true}
                  onFilterChange={event =>
                    filterChange(event, events, setEventsFiltered)
                  }
                  label={'Event'}
                  textField="name"
                  dataItemKey="id"
                  data={eventsFiltered}
                />
                <InputError
                  messages={errors.data?.errors?.eventId}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'companyId'}
                  component={FormDropDownList}
                  filterable={true}
                  onFilterChange={event =>
                    filterChange(event, companies, setCompaniesFiltered)
                  }
                  label={'Company'}
                  textField="name"
                  dataItemKey="id"
                  data={companiesFiltered}
                  onChange={test => {
                    var tmp = companyContacts.filter(
                      contact => contact.companyId == test.value.id
                    )
                    setFilteredCompanyContacts(tmp)
                  }}
                />
                <InputError
                  messages={errors.data?.errors?.companyId}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                name={'companyContactId'}
                component={FormDropDownList}
                itemRender={itemRender}
                valueRender={valueRender}
                label={'Company contact'}
                disabled={filteredCompanyContacts.length == 0}
                textField="firstname"
                dataItemKey="id"
                data={filteredCompanyContacts}
              />
              <InputError
                messages={errors.data?.errors?.eventId}
                className="mt-2"
              />
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'contactType'}
                  component={FormRadioGroup}
                  layout="horizontal"
                  label={'Type of contact'}
                  textField="label"
                  dataItemKey="value"
                  data={[
                    { value: '0', label: 'Telefon' },
                    { value: '1', label: 'Mail' }
                  ]}
                />
                <InputError messages={errors?.internId} className="mt-2" />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'resubmissionDate'}
                  component={FormDatePicker}
                  label={'Resubmission date'}
                  format="dd.MM.yyyy"
                />
                <InputError messages={errors?.name} className="mt-2" />
              </div>
            </div>
            <div className="mb-3">
              <Field
                id={'remark'}
                name={'remark'}
                label={'Comment'}
                optional={true}
                autoSize={true}
                component={FormTextArea}
              />
              <InputError messages={errors?.remark} className="mt-2" />
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <Button onClick={cancleEditModal} type={'button'}>
                Cancel
              </Button>
              {mode == 'update' ? (
                <Button
                  themeColor={'primary'}
                  type={'submit'}
                  disabled={!formRenderProps.allowSubmit}>
                  Update sponsoring contactpoint
                </Button>
              ) : (
                <Button
                  themeColor={'primary'}
                  type={'submit'}
                  disabled={!formRenderProps.allowSubmit}>
                  Create sponsoring contactpoint
                </Button>
              )}
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm

const itemRender = (li, itemProps) => {
  const itemChildren = (
    <span>
      {itemProps.dataItem.id} - {li.props.children}{' '}
      {itemProps.dataItem.lastname}
    </span>
  )
  return cloneElement(li, li.props, itemChildren)
}
const valueRender = (element, value) => {
  if (!value) {
    return element
  }
  const children = [
    <span key={2}>
      {value.id} - {element.props.children} {value.lastname}
    </span>
  ]
  return cloneElement(
    element,
    {
      ...element.props
    },
    children
  )
}
