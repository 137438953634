import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useIssues } from 'hooks/issues'
import { useEffect, useState } from 'react'
import { getHoursFromMin } from 'lib/convinience'



const EventsTimeOverview = () => {
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  const { issuesHook } = useIssues({
    query: '?includeEvent=true&priority[eq]=4'
  })
  document.title = 'Events Time Overview'

  useEffect(() => {
    if (issuesHook) {
      let parsedData = parseData(issuesHook.data)
      console.log(parsedData)
      if (parsedData && parsedData.length != 0) {
        let cols = []
        Object.entries(parsedData[0]).forEach(([key, value]) => {
          cols.push({ field: key, width: key =='Event' ?200:125 })
        })
        setColumns(cols)
        setRows(parsedData)
      }
    }
  }, [issuesHook])
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">
            Events Time Overview
          </Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="px-2 py-2">
                  <Box sx={{ height: '700px', width: '100%' }}>
                    <DataGrid
                      sx={{'& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                        borderRight: '1px solid #f0f0f0'
                      }}}
                      getRowId={row => row.Event}
                      columns={columns}
                      rows={rows}
                      variant="outlined"
                      borderRight={1}
                      slots={{ toolbar: GridToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true
                        }
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default EventsTimeOverview

const parseData = data => {
  let events = data
    .map(issue => {
      return { id: issue.event?.id, name: issue.event?.name }
    })
    .filter(
      (obj, index, self) => index === self.findIndex(t => t.id === obj.id)
    )
  let pivotData = data
    .map(issue => issue.label)
    .filter(
      (obj, index, self) => index === self.findIndex(t => t === obj)
    ).sort()
  console.log(pivotData)
  return events.map(event => {
    var pData = {}
    pivotData.map((pivot, key) => {
      let time = data.filter(eq => eq.label == pivot && event.id == eq.event?.id)[0]
      ?.totalWorkedTime
      pData[pivot] =
      time ? getHoursFromMin(time): ''
    })
    return {
      Event: event.name,
      ...pData
    }
  })
}
