import { Link } from 'react-router-dom'

const WelcomeCard = ({ link, label }) => (
  <Link
    to={link}
    class="w-60 mb-2 text-center block mr-2 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
    <h5 class=" text-2xl font-medium tracking-tight text-gray-900 dark:text-white ">
      {label}
    </h5>
  </Link>
)
export default WelcomeCard
