import { PDFExport } from '@progress/kendo-react-pdf'
import {
  getDateFormated,
  leadingZero,
  getNetPrice,
  getTotalNet
} from 'lib/convinience'
import ConferencingLogo from '../assets/Infinite-Science-Conferencing-zweizeilig.png'

const INVOICE_TRANSLATIONS = {
  DE: {
    site: 'Seite',
    from: 'von',
    date: 'Datum',
    invoice: 'Rechnung',
    stornoInvoice: 'Stornorechnung',
    position: 'Pos.',
    description: 'Beschreibung',
    amount: 'Anzahl',
    vat: 'MwSt.',
    unitPrice: 'Einzelpreis',
    netPrice: 'Nettobetrag',
    subTotalNet: 'Zwischensumme netto',
    salesTax: 'Umsatzsteuer',
    totalBrut: 'Rechnungsbetrag brutto',
    periodOfService: 'Leistungszeitraum',
    reference: 'Verwendungszweck'
  },
  EN: {
    site: 'Page',
    from: 'of',
    date: 'Date',
    invoice: 'Invoice',
    stornoInvoice: 'Cancellation invoice',
    position: 'Pos.',
    description: 'Description',
    amount: 'Qty.',
    vat: 'VAT',
    unitPrice: 'Unit price',
    netPrice: 'Net amount',
    subTotalNet: 'Subtotal net',
    salesTax: 'VAT',
    totalBrut: 'Total amount',
    periodOfService: 'Period of service',
    reference: 'Reference'
  }
}

const InvoiceTemplate = ({ invoiceData, pdfExportComponent }) => (
  <PDFExport
    fileName={`${invoiceData.state == '2' ? 'Stornorechnung' : 'Rechnung'}_${
      invoiceData.accountingArea
    }${leadingZero(invoiceData.orderId, 6)}.pdf`}
    ref={pdfExportComponent}
    className="page-container hidden-on-narrow">
    <div className="pdf-page size-a4">
      <div className="inner-page">
        <div style={{ position: 'absolute', right: '8mm', width: '223px' }}>
          <img src={ConferencingLogo} alt="logo" />
        </div>
        {/* ADRESSLINE START */}
        <div
          id="addressLine"
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '31.43mm',
            fontSize: ' 7.5px'
          }}>
          <p>{invoiceData.companyDataOneLine}</p>
        </div>
        {/* ADRESSLINE END */}
        {/* RECIPIENT DATA START */}
        <div
          id="recipient"
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '36.47mm',
            fontSize: ' 9px'
          }}>
          <p style={{ lineHeight: 1.4, whiteSpace: 'pre-wrap' }}>
            {invoiceData.recipientData}
          </p>
        </div>
        {/* RECIPIENT DATA END */}
        {/* WARNING START */}
        <div
          id="recipient"
          style={{
            //fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '13mm',
            fontSize: ' 15px',
            border: '1px solid red',
            borderRadius: '5px',
            padding: '2px'
          }}>
          <p
            style={{
              lineHeight: 1.4,
              whiteSpace: 'pre-wrap',
              color: 'red'
            }}>
            NUR VORSCHAU
            <br />
          </p>
          <p
            style={{
              lineHeight: 1.4,
              whiteSpace: 'pre-wrap',
              color: 'red',
              fontSize: ' 11px'
            }}>
            Die generierte Rechnung kann abweichen.
          </p>
        </div>
        {/* WARNING END */}
        {/* COMPANY DATA START */}
        <div
          id="companyData"
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '109.29mm',
            top: '32mm',
            fontSize: ' 7.5px'
          }}>
          <p style={{ lineHeight: 1.4, whiteSpace: 'pre-wrap' }}>
            {invoiceData.companyData}
          </p>
        </div>
        {/* COMPANY DATA END */}
        {/* DATE START */}
        <div
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '109.29mm',
            top: '63mm',
            fontSize: ' 9px'
          }}>
          <span>{getDateFormated(invoiceData.date)}</span>
        </div>
        {/* DATE END */}
        {/* PAGE START */}
        <div
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '109.29mm',
            top: '59mm',
            fontSize: ' 9px'
          }}>
          <span>
            {' '}
            {INVOICE_TRANSLATIONS[invoiceData.lang]['site']} 1{' '}
            {INVOICE_TRANSLATIONS[invoiceData.lang]['from']} 1
          </span>
        </div>
        {/* PAGE END */}
        {/* INVOICE HEAD START */}
        <div
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '74.44mm',
            fontSize: ' 9px'
          }}>
          <b>
            {invoiceData?.state == '2'
              ? INVOICE_TRANSLATIONS[invoiceData.lang]['stornoInvoice']
              : INVOICE_TRANSLATIONS[invoiceData.lang]['invoice']}{' '}
            {invoiceData.accountingArea}
            {leadingZero(invoiceData.orderId, 5)}
          </b>
        </div>
        {/* INVOICE HEAD END */}
        {/* INVOICE HEAD DESCRIPTION START */}
        <div
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '79.8mm',
            fontSize: ' 9px'
          }}>
          <span style={{ lineHeight: 1.6, whiteSpace: 'pre-wrap' }}>
            {invoiceData.invoiceDescription}
          </span>
        </div>
        {/* INVOICE HEAD DESCRIPTION END */}
        {/* INVOICE POSITIONS TABLE START */}
        <div
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '90.23mm'
          }}>
          <table border="0" cellpadding="1" style={{ width: '481.2px' }}>
            <tbody>
              <tr>
                <td
                  style={{
                    fontSize: '9px',
                    width: '30px',
                    fontFamily: 'DejaVu Sans Condensed'
                  }}>
                  <b>{INVOICE_TRANSLATIONS[invoiceData.lang]['position']}</b>
                </td>
                <td
                  style={{
                    fontSize: '9px',
                    fontFamily: 'DejaVu Sans Condensed'
                  }}>
                  <b>{INVOICE_TRANSLATIONS[invoiceData.lang]['description']}</b>
                </td>
                <td
                  style={{
                    fontSize: '9px',
                    fontFamily: 'DejaVu Sans Condensed',
                    width: '50px',
                    textAlign: 'right'
                  }}>
                  <b>{INVOICE_TRANSLATIONS[invoiceData.lang]['amount']}</b>
                </td>
                {invoiceData.vat !== 0 && (
                  <td
                    style={{
                      width: '40px',
                      fontSize: '9px',
                      fontFamily: 'DejaVu Sans Condensed',
                      textAlign: 'right'
                    }}>
                    <b>{INVOICE_TRANSLATIONS[invoiceData.lang]['vat']}</b>
                  </td>
                )}
                <td
                  style={{
                    fontSize: '9px',
                    fontFamily: 'DejaVu Sans Condensed',
                    width: '60px',
                    textAlign: 'right'
                  }}>
                  <b>{INVOICE_TRANSLATIONS[invoiceData.lang]['unitPrice']}</b>
                </td>
                <td
                  style={{
                    fontSize: '9px',
                    textAlign: 'right',
                    fontFamily: 'DejaVu Sans Condensed',
                    width: '60px'
                  }}>
                  <b>{INVOICE_TRANSLATIONS[invoiceData.lang]['netPrice']}</b>
                </td>
              </tr>
              <tr>
                <td colspan="6">
                  <hr
                    style={{
                      marginBottom: 0,
                      marginTop: 0,
                      fonFtamily: 'DejaVu Sans Condensed'
                    }}
                  />
                </td>
              </tr>
              {invoiceData.items.map((item, index) => (
                <tr>
                  <td
                    style={{
                      fontSize: '9px',
                      width: '30px',
                      fontFamily: 'DejaVu Sans Condensed',
                      verticalAlign: 'top',
                      height: '6.7mm'
                    }}>
                    {index + 1}
                  </td>
                  <td
                    style={{
                      fontFamily: 'DejaVu Sans Condensed',
                      fontSize: '9px',
                      width: '240px',
                      wordWrap: 'break-all',
                      verticalAlign: 'top',
                      height: '6.7mm',
                      whiteSpace: 'pre-wrap'
                    }}>
                    {item.description}
                  </td>
                  <td
                    style={{
                      fontSize: '9px',
                      fontFamily: 'DejaVu Sans Condensed',
                      verticalAlign: 'top',
                      textAlign: 'right',
                      height: '6.7mm'
                    }}>
                    {item.quantity}
                  </td>
                  {invoiceData.vat !== 0 && (
                    <td
                      style={{
                        verticalAlign: 'top',
                        fontSize: '9px',
                        textAlign: 'right',
                        fontFamily: 'DejaVu Sans Condensed',
                        height: '6.7mm'
                      }}>
                      {invoiceData.vat} %
                    </td>
                  )}
                  <td
                    style={{
                      verticalAlign: 'top',
                      fontSize: '9px',
                      fontFamily: 'DejaVu Sans Condensed',
                      textAlign: 'right',
                      height: '6.7mm'
                    }}>
                    {invoiceData?.state == '2' && '-'}
                    {getNetPrice(item.price, 0).toFixed(2).replace('.', ',')} €
                  </td>
                  <td
                    style={{
                      verticalAlign: 'top',
                      fontSize: '9px',
                      textAlign: 'right',
                      fontFamily: 'DejaVu Sans Condensed',
                      height: '6.7mm'
                    }}>
                    {invoiceData?.state == '2' && '-'}
                    {(getNetPrice(item.price, 0) * item.quantity)
                      .toFixed(2)
                      .replace('.', ',')}{' '}
                    €
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="mt-16"
            style={{
              fontFamily: 'DejaVu Sans Condensed',
              position: 'absolute',
              left: '78mm',
              right: '0mm',
              fontSize: '9px'
            }}>
            <table className="w-full">
              <tbody>
                {invoiceData.vat !== 0 && (
                  <tr>
                    <td>
                      {INVOICE_TRANSLATIONS[invoiceData.lang]['subTotalNet']}
                    </td>
                    <td
                      style={{
                        fontSize: '9px',
                        textAlign: 'right',
                        fontFamily: 'DejaVu Sans Condensed',
                        width: '60px'
                      }}>
                      {invoiceData?.state == '2' && '-'}
                      {getTotalNet(invoiceData.items, 0)
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €
                    </td>
                  </tr>
                )}
                {invoiceData.vat !== 0 && (
                  <tr>
                    <td>
                      {INVOICE_TRANSLATIONS[invoiceData.lang]['salesTax']}{' '}
                      {invoiceData.vat} %
                    </td>
                    <td
                      style={{
                        fontSize: '9px',
                        textAlign: 'right',
                        fontFamily: 'DejaVu Sans Condensed',
                        width: '60px'
                      }}>
                      {invoiceData?.state == '2' && '-'}
                      {(
                        getTotalNet(invoiceData.items, invoiceData.vat) -
                        getTotalNet(invoiceData.items, 0)
                      )
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>{INVOICE_TRANSLATIONS[invoiceData.lang]['totalBrut']}</b>
                  </td>
                  <td
                    style={{
                      fontSize: '9px',
                      textAlign: 'right',
                      fontFamily: 'DejaVu Sans Condensed',
                      width: '60px'
                    }}>
                    <b>
                      {invoiceData?.state == '2' && '-'}
                      {getTotalNet(invoiceData.items, invoiceData.vat)
                        .toFixed(2)
                        .replace('.', ',')}{' '}
                      €
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="mt-28"
            style={{
              fontSize: '9px',
              fontFamily: 'DejaVu Sans Condensed',
              width: '481.2px'
            }}>
            {INVOICE_TRANSLATIONS[invoiceData.lang]['periodOfService']}{' '}
            {invoiceData.periodOfService}
          </div>
          <div
            style={{
              fontSize: '9px',
              fontFamily: 'DejaVu Sans Condensed',
              whiteSpace: 'pre-wrap'
            }}>
            <br />
            {invoiceData.selectedPaymentMethodDescription}
            <br />
            {INVOICE_TRANSLATIONS[invoiceData.lang]['reference']}:{' '}
            {INVOICE_TRANSLATIONS[invoiceData.lang]['invoice']}{' '}
            {invoiceData.reference}
          </div>
        </div>
        {/* INVOICE POSITION TABLE END */}

        {/* INVOICE FOOTER START */}
        <div
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '210.53mm',
            fontSize: '6px'
          }}>
          <span>
            Inﬁnite Science GmbH · Lübeck · USt-ID: DE 298 028 360 · Amtsgericht
            Lübeck HRB 14633 HL · Geschäftsführung: Kanina Neideck
          </span>
        </div>
        <div
          style={{
            fontFamily: 'DejaVu Sans Condensed',
            position: 'absolute',
            left: '18.8mm',
            top: '212.86mm',
            fontSize: '6px'
          }}>
          <span>
            Bankverbindung: BIC BYLADEM1001 (DKB) · IBAN DE23 1203 0000 1020
            2838 40
          </span>
        </div>
        {/* INVOICE FOOTER END */}
      </div>
    </div>
  </PDFExport>
)
export default InvoiceTemplate
