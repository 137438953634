import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useVimeoExportData = ({ query }) => {
  const {
    data: vimeoExportDataHook,
    error,
    mutate
  } = useSWR(`/api/v1/vimeo/export-data${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/vimeo/export-data${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const getVimeoExportData = async ({ query }) => {
    const timeRecs = axios
      .get(`/api/v1/vimeo/export-data${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
    return timeRecs
  }

  useEffect(() => {}, [vimeoExportDataHook, error])

  return {
    vimeoExportDataHook,
    getVimeoExportData
  }
}
