import { Button } from '@mui/material'
import * as XLSX from 'xlsx'

const ExportIssues = props => {
  const { exportData, title } = props

  const handleClick = async () => {
    const data = parseData(exportData)
    downloadExcel(data)
  }
  return <Button onClick={handleClick}>{title}</Button>
}

export default ExportIssues

const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const information = XLSX.utils.json_to_sheet([
    {
      State: 0,
      Label: 'Offen'
    },
    {
      State: 1,
      Label: 'Wartend'
    },
    {
      State: 2,
      Label: 'Beendet'
    },
    {
      State: 3,
      Label: 'Plan-Inital'
    }
  ])
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Issues')
  XLSX.utils.book_append_sheet(workbook, information, 'Weitere Informationen')
  XLSX.writeFile(workbook, 'Issues.xlsx')
}

const parseData = data =>
  data.map(issue => ({
    ID: issue.id,
    DueDate: issue.dueDate,
    Titel: issue.label,
    Event: issue.event.name,
    State: issue.state,
    AssignedUser: issue.user.name,
    TotalTime: issue.totalWorkedTime,
    TotalExpenses: issue.totalExpenses,
    Remark: issue.remark,
    LastUpdate: issue.updatedAt,
    Category: issue.issueCategory?.label,
    Subcategory: issue.issueSubcategory?.label
  }))
