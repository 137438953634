import './App.css'
import { Routes, Route, Switch } from 'react-router-dom'
import Dashboard from 'pages/dashboard'
import Login from 'pages/login'
import Register from 'pages/register'
import ForgotPassword from 'pages/forgot-password'
import PasswordReset from 'pages/password-reset'
import NotFoundPage from 'pages/404'
import Crm from 'pages/crm'
import Companies from 'pages/crm/companies'
import CompanyContacts from 'pages/crm/company-contacts'
import Events from 'pages/crm/events'
import Projects from 'pages/crm/projects'
import SponsoringContactPoints from 'pages/crm/sponsoring-contact-points'
import Sponsorings from 'pages/crm/sponsorings'
import Issues from 'pages/crm/issues'
import IssuesDetails from 'pages/crm/issues/issueDetails'
import Welcome from 'pages/welcome'
import CheckLoggedIn from 'pages/check-logged-in'
import UserDashboard from 'pages/user/dashboard'
import ExporteCRM from 'pages/exports/crm'
import DashboardEvents from 'pages/dashboards/events'
import Mailvalidator from 'pages/toolbox/mailvalidator'
import InvoiceCreation from 'pages/toolbox/create-invoice'
import NamebadgeCreation from 'pages/toolbox/create-namebadges'
import TablesignCreation from 'pages/toolbox/create-tablesign'
import CertificateCreation from 'pages/toolbox/create-certificate'
import EventDetails from 'pages/crm/events/eventDetails'
import TimeTracking from 'pages/crm/time-tracking'
import MobileTimeTracking from 'pages/crm/mobile-time-tracking'
import EventsTimeOverview from 'pages/exports/events-time-overview'

function App() {
  return (
    <div className="antialiased">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password-reset/:token" element={<PasswordReset />} />
        <Route path="/toolbox/mailvalidator" element={<Mailvalidator />} />
        <Route path="/toolbox/create-invoice" element={<InvoiceCreation />} />
        <Route
          path="/toolbox/create-namebadges"
          element={<NamebadgeCreation />}
        />
        <Route
          path="/toolbox/create-tablesigns"
          element={<TablesignCreation />}
        />
        <Route
          path="/toolbox/create-certificates"
          element={<CertificateCreation />}
        />
        <Route element={<CheckLoggedIn />}>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user/dashboard" element={<UserDashboard />} />
          <Route path="/crm" element={<Crm />}>
            <Route path="companies" element={<Companies />} />
            <Route path="projects" element={<Projects />} />
            <Route path="events" element={<Events />} />
            <Route path="events/:eventId" element={<EventDetails />} />
            <Route path="company-contacts" element={<CompanyContacts />} />
            <Route path="sponsorings" element={<Sponsorings />} />
            <Route
              path="sponsoring-contact-points"
              element={<SponsoringContactPoints />}
            />
            <Route path="issues" element={<Issues />} />
            <Route path="time-tracking" element={<TimeTracking />} />
            <Route path="mobile-time-tracking" element={<MobileTimeTracking />} />
            <Route path="issues/:issueId" element={<IssuesDetails />} />
          </Route>
          <Route path="/exports" element={<Crm />}>
            <Route path="crm" element={<ExporteCRM />} />
            <Route path="events-time-overview" element={<EventsTimeOverview />} />
          </Route>
          <Route path="/dashboards" element={<Crm />}>
            <Route path="events" element={<DashboardEvents />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
