import {
  FormTextArea,
  FormDropDownList,
  FormNumericTextBox,
  FormCheckbox
} from 'components/ReactFormComponents/Index'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import InputError from 'components/InputError'
import {
  requiredValidator,
} from 'components/ReactFormComponents/Validators'
import { useState, useEffect, cloneElement } from 'react'
import { filterChange } from 'components/FormsDropdownFilter/Index'
import { useTranslation } from 'react-i18next'
import KendoFormButton from 'components/KendoFormButton/Index'

const KendoForm = props => {
  const { t } = useTranslation(['crm'])
  const {
    errors,
    handleSubmit,
    item,
    mode,
    cancleEditModal,
    setItem,
    sponsoringBenefitsHook,
    companyContactsHook,
    eventsHook,
    companiesHook,
    formButtonsDisabled
  } = props
  const [companies, setCompanies] = useState([])
  const [companiesFiltered, setCompaniesFiltered] = useState([])
  const [companyContacts, setCompanyContacts] = useState([])
  const [filteredCompanyContacts, setFilteredCompanyContacts] = useState([])
  const [events, setEvents] = useState([])
  const [eventsFiltered, setEventsFiltered] = useState([])
  const [sponsoringBenefits, setSponsoringBenefits] = useState([])

  useEffect(() => {
    if (
      item &&
      item.length != 0 &&
      companies.length != 0 &&
      !item?.companyId?.id
    ) {
      var test = companies.filter(company => company.id == item.company?.id)
      test[0]?.companyContacts
        ? setFilteredCompanyContacts(test[0].companyContacts)
        : setFilteredCompanyContacts([])
    } else if (
      !(item && item.length != 0 && companies.length != 0) &&
      !item?.companyId?.id
    ) {
      setFilteredCompanyContacts([])
    }
  }, [item, companies])
  useEffect(() => {
    if (mode == 'add') {
      setItem({
        ...item,
        sponsoringBenefits: []
      })
    }
  }, [])
  useEffect(() => {
    setCompanies(companiesHook)
    setCompaniesFiltered(companiesHook)
  }, [companiesHook])
  useEffect(() => {
    setCompanyContacts(companyContactsHook)
  }, [companyContactsHook])
  useEffect(() => {
    setEvents(eventsHook)
    setEventsFiltered(eventsHook)
  }, [eventsHook])

  useEffect(() => {
    setSponsoringBenefits(sponsoringBenefitsHook)
  }, [sponsoringBenefitsHook])
  const test = (e, index) => {
    var values = [...item.sponsoringBenefits]
    values.splice(index, 1)
    setItem({
      ...item,
      sponsoringBenefits: values
    })
  }
  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      ignoreModified={true}
      initialValues={{
        ...item,
        category: item?.category ?? '1'
      }}
      key={item?.sponsoringBenefits?.length}
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 800
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'eventId'}
                  component={FormDropDownList}
                  label={t('sponsorings.form.event')}
                  filterable={true}
                  onFilterChange={event =>
                    filterChange(event, events, setEventsFiltered)
                  }
                  textField="name"
                  onChange={e => {
                    setItem({
                      ...item,
                      eventId: e.value
                    })
                  }}
                  dataItemKey="id"
                  data={eventsFiltered}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.eventId}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'companyId'}
                  component={FormDropDownList}
                  label={t('sponsorings.form.company')}
                  filterable={true}
                  onFilterChange={event =>
                    filterChange(event, companies, setCompaniesFiltered)
                  }
                  textField="name"
                  onChange={e => {
                    setItem({
                      ...item,
                      companyId: e.value
                    })
                    var contacts = companyContacts.filter(
                      contact => contact.companyId == e.value.id
                    )
                    setFilteredCompanyContacts(contacts)
                  }}
                  dataItemKey="id"
                  data={companiesFiltered}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.companyId}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'mainContactId'}
                  component={FormDropDownList}
                  itemRender={itemRender}
                  valueRender={valueRender}
                  label={t('sponsorings.form.mainContact')}
                  disabled={filteredCompanyContacts.length == 0}
                  textField="firstname"
                  dataItemKey="id"
                  required
                  validator={requiredValidator}
                  data={filteredCompanyContacts}
                />
                <InputError
                  messages={errors.data?.errors?.mainContactId}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'administrationContactId'}
                  component={FormDropDownList}
                  itemRender={itemRender}
                  valueRender={valueRender}
                  label={t('sponsorings.form.administrationContact')}
                  defaultItem={{ firstname: 'Please choose ...', id: 'null' }}
                  disabled={filteredCompanyContacts.length == 0}
                  textField="firstname"
                  dataItemKey="id"
                  data={filteredCompanyContacts}
                />
                <InputError
                  messages={errors.data?.errors?.administrationContactId}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'marketingContactId'}
                  component={FormDropDownList}
                  itemRender={itemRender}
                  valueRender={valueRender}
                  label={t('sponsorings.form.marketingContact')}
                  defaultItem={{ firstname: 'Please choose ...', id: 'null' }}
                  disabled={filteredCompanyContacts.length == 0}
                  textField="firstname"
                  dataItemKey="id"
                  data={filteredCompanyContacts}
                />
                <InputError
                  messages={errors.data?.errors?.eventId}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'state'}
                  component={FormDropDownList}
                  label={t('sponsorings.form.state')}
                  textField="label"
                  dataItemKey="value"
                  onChange={e => {
                    setItem({
                      ...item,
                      state: e.value
                    })
                  }}
                  required
                  validator={requiredValidator}
                  data={[
                    {
                      label: t('sponsorings.form.state.4'),
                      value: '4'
                    },
                    {
                      label: t('sponsorings.form.state.0'),
                      value: '0'
                    },
                    {
                      label: t('sponsorings.form.state.1'),
                      value: '1'
                    },
                    {
                      label: t('sponsorings.form.state.2'),
                      value: '2'
                    },
                    {
                      label: t('sponsorings.form.state.3'),
                      value: '3'
                    },
                    {
                      label: t('sponsorings.form.state.5'),
                      value: '5'
                    }
                  ]}
                />
                <InputError
                  messages={errors.data?.errors?.eventId}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'onSite1Id'}
                  component={FormDropDownList}
                  itemRender={itemRender}
                  valueRender={valueRender}
                  label={t('sponsorings.form.onSite1')}
                  defaultItem={{ firstname: 'Please choose ...', id: 'null' }}
                  disabled={filteredCompanyContacts.length == 0}
                  textField="firstname"
                  dataItemKey="id"
                  data={filteredCompanyContacts}
                />
                <InputError
                  messages={errors.data?.errors?.onSite1Id}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'onSite2Id'}
                  component={FormDropDownList}
                  itemRender={itemRender}
                  valueRender={valueRender}
                  label={t('sponsorings.form.onSite2')}
                  defaultItem={{ firstname: 'Please choose ...', id: 'null' }}
                  disabled={filteredCompanyContacts.length == 0}
                  textField="firstname"
                  dataItemKey="id"
                  data={filteredCompanyContacts}
                />
                <InputError
                  messages={errors.data?.errors?.onSite2Id}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'sponsoringAmount'}
                  component={FormNumericTextBox}
                  label={t('sponsorings.form.sponsoringAmount')}
                  min={-1}
                  onChange={e => {
                    setItem({
                      ...item,
                      sponsoringAmount: e.value
                    })
                  }}
                />
                <InputError
                  messages={errors?.data?.errors?.sponsoringAmount}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  id={'category'}
                  name={'category'}
                  component={FormDropDownList}
                  label={t('sponsorings.form.category')}
                  onChange={e => {
                    setItem({
                      ...item,
                      category: e.value
                    })
                  }}
                  required
                  validator={requiredValidator}
                  data={['1', '2', '3', '4', '5']}
                />
                <InputError
                  messages={errors.data?.errors?.category}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                id={'remark'}
                name={'remark'}
                label={t('sponsorings.form.remark')}
                onChange={e => {
                  setItem({
                    ...item,
                    remark: e.value
                  })
                }}
                optional={true}
                autoSize={true}
                component={FormTextArea}
              />
              <InputError
                messages={errors.data?.errors?.remark}
                className="mt-2"
              />
            </div>
            <span className={'k-form-separator pb-2'} />
            <div className="grid gap-1 grid-cols-2">
              {sponsoringBenefits.length != 0 &&
                sponsoringBenefits.map(benefit =>
                  benefit.fieldType == 'checkbox' ? (
                    <div>
                      <Field
                        id={`sponsoringBenefitss.${benefit.id}`}
                        name={`sponsoringBenefitss.${benefit.id}`}
                        label={benefit.label}
                        onChange={e => {
                          setItem({
                            ...item,
                            remark: e.value
                          })
                        }}
                        component={FormCheckbox}
                      />
                      <InputError messages={errors?.remark} className="mt-2" />
                    </div>
                  ) : (
                    <div className="col-span-2">
                      <Field
                        id={`sponsoringBenefitss.${benefit.id}`}
                        name={`sponsoringBenefitss.${benefit.id}`}
                        label={benefit.label}
                        onChange={e => {
                          setItem({
                            ...item,
                            remark: e.value
                          })
                        }}
                        component={FormTextArea}
                      />
                      <InputError messages={errors?.remark} className="mt-2" />
                    </div>
                  )
                )}
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <KendoFormButton
                mode={mode}
                updateText={t('sponsorings.form.updateBtn')}
                addText={t('sponsorings.form.createBtn')}
                formButtonsDisabled={formButtonsDisabled}
                disabled={!formRenderProps.allowSubmit}
                cancleEditModal={cancleEditModal}
              />
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm

const itemRender = (li, itemProps) => {
  const itemChildren = (
    <span>
      {li.props.children} {itemProps.dataItem.lastname}
    </span>
  )
  return cloneElement(li, li.props, itemChildren)
}
const valueRender = (element, value) => {
  if (!value) {
    return element
  }
  const children = [
    <span key={2}>
      {element.props.children} {value.lastname}
    </span>
  ]
  return cloneElement(
    element,
    {
      ...element.props
    },
    children
  )
}
