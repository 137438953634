import { Outlet } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import InfiniteLoader from 'components/InfiniteLoader/Index'

function CheckLoggedIn() {
  const { user } = useAuth({ middleware: 'auth' })
  return user ? <Outlet /> : <InfiniteLoader />
}

export default CheckLoggedIn
