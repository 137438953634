import {
  GridColumn as Column
} from '@progress/kendo-react-grid'
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter
} from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import { useTranslation } from 'react-i18next'
import CellStateTranslator from 'components/DataGridCellTranslator/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import CheckmarkCell from 'components/KendoDataGrid/CheckmarkCell/Index'
import TruncatedTooltipCellWithLink from 'components/KendoDataGrid/TruncatedTooltipCellWithLink/Index'

const IssuesDataGrid = props => {
  const { t } = useTranslation(['crm'])
  const handleDataItem = dataItem => {
    dataItem.event = dataItem.eventId?.id ? dataItem.eventId : dataItem.event
    dataItem.state = dataItem.state.value ?? dataItem.state
    dataItem.priority = dataItem.priority.value ?? dataItem.priority
    dataItem.userId = dataItem.userId.id ?? dataItem.userId
    dataItem.eventId = dataItem.eventId.id ?? dataItem.eventId
    dataItem.issueSubcategoryId =
      dataItem.issueSubcategoryId?.id ?? dataItem.issueSubcategoryId
    dataItem.issueCategoryId =
      dataItem.issueCategoryId?.id ?? dataItem.issueCategoryId

    dataItem.priorityChangeDate = new Date()
    dataItem.dueDate = new Date(dataItem.dueDate.setHours(12))
    return dataItem
  }

  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        KendoForm={props1 =>
          KendoForm({
            ...props1,
            usersHook: props.usersHook,
            eventsHook: props.eventsHook
          })
        }
        noRecords="No issues found!"
        itemTitle="Issue"
        redirectUrl={'/crm/issues'}
        duplicate={props.duplicate}
        initialGroups={props.initialGroups}
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'label', label: 'Label:' },
          { id: 'remark', label: 'Remark:' }
        ]}
        handleDataItem={handleDataItem}
        deleteDetailText="Also following tables are involved in deletion: Timerecords, Expenses">
        <Column
          field="id"
          title="ID"
          width="80px"
          filter="numeric"
          columnMenu={ColumnMenu}
        />
        <Column
          field="dueDate"
          title={t('issues.dataGrid.dueDate')}
          filter="date"
          format="{0:dd.MM.yyyy}"
          width="100px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="label"
          title={t('issues.dataGrid.label')}
          width="400px"
          cell={TruncatedTooltipCellWithLink}
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="event.name"
          title={t('issues.dataGrid.event')}
          width="150px"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="state"
          title={t('issues.dataGrid.state')}
          width="100px"
          columnMenu={props =>
            ColumnMenuCheckboxFilter(props, [
              { state: '0' },
              { state: '1' },
              { state: '2' },
              { state: '3' }
            ])
          }
          cell={props => (
            <CellStateTranslator {...props} tbl="issues" ns="crm" />
          )}
        />
        <Column
          field="user.name"
          title={t('issues.dataGrid.assignedUser')}
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="totalWorkedTime"
          title={t('issues.dataGrid.totalWorkedTime')}
          width="80px"
          filter="numeric"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="totalExpenses"
          title={t('issues.dataGrid.totalExpenses')}
          width="80px"
          format="{0:n2}"
          filter="numeric"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="priority"
          title={t('issues.dataGrid.priority')}
          width="150px"
          columnMenu={props =>
            ColumnMenuCheckboxFilter(props, [
              { priority: '0' },
              { priority: '1' },
              { priority: '2' },
              { priority: '3' },
              { priority: '4' }
            ])
          }
          cell={props => (
            <CellStateTranslator {...props} tbl="issues" ns="crm" />
          )}
        />
        <Column
          field="remark"
          title={t('issues.dataGrid.remark')}
          width="400px"
          className="truncate"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field="updatedAt"
          title={t('issues.dataGrid.updatedAt')}
          filter="date"
          format="{0:dd.MM.yyyy}"
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="issueCategory.label"
          title={t('issues.dataGrid.category')}
          width="150px"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="issueSubcategory.label"
          title={t('issues.dataGrid.subcategory')}
          width="150px"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          width="45px"
          locked={true}
          cell={cell => CheckmarkCell({ ...cell, patchHook: props.patchHook })}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default IssuesDataGrid