import Link from '@mui/material/Link';

const TruncatedTooltipCellWithLink = props => {
if (props.rowType === 'data') {
return (
  <td
    aria-colindex={props.ariaColumnIndex}
    data-grid-col-index={props.columnIndex}
    title={props.dataItem[props.field]}
    role="gridcell"
    colSpan="1"
    className={`k-table-td truncate ${props.dataItem['priority'] == 4  && "text-blue-600 hover:text-blue-800 underline underline-offset-2"}`}
    style={{ whiteSpace: 'nowrap', width: '100%' }}>
    {props.dataItem['priority'] == 4 ? <Link href={"/crm/issues/"+props.dataItem['id']} target="_blank">{props.dataItem[props.field]}</Link>:<>{props.dataItem[props.field]}</>}
  </td>
)} else {
  return <td></td>
}}

export default TruncatedTooltipCellWithLink
