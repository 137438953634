import BackgroundImage from 'images/Infinite_Science_165488.jpg'

const AuthCard = ({ logo, children }) => (
  <div
    className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100"
    style={{
      background: `url(${BackgroundImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.4)'
    }}>
    <div className="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
      <div className="flex justify-center mb-5">{logo}</div>
      {children}
    </div>
  </div>
)

export default AuthCard
