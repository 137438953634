import { Button } from '@mui/material'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'

const ExportSponsorings = props => {
  const { exportData, pivotData, title } = props
  const { t } = useTranslation(['crm'])
  const handleClick = async () => {
    var data = []
    if (!exportData) {
      const expData = await props.getSponsorings('')
      const pivData = await props.getSponsoringBenefits('')
      data = parseData(expData.data, pivData.data, t)
    } else {
      var onSitePersons = []
      exportData.map(data1 => {
        if (data1.onSite1) {
          onSitePersons.push({
            event: data1.event?.name,
            sponsoringAmount: data1.sponsoringAmount,
            state: data1.state,
            ...data1.onSite1,
            company: data1.company?.name,
            remark: data1.remark
          })
        }
        if (data1.onSite2) {
          onSitePersons.push({
            event: data1.event?.name,
            sponsoringAmount: data1.sponsoringAmount,
            state: data1.state,
            ...data1.onSite2,
            company: data1.company?.name,
            remark: data1.remark
          })
        }
      })

      data = parseData(onSitePersons, pivotData, t)
    }
    if (data?.length != 0) downloadExcel(data)
  }
  return <Button onClick={handleClick}>{title}</Button>
}

export default ExportSponsorings
const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const information = XLSX.utils.json_to_sheet([
    {
      State: 0,
      Label: 'Contract pending'
    },
    {
      State: 1,
      Label: 'Invoice sufficient'
    },
    {
      State: 2,
      Label: 'Contract exists - invoice outstanding'
    },
    {
      State: 3,
      Label: 'Finished'
    }
  ])
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sponsorings')
  XLSX.utils.book_append_sheet(workbook, information, 'Weitere Informationen')
  XLSX.writeFile(workbook, 'Sponsorings.xlsx')
}

const parseData = (data, pivotData, t) =>
  data.map(sponsoring => ({
    Event: sponsoring.event,
    Company: sponsoring.company,
    State: t(`sponsorings.form.state.${sponsoring.state}`),
    SponsoringAmount: sponsoring.sponsoringAmount,
    Sex: sponsoring.sex,
    Title: sponsoring.title,
    FirstName: sponsoring.firstname,
    LastName: sponsoring.lastname,
    Email: sponsoring.email,
    Phone: sponsoring.phone,
    Mobile: sponsoring.mobile,
    Department: sponsoring.department,
    Remark: sponsoring.remark
  }))
