export const getDateFormated = date =>
  `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
    -2
  )}.${date.getFullYear()}`

export const getTimeFormated = time =>
  `${`0${time.getUTCHours()}`.slice(-2)}:${`0${time.getUTCMinutes()}`.slice(
    -2
  )}`

export const getTimeFormatedNotUTC = time =>
  `${`0${time.getHours()}`.slice(-2)}:${`0${time.getMinutes()}`.slice(-2)}`

export const leadingZero = (number, size) => {
  var s = `0000000000000000${number}`
  return s.substr(s.length - size)
}
export const getNetPrice = (price, vat) => (price / 100) * (100 + vat)

export const getTotalNet = (items, vat) =>
  items.reduce(
    (acc, item) => acc + Number(getNetPrice(item.price, vat) * item.quantity),
    0
  )

export const getHoursFromMin = (min) => (min / 60).toFixed(1)