import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import { TextArea } from '@progress/kendo-react-inputs'
import { Button } from '@mui/material'
import { useState } from 'react'
import { Label as Label2, Hint, Error } from '@progress/kendo-react-labels'

const Mailvalidator = () => {
  document.title = 'Mailvalidator'
  const [rawMails, setRawMails] = useState('')
  const [validatedMails, setValidatedMails] = useState('')
  const [falseMails, setFalseMails] = useState('')
  const handleChange = e => {
    setRawMails(e.value)
  }
  const validateMails = e => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    setValidatedMails(
      rawMails
        .split('\n')
        .filter(mail => re.test(mail))
        .join('\n')
    )
    setFalseMails(
      rawMails
        .split('\n')
        .filter(mail => !re.test(mail))
        .join('\n')
    )
  }
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Mailvalidator</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-row items-center shadow-lg">
                  <div className="p-2 flex justify-center flex-col items-center shadow-lg">
                    <span className="text-2xl">Raw E-Mails</span>
                    <TextArea
                      size={'large'}
                      value={rawMails}
                      onChange={handleChange}
                      style={{
                        height: 800,
                        width: 350
                      }}
                    />
                  </div>
                  <div className="p-2 flex justify-between flex-col items-center shadow-lg">
                    <span>
                      Raw E-Mails: {rawMails ? rawMails.split('\n').length : 0}
                    </span>
                    <span>
                      Validated E-Mails:{' '}
                      {validatedMails ? validatedMails.split('\n').length : 0}
                    </span>
                    <Button
                      onClick={validateMails}
                      className="w-full btn btn-primary">
                      Validate
                    </Button>
                    {/* <span className="text-2xl">False Emails</span>
                    <TextArea
                      size={'large'}
                      value={falseMails}
                      onChange={handleChange}
                      style={{
                        height: 300,
                        width: 300
                      }}
                    /> */}
                  </div>
                  <div className="p-2 flex justify-center flex-col items-center shadow-lg">
                    <span className="text-2xl">Validated E-Mails</span>
                    <TextArea
                      //disabled
                      value={validatedMails}
                      size={'large'}
                      style={{
                        height: 800,
                        width: 350
                      }}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Mailvalidator
