import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'
import InputError from 'components/InputError'
import { requiredValidator } from 'components/ReactFormComponents/Validators'
import {
  FormInput,
  FormDropDownList,
  FormTextArea
} from 'components/ReactFormComponents/Index'
import { useState, useEffect } from 'react'
import { filterChange } from 'components/FormsDropdownFilter/Index'
import KendoFormButton from 'components/KendoFormButton/Index'

const KendoForm = props => {
  const {
    errors,
    handleSubmit,
    item,
    mode,
    cancleEditModal,
    companiesHook,
    formButtonsDisabled
  } = props
  const [currentCompanyContacts, setCurrentCompanyContacts] = useState([])
  const [companies, setCompanies] = useState([])
  const [companiesFiltered, setCompaniesFiltered] = useState([])

  useEffect(() => {
    setCompaniesFiltered(companiesHook)
    setCompanies(companiesHook)
  }, [companiesHook])
  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      initialValues={item}
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 800
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="mb-3">
              <Field
                name={'companyId'}
                component={FormDropDownList}
                label={'Company'}
                filterable={true}
                onFilterChange={event =>
                  filterChange(event, companies, setCompaniesFiltered)
                }
                textField="name"
                dataItemKey="id"
                data={companiesFiltered}
                required
                validator={requiredValidator}
                onChange={value =>
                  setCurrentCompanyContacts(value.value.companyContacts)
                }
              />
              <InputError
                messages={errors.data?.errors?.companyId}
                className="mt-2"
              />
              {currentCompanyContacts.length != 0
                ? currentCompanyContacts.map(contact => (
                    <span className="block">
                      {contact.firstname} {contact.lastname}
                    </span>
                  ))
                : null}
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  id={'sex'}
                  name={'sex'}
                  label={'Gender'}
                  component={FormDropDownList}
                  textField={'label'}
                  dataItemKey="value"
                  required
                  validator={requiredValidator}
                  data={[
                    { label: 'Male', value: 'm' },
                    { label: 'Female', value: 'f' },
                    { label: 'Divers', value: 'd' }
                  ]}
                />
                <InputError
                  messages={errors.data?.errors?.sex}
                  className="mt-2"
                />
              </div>

              <div className="mb-3 flex-1">
                <Field
                  name={'title'}
                  label={'Title'}
                  component={FormDropDownList}
                  textField={'label'}
                  dataItemKey="value"
                  optional={true}
                  data={[
                    {
                      label: 'Dr./Phd',
                      value: 'Dr./Phd'
                    },
                    {
                      label: 'Professor',
                      value: 'Professor'
                    }
                  ]}
                  defaultItem={{
                    label: 'Please select...',
                    value: ''
                  }}
                />
                <InputError
                  messages={errors.data?.errors?.title}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'firstname'}
                  component={FormInput}
                  label={'First name'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.firstname}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'lastname'}
                  component={FormInput}
                  label={'Last name'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.lastname}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'email'}
                  component={FormInput}
                  label={'Email'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.email}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'department'}
                  component={FormInput}
                  label={'Department/Function'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.department}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'phone'}
                  component={FormInput}
                  label={'Phone'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.phone}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'mobile'}
                  component={FormInput}
                  label={'Mobile'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.mobile}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                name={'remark'}
                component={FormTextArea}
                label={'Remark'}
                optional={true}
              />
              <InputError
                messages={errors.data?.errors?.remark}
                className="mt-2"
              />
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <KendoFormButton
                mode={mode}
                updateText={'Update company contact'}
                addText={'Create company contact'}
                formButtonsDisabled={formButtonsDisabled}
                disabled={!formRenderProps.allowSubmit}
                cancleEditModal={cancleEditModal}
              />
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm
