import {
  FormInput,
  FormTextArea,
  FormDropDownList
} from 'components/ReactFormComponents/Index'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'
import InputError from 'components/InputError'
import { requiredValidator } from 'components/ReactFormComponents/Validators'
import KendoFormButton from 'components/KendoFormButton/Index'

const KendoForm = props => {
  const {
    errors,
    handleSubmit,
    item,
    mode,
    cancleEditModal,
    formButtonsDisabled
  } = props
  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      initialValues={{
        ...item,
        country: item?.country ?? 'Germany'
      }}
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 800
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'name'}
                  component={FormInput}
                  label={'Company name'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.name}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'nickname'}
                  component={FormInput}
                  label={'Company nickname'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.nickname}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'category'}
                  label={'Category'}
                  component={FormDropDownList}
                  textField={'label'}
                  dataItemKey="value"
                  required
                  validator={requiredValidator}
                  data={[
                    {
                      label: 'Fachgesellschaft',
                      value: 'Fachgesellschaft'
                    },
                    {
                      label: 'Forschung, Hochschule',
                      value: 'Forschung, Hochschule'
                    },
                    {
                      label: 'Krankenkasse',
                      value: 'Krankenkasse'
                    },
                    {
                      label: 'Lieferant',
                      value: 'Lieferant'
                    },
                    {
                      label: 'Netzwerk, Verein',
                      value: 'Netzwerk, Verein'
                    },
                    {
                      label: 'Rechtsanwalt, Notar, Beratung',
                      value: 'Rechtsanwalt, Notar, Beratung'
                    },
                    {
                      label: 'Sonstiges',
                      value: 'Sonstiges'
                    },
                    {
                      label: 'Sponsor',
                      value: 'Sponsor'
                    },
                    {
                      label: 'Unternehmen',
                      value: 'Unternehmen'
                    },
                    {
                      label: 'Verlag',
                      value: 'Verlag'
                    },
                    {
                      label: 'Klinik',
                      value: 'Klinik'
                    },
                    {
                      label: 'Kunde',
                      value: 'Kunde'
                    }
                  ]}
                />
                <InputError
                  messages={errors.data?.errors?.category}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'website'}
                  component={FormInput}
                  label={'Website'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.website}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'areaCode'}
                  component={FormInput}
                  label={'Area code'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.areaCode}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'city'}
                  component={FormInput}
                  label={'City'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.city}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'street'}
                  component={FormInput}
                  label={'Street'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.street}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'country'}
                  component={FormInput}
                  label={'Country'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.country}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                name={'keywords'}
                label={'Keywords'}
                optional={true}
                hint="Plese provide the keywords seperated by a ';' e.g. 'word1;word2'"
                component={FormInput}
              />
              <InputError
                messages={errors.data?.errors?.keywords}
                className="mt-2"
              />
            </div>
            <div className="mb-3">
              <Field
                id={'remark'}
                name={'remark'}
                label={'Comment'}
                optional={true}
                autoSize={true}
                component={FormTextArea}
              />
              <InputError
                messages={errors.data?.errors?.remark}
                className="mt-2"
              />
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <KendoFormButton
                mode={mode}
                updateText={'Update company'}
                addText={'Create company'}
                formButtonsDisabled={formButtonsDisabled}
                disabled={!formRenderProps.allowSubmit}
                cancleEditModal={cancleEditModal}
              />
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm
