const TruncatedTooltipCell = props => (
  <td
    aria-colindex={props.ariaColumnIndex}
    data-grid-col-index={props.columnIndex}
    title={props.dataItem[props.field]}
    role="gridcell"
    colSpan="1"
    className="k-table-td truncate"
    style={{ whiteSpace: 'nowrap', width: '100%' }}>
    {props.dataItem[props.field]}
  </td>
)

export default TruncatedTooltipCell
