import {
  FormInput,
  FormTextArea,
  FormDatePicker,
  FormDropDownList
} from 'components/ReactFormComponents/Index'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import InputError from 'components/InputError'
import { requiredValidator } from 'components/ReactFormComponents/Validators'
import { useState, useEffect } from 'react'
import { filterChange } from 'components/FormsDropdownFilter/Index'
import KendoFormButton from 'components/KendoFormButton/Index'

const KendoForm = props => {
  const {
    errors,
    handleSubmit,
    item,
    mode,
    cancleEditModal,
    projectsHook,
    formButtonsDisabled
  } = props
  const [projects, setProjects] = useState([])
  const [projectsFiltered, setProjectsFiltered] = useState([])
  useEffect(() => {
    setProjects(projectsHook)
    setProjectsFiltered(projectsHook)
  }, [projectsHook])
  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      initialValues={{
        ...item,
        startDate: new Date(item.startDate ?? Date.now()),
        endDate: new Date(item.endDate ?? Date.now())
      }}
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 800
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'projectId'}
                  component={FormDropDownList}
                  label={'Project'}
                  filterable={true}
                  onFilterChange={event =>
                    filterChange(event, projects, setProjectsFiltered)
                  }
                  textField="name"
                  dataItemKey="id"
                  data={projectsFiltered}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.projectId}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'state'}
                  component={FormDropDownList}
                  label={'State'}
                  textField="label"
                  dataItemKey="value"
                  required
                  validator={requiredValidator}
                  data={[
                    { value: '0', label: 'Request' },
                    {
                      value: '1',
                      label: 'Cancellation'
                    },
                    {
                      value: '2',
                      label: 'Project'
                    },
                    { value: '3', label: 'Finished' }
                  ]}
                />
                <InputError
                  messages={errors.data?.errors?.state}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'name'}
                  component={FormInput}
                  label={'Event name'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.name}
                  className="mt-2"
                />
              </div>
              <div className="mb-3" style={{ flex: 2 }}>
                <Field
                  name={'nameLong'}
                  component={FormInput}
                  label={'Event name long'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.nameLong}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'participantNumber'}
                  component={FormInput}
                  label={'Participant Information'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.participantNumber}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'location'}
                  component={FormInput}
                  label={'Location'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.location}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'startDate'}
                  component={FormDatePicker}
                  format="dd.MM.yyyy"
                  label={'Start date'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.startDate}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'endDate'}
                  component={FormDatePicker}
                  format="dd.MM.yyyy"
                  label={'End date'}
                  optional={true}
                />
                <InputError
                  messages={errors.data?.errors?.endDate}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                id={'remark'}
                name={'remark'}
                label={'Comment'}
                optional={true}
                autoSize={true}
                component={FormTextArea}
              />
              <InputError
                messages={errors.data?.errors?.remark}
                className="mt-2"
              />
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <KendoFormButton
                mode={mode}
                updateText={'Update event'}
                addText={'Create event'}
                formButtonsDisabled={formButtonsDisabled}
                disabled={!formRenderProps.allowSubmit}
                cancleEditModal={cancleEditModal}
              />
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm
