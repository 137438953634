import { PDFExport } from '@progress/kendo-react-pdf'
import './fonts.css'

const CertificateTemplate = ({ tablesignPdf, data, backgroundImage }) => (
  <PDFExport
    forcePageBreak=".page-break"
    fileName={'Certificates.pdf'}
    ref={tablesignPdf}
    className="page-container hidden-on-narrow"
    style={{ display: 'none' }}>
    <div className="pdf-page size-a4">
      {data.map((item, index) => (
        <div className={`inner-page ${index != 0 ? 'page-break' : ''}`}>
          {backgroundImage && (
            <img
              className="absolute top-0 left-0 right-0 bottom-0"
              src={backgroundImage}
              alt="Selected"
            />
          )}
          <div
            className="absolute leading-tight"
            style={{
              fontFamily: 'Open Sans',
              fontSize: 11,
              top: '54.5mm',
              left: '32mm',
              right: '10mm',
              maxHeight: '1.25em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.name}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              fontFamily: 'Open Sans',
              fontSize: 11,
              top: '64.5mm',
              left: '32mm',
              right: '10mm',
              maxHeight: '1.25em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.location}
          </div>
        </div>
      ))}
    </div>
  </PDFExport>
)

export default CertificateTemplate
