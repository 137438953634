import useSWR from 'swr'
import axios from 'lib/axios'

export const useProjectTypes = ({ query } = {}) => {
  const {
    data: projectTypesHook,
    error,
    mutate
  } = useSWR(`/api/v1/project-types${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/project-types${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  return {
    projectTypesHook
  }
}
