import { useState } from 'react'
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa'

const CheckmarkCell = props => {
  const [hoverCheck, setHoverCheck] = useState(false)
  const toggleHover = (state, id) => {
    if (state == 'hover') {
      setHoverCheck(id)
    } else {
      setHoverCheck(false)
    }
  }
  return props.rowType === 'data' ? (
    <td {...props}>
      {props.dataItem.state == '2' ? (
        <FaCheckSquare size={24} style={{ color: 'green' }}></FaCheckSquare>
      ) : (
        <div
          onMouseEnter={() => toggleHover('hover', props.dataItem.id)}
          onMouseLeave={() => toggleHover('not', props.dataItem.id)}>
          {hoverCheck == props.dataItem.id ? (
            <FaCheckSquare
              size={24}
              title="Set Issue Finished"
              className="cursor-pointer test"
              style={{ color: 'green', opacity: 0.5 }}
              onClick={ev =>
                props.patchHook({
                  dataItem: {
                    id: props.dataItem.id,
                    state: '2'
                  }
                })
              }></FaCheckSquare>
          ) : (
            <FaRegSquare
              size={24}
              title="Set Issue Finished"
              className="cursor-pointer test"
              style={{ color: 'green' }}
              onClick={ev =>
                props.patchHook({
                  dataItem: {
                    id: props.dataItem.id,
                    state: '2'
                  }
                })
              }></FaRegSquare>
          )}
        </div>
      )}
    </td>
  ) : (
    <td></td>
  )
}

export default CheckmarkCell
