import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import * as xlsx from 'xlsx'
import NamebadgeTemplate from 'components/pages/toolbox/create-namebadges/NamebadgeTemplate'
import { useRef, useState } from 'react'
import fileToDownload from 'components/pages/toolbox/create-namebadges/ExcelTemplate/VorlageNamebadgeImport.xlsx'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { TextBox, TextArea } from '@progress/kendo-react-inputs'
import { Label as KLabel } from '@progress/kendo-react-labels'

const NamebadgeCreation = () => {
  document.title = 'Create namebadges'
  const [namebadgeData, setNamebadgeData] = useState([])
  const namebadgePdf = useRef(null)
  const [selected, setSelected] = useState(0)
  const handleSelect = e => {
    setSelected(e.selected)
  }
  const readUploadFile = async e => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = async e => {
        const data = e.target.result
        const workbook = xlsx.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const namebadgeDataArray = xlsx.utils.sheet_to_json(worksheet, {
          defval: ''
        })
        setNamebadgeData(namebadgeDataArray)
      }
      reader.readAsArrayBuffer(e.target.files[0])
      e.target.value = null
    }
  }
  const onChangeInvoiceData = event => {
    let id = event.target.element?.current?.id ?? event.target.element.id
    setNamebadgeData([
      { ...namebadgeData[0], [id]: event.value.replace('\\\\n', '\n') }
    ])
  }
  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = fileToDownload
    link.download = 'VorlageNamebadge.xlsx' // Set the desired name of the downloaded file
    link.click()
  }
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Namebadge generator</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 shadow-lg mb-4">
                  <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                    Anleitung zum Erstellen der Namebadges
                  </h2>
                  <ol className="mb-4 max-w-md space-y-1 list-decimal list-inside dark:text-gray-400">
                    <li>
                      Excel Vorlage herunterladen{' '}
                      <button
                        type="button"
                        onClick={handleDownload}
                        className="px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        Download
                      </button>
                    </li>
                    <li>
                      Excel-Datei mit den Daten der Teilnehmer hochladen. Es ist
                      möglich in der Tabelle zu prüfen ob die Daten korrekt
                      hochgeladen wurden.
                    </li>
                    <li>PDF mit den Namensschildern herunterladen</li>
                  </ol>
                  <span className="max-w-md space-y-1 list-decimal list-inside dark:text-gray-400 text-sm">
                    {' '}
                    Tipps: Beim Upload kann in den Felder "institution",
                    "location" und "special" durch setzen von "\n" ein Umbruch
                    erzwungen werden. In dem Formular kann ein einfacher Umbruch
                    genutzt werden. Anderenfalls brechen diese Felder
                    automatisch um (Maximal zwei Zeilen). Das Feld "institution"
                    ist immer die erste Zeile auf dem Namebadge und das Feld
                    "location" immer die Zweite.{' '}
                  </span>
                </Paper>
                <Paper className="p-2 flex shadow-lg">
                  <div>
                    <div
                      style={{
                        position: 'absolute',
                        left: '-1000px',
                        top: 0
                      }}>
                      <NamebadgeTemplate
                        namebadgePdf={namebadgePdf}
                        data={namebadgeData}
                      />
                    </div>
                    <TabStrip
                      style={{ width: '400px' }}
                      selected={selected}
                      onSelect={handleSelect}>
                      <TabStripTab title="Upload">
                        <div className="flex flex-col">
                          <form className="m-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              Upload Excel File:
                            </label>
                            <input
                              type="file"
                              onChange={readUploadFile}
                              accept=".xlsx"
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            />
                          </form>
                        </div>
                      </TabStripTab>
                      <TabStripTab title="Form">
                        <div className="m-2">
                          <div className="mb-3 flex">
                            <div className="mr-2" style={{ flex: 1 }}>
                              <KLabel editorId={'id'}>ID:</KLabel>
                              <TextBox
                                id="id"
                                value={namebadgeData[0]?.id ?? ''}
                                onChange={onChangeInvoiceData}
                              />
                            </div>
                            <div style={{ flex: 3 }}>
                              <KLabel editorId={'title'}>Title:</KLabel>
                              <TextBox
                                id="title"
                                value={namebadgeData[0]?.title ?? ''}
                                onChange={onChangeInvoiceData}
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <KLabel editorId={'firstname'}>Firstname:</KLabel>
                            <TextBox
                              id="firstname"
                              value={namebadgeData[0]?.firstname ?? ''}
                              onChange={onChangeInvoiceData}
                            />
                          </div>
                          <div className="mb-3">
                            <KLabel editorId={'lastname'}>Lastname:</KLabel>
                            <TextBox
                              id="lastname"
                              value={namebadgeData[0]?.lastname ?? ''}
                              onChange={onChangeInvoiceData}
                            />
                          </div>
                          <div className="mb-3">
                            <KLabel editorId={'institution'}>
                              Institution:
                            </KLabel>
                            <TextArea
                              id="institution"
                              rows={2}
                              value={namebadgeData[0]?.institution ?? ''}
                              onChange={onChangeInvoiceData}
                            />
                          </div>
                          <div className="mb-3">
                            <KLabel editorId={'location'}>Location:</KLabel>
                            <TextArea
                              id="location"
                              rows={2}
                              value={namebadgeData[0]?.location ?? ''}
                              onChange={onChangeInvoiceData}
                            />
                          </div>
                          <div className="mb-3">
                            <KLabel editorId={'special'}>Special:</KLabel>
                            <TextArea
                              id="special"
                              rows={2}
                              value={namebadgeData[0]?.special ?? ''}
                              onChange={onChangeInvoiceData}
                            />
                          </div>
                          <div className="flex">
                            <button
                              type="button"
                              onClick={() => setNamebadgeData([])}
                              class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                              Clear
                            </button>
                          </div>
                        </div>
                      </TabStripTab>
                    </TabStrip>
                    {namebadgeData.length != 0 && (
                      <button
                        className="m-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        disabled={namebadgeData.length == 0}
                        onClick={() => namebadgePdf.current.save()}>
                        {' '}
                        Download Namebadges PDF
                      </button>
                    )}
                  </div>
                  <div className="flex-1 ml-4 ">
                    <span className="text-xl font-bold">
                      Excel uploaded data:
                    </span>
                    <table className="w-full table-auto mt-4 min-w-full text-sm text-left">
                      <thead>
                        <tr>
                          <th className="border px-2 py-1">ID</th>
                          <th className="border px-2 py-1">Title</th>
                          <th className="border px-2 py-1">First Name</th>
                          <th className="border px-2 py-1">Last Name</th>
                          <th className="border px-2 py-1">Institution</th>
                          <th className="border px-2 py-1">Location</th>
                          <th className="border px-2 py-1">Special</th>
                        </tr>
                      </thead>
                      <tbody>
                        {namebadgeData.length != 0 ? (
                          namebadgeData.map((data, index) => (
                            <tr
                              className="border-b dark:border-neutral-500"
                              key={index}>
                              <td className="py-1 px-3 font-medium">
                                {data.id}
                              </td>
                              <td className="py-1 px-3">{data.title}</td>
                              <td className="py-1 px-3">{data.firstname}</td>
                              <td className="py-1 px-3">{data.lastname}</td>
                              <td className="py-1 px-3">{data.institution}</td>
                              <td className="py-1 px-3">{data.location}</td>
                              <td className="py-1 px-3">{data.special}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default NamebadgeCreation
