import {
  FormTextArea,
  FormDropDownList
} from 'components/ReactFormComponents/Index'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import InputError from 'components/InputError'
import { requiredValidator } from 'components/ReactFormComponents/Validators'
import { useTranslation } from 'react-i18next'
import KendoFormButton from 'components/KendoFormButton/Index'

const KendoForm = props => {
  const { t } = useTranslation(['crm', 'common'])
  const {
    errors,
    handleSubmit,
    item,
    mode,
    cancleEditModal,
    formButtonsDisabled
  } = props

  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      initialValues={
        item.id
          ? {
              ...item
            }
          : { state: '0', priority: '0' }
      }
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 800
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="mb-3 mr flex-1">
              <Field
                name={'state'}
                component={FormDropDownList}
                label={t('issues.dataGrid.state')}
                textField="label"
                dataItemKey="value"
                required
                validator={requiredValidator}
                data={[
                  {
                    label: 'Offen',
                    value: '0'
                  },
                  {
                    label: 'Bezahlt',
                    value: '1'
                  },
                  {
                    label: 'Storniert',
                    value: '2'
                  }
                ]}
              />
              <InputError
                messages={errors.data?.errors?.priority}
                className="mt-2"
              />
            </div>
            <div className="mb-3">
              <Field
                id={'remark'}
                name={'remark'}
                label={t('issues.dataGrid.remark')}
                optional={true}
                autoSize={true}
                component={FormTextArea}
              />
              <InputError
                messages={errors.data?.errors?.remark}
                className="mt-2"
              />
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <KendoFormButton
                mode={mode}
                updateText={'Update order'}
                addText={'Create order'}
                formButtonsDisabled={formButtonsDisabled}
                disabled={!formRenderProps.allowSubmit}
                cancleEditModal={cancleEditModal}
              />
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm
