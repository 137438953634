import * as React from 'react'
import {
    GridColumnMenuFilter,
    GridColumnMenuCheckboxFilter,
} from '@progress/kendo-react-grid'

export const ColumnMenu = props => {
    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    )
}
export const ColumnMenuCheckboxFilter = (props, data) => {
    return (
        <div>
            <GridColumnMenuCheckboxFilter
                {...props}
                data={data}
                expanded={true}
            />
        </div>
    )
}
