import { GridColumn as Column } from '@progress/kendo-react-grid'
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter
} from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import { MyCommandCell } from 'components/DataGridCommandCell/Index'

const EventsDataGrid = props => {
  const handleDataItem = dataItem => {
    dataItem.state = dataItem.state.value ?? dataItem.state
    dataItem.projectId = dataItem.projectId.id ?? dataItem.projectId
    dataItem.startDate = new Date(dataItem.startDate.setHours(10))
    dataItem.endDate = new Date(dataItem.endDate.setHours(12))
    return dataItem
  }
  const goToProjectPage = (id, url) => {
    var redirectionPath = url
    window.open(`${redirectionPath}/${id}`, '_blank')
  }
  const CommandCellStart = props => (
    <MyCommandCell
      {...props}
      redirectUrl="/crm/events"
      goToUrl={goToProjectPage}
    />
  )
  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        KendoForm={props1 =>
          KendoForm({ ...props1, projectsHook: props.projectsHook })
        }
        noRecords="No events found!"
        itemTitle="Event"
        duplicate={props.duplicate}
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'name', label: 'Name:' },
          { id: 'location', label: 'Location:' },
          { id: 'website', label: 'Website:' },
          { id: 'remark', label: 'Remark:' }
        ]}
        handleDataItem={handleDataItem}
        deleteDetailText="Also following tables are involved in deletion: Issues, Sponsorings">
        <Column cell={CommandCellStart} width="45px" locked={true} />
        <Column field="id" title="ID" width="80px" />
        <Column
          field="project.projectType.label"
          title="Projecttype"
          width="200px"
        />
        <Column field="project.name" title="Project" width="250px" />
        <Column
          field="name"
          title="Name"
          width="200px"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field="location"
          title="Location"
          width="250px"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipCell}
        />
        <Column
          field="participantNumber"
          title="Participants"
          width="130px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="startDate"
          title="Start Date"
          filter="date"
          format="{0:dd.MM.yyyy}"
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="endDate"
          title="End Date"
          filter="date"
          format="{0:dd.MM.yyyy}"
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="state"
          title="Status"
          width="110px"
          columnMenu={props =>
            ColumnMenuCheckboxFilter(props, [
              { state: '0', label: 'test0' },
              { state: '1', label: 'test1' },
              { state: '2', label: 'test2' },
              { state: '3', label: 'test3' }
            ])
          }
        />
        <Column
          field="remark"
          title="Remark"
          width="300px"
          className="truncate"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipCell}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default EventsDataGrid
