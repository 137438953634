import { Button } from '@mui/material'
import * as XLSX from 'xlsx'
import { getHoursFromMin } from 'lib/convinience'

const ExportTimeTracking = props => {
  const { exportData, title } = props
  const handleClick = async () => {
    var data = []
    if (!exportData) {
      var issues = await props.getIssues({
        query: '?includeEvent=true&priority[eq]=4'
      })
      data = parseData(issues.data)
    } else {
      data = parseData(props.data)
    }
    downloadExcel(data)
  }
  return <Button onClick={handleClick}>{title}</Button>
}

export default ExportTimeTracking

const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Übersicht')
  XLSX.writeFile(workbook, 'EventsZeitUbersicht.xlsx')
}

const parseData = data => {
  let events = data
    .map(issue => {
      return { id: issue.event?.id, name: issue.event?.name }
    })
    .filter(
      (obj, index, self) => index === self.findIndex(t => t.id === obj.id)
    )
    let pivotData = data
    .map(issue => issue.label)
    .filter(
      (obj, index, self) => index === self.findIndex(t => t === obj)
    ).sort()
  return events.map(event => {
    var pData = {}
    pivotData.map((pivot, key) => {
      let time = data.filter(eq => eq.label == pivot && event.id == eq.event?.id)[0]
      ?.totalWorkedTime
      pData[pivot] =
      time ? getHoursFromMin(time): ''
    })
    return {
      Event: event.name,
      ...pData
    }
  })
}