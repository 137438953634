import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import TimeTrackingDataGrid from 'components/pages/crm/issues/TimeTrackingDataGrid/Index'
import { useIssues } from 'hooks/issues'
import { useTranslation } from 'react-i18next'
import { useUsers } from 'hooks/user'
import { useEvents } from 'hooks/events'

const TimeTracking = () => {
  const { t } = useTranslation('common')
  const { issuesHook, putHook, postHook, deleteHook, patchHook } = useIssues({
    query: '?priority[eq]=4&noFinishedEvents=true&order_by=label'
  })
  const { usersHook } = useUsers({
    query: '?is_active[eq]=1&order_by=id'
  })
  const { eventsHook } = useEvents({
    query: '?order_by=-name&includeProject=true'
  })
  document.title = 'TimeTracking'
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">
            {t('drawer.timetracking')}
          </Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-col items-center shadow-lg">
                  <TimeTrackingDataGrid
                    itemHook={issuesHook}
                    putHook={putHook}
                    postHook={postHook}
                    deleteHook={deleteHook}
                    patchHook={patchHook}
                    usersHook={usersHook?.data ?? []}
                    eventsHook={eventsHook?.data ?? []}
                    duplicate={true}
                    initialGroups={[
                      {
                        field: 'event.name'
                      }
                      /* {
                        field: 'issueCategory.label'
                      } */
                    ]}
                    initialFilter={{
                      filters: [
                        {
                          filters: [
                            {
                              field: 'state',
                              operator: 'eq',
                              value: '0'
                            },
                            {
                              field: 'state',
                              operator: 'eq',
                              value: '1'
                            },
                            {
                              field: 'state',
                              operator: 'eq',
                              value: '3'
                            }
                          ],
                          logic: 'or'
                        }
                      ],
                      logic: 'and'
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default TimeTracking
