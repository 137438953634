import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import EventsDataGrid from 'components/pages/crm/events/EventsDataGrid/Index'
import { useEvents } from 'hooks/events'
import { useProjects } from 'hooks/projects'

const Events = () => {
  const { eventsHook, putEvent, postEvent, deleteEvent } = useEvents({
    query:
      '?includeProject=true&includeDashboard=true&includeIssues=true&order_by=-start_date'
  })
  const { projectsHook } = useProjects({ query: '' })
  document.title = 'Events'
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Events</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-col items-center shadow-lg">
                  <EventsDataGrid
                    itemHook={eventsHook}
                    putHook={putEvent}
                    postHook={postEvent}
                    deleteHook={deleteEvent}
                    projectsHook={projectsHook?.data ?? []}
                    duplicate={true}
                    initialGroups={[
                      {
                        field: 'project.projectType.label'
                      }
                    ]}
                    initialFilter={{
                      filters: [
                        {
                          filters: [
                            {
                              field: 'state',
                              operator: 'eq',
                              value: '0'
                            },
                            {
                              field: 'state',
                              operator: 'eq',
                              value: '1'
                            },
                            {
                              field: 'state',
                              operator: 'eq',
                              value: '2'
                            }
                          ],
                          logic: 'or'
                        }
                      ],
                      logic: 'and'
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Events
