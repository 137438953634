import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useSponsoringContactPoints = query => {
  const {
    data: sponsoringContactPointsHook,
    error,
    mutate
  } = useSWR(
    `/api/v1/sponsoring-contact-points?includeCompany=true${
      query ? query : ''
    }`,
    () =>
      axios
        .get(
          `/api/v1/sponsoring-contact-points?includeCompany=true${
            query ? query : ''
          }`
        )
        .then(res => {
          var parsedData = res.data.data.map(event => {
            event.date = new Date(event.date)
            event.resubmissionDate = event.resubmissionDate
              ? new Date(event.resubmissionDate)
              : null
            return event
          })
          return { data: parsedData }
        })
        .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const putCompanyContactPoints = async ({
    setErrors,
    setStatus,
    ...props
  }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(
        `/api/v1/sponsoring-contact-points/${props.dataItem.id}`,
        props.dataItem
      )
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postCompanyContactPoints = async ({
    setErrors,
    setStatus,
    ...props
  }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/sponsoring-contact-points', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const deleteCompanyContactPoints = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)

    axios
      .delete(`/api/v1/sponsoring-contact-points/${props.dataItem.id}`)
      .then(status => {
        setStatus(status)
        mutate()
      })
  }

  useEffect(() => {}, [sponsoringContactPointsHook, error])

  return {
    sponsoringContactPointsHook,
    putCompanyContactPoints,
    postCompanyContactPoints,
    deleteCompanyContactPoints
  }
}
