const TruncatedTooltipLinkCell = props => (
  <td
    aria-colindex={props.ariaColumnIndex}
    data-grid-col-index={props.columnIndex}
    title={props.dataItem[props.field]}
    role="gridcell"
    colSpan="1"
    className="k-table-td truncate"
    style={{ whiteSpace: 'nowrap', width: '100%' }}>
    <a href={props.dataItem[props.field]} target="_blank" rel="noreferrer">
      {props.dataItem[props.field]}
    </a>
  </td>
)

export default TruncatedTooltipLinkCell
