import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useCompanies = ({ middleware, query } = {}) => {
  const {
    data: companiesHook,
    error,
    mutate
  } = useSWR(`/api/v1/companies${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/companies${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const putCompany = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/companies/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postCompany = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/companies', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const deleteCompany = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)

    axios.delete(`/api/v1/companies/${props.dataItem.id}`).then(status => {
      setStatus(status)
      mutate()
    })
  }

  useEffect(() => {}, [companiesHook, error])

  return {
    companiesHook,
    putCompany,
    postCompany,
    deleteCompany
  }
}
