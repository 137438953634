import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import WelcomeCard from 'components/WelcomeCard'

import { useProjects } from 'hooks/projects'
import { MonthView } from '@progress/kendo-react-scheduler'
import {
  Scheduler,
  SchedulerItem,
  SchedulerItemContent
} from '@progress/kendo-react-scheduler'
import { useEffect, useState, Children } from 'react'
import { useAuth } from 'hooks/auth'
import { getDateFormated } from 'lib/convinience'
import { Link, useNavigate } from 'react-router-dom'

const Welcome = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { user } = useAuth()
  document.title = 'Welcome'
  const { projectsHook } = useProjects({
    query: '?includeEvents=true'
  })
  const [events, setEvents] = useState([])
  const [eventsCalender, setEventsCalender] = useState([])
  const handleClick = url => {
    navigate(url, { replace: true })
  }
  var sampleData = []
  if (eventsCalender) {
    sampleData = eventsCalender
      .filter(dataItem => dataItem)
      .map(dataItem => ({
        id: dataItem.id,
        start: parseAdjustStart(dataItem.startDate),
        startTimezone: null,
        end: parseAdjustEnd(dataItem.endDate),
        endTimezone: null,
        isAllDay: true,
        title: dataItem.name,
        description: `/crm/events/${dataItem.id}`,
        recurrenceRule: '',
        recurrenceId: null,
        recurrenceExceptions: null,
        roomId: 1,
        ownerID: 1,
        personId: 1
      }))
    sampleData.push({
      id: 5555,
      start: new Date(new Date().setHours(1)),
      startTimezone: null,
      end: new Date(new Date().setHours(23)),
      endTimezone: null,
      isAllDay: true,
      title: 'Today',
      description: '/user/dashboard',
      recurrenceRule: '',
      recurrenceId: null,
      recurrenceExceptions: null,
      roomId: 1,
      ownerID: 2,
      personId: 2
    })
  }
  useEffect(() => {
    var events = []
    var eventsCalendar = []
    if (projectsHook?.data) {
      projectsHook.data.map(project => {
        project.events.map(event => {
          events.push(event)
        })
      })
      projectsHook.data
        .filter(
          project => project?.projectTypeId == 1 || project?.projectTypeId == 2
        )
        .map(project => {
          project.events.map(event => {
            eventsCalendar.push(event)
          })
        })
    }
    setEventsCalender(eventsCalendar)
    setEvents(events)
  }, [projectsHook])

  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">
            {t('drawer.welcome')} back
          </Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={6} xl={6}>
                {user?.is_super_admin == 1 && (
                  <Box component={Grid} display={{ xs: 'block', lg: 'none' }}>
                    <Grid xs={12} lg={0} xl={0}>
                      <Link
                        to="/crm/mobile-time-tracking"
                        className="w-60 mb-2 text-center block mr-2 max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100">
                        {`Mobile ${  t('drawer.timetracking')}`}
                      </Link>
                    </Grid>
                  </Box>
                )}
                <Grid xs={12} lg={12} xl={12}>
                  <Paper className="px-2 py-2">
                    <Scheduler
                      data={sampleData}
                      defaultDate={new Date()}
                      resources={[
                        {
                          name: 'Persons',
                          data: [
                            {
                              text: 'Peter',
                              value: 1
                            },
                            {
                              text: 'Alex',
                              value: 2,
                              color: '#000000'
                            }
                          ],
                          field: 'personId',
                          valueField: 'value',
                          textField: 'text',
                          colorField: 'color'
                        }
                      ]}
                      item={props =>
                        CustomItem({ ...props, handleClick: handleClick })
                      }>
                      <MonthView />
                    </Scheduler>
                  </Paper>
                </Grid>
                {user?.is_super_admin == 1 && (
                  <Grid xs={12} lg={12} xl={12}>
                    <Paper className="px-2 py-2">
                      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" class="px-6 py-3">
                                Event
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Date
                              </th>
                              <th scope="col" class="px-6 py-3">
                                Time Left
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {events
                              .filter(
                                event =>
                                  new Date(
                                    new Date(event.endDate).setHours(23)
                                  ) >= new Date()
                              )
                              .sort(
                                (a, b) =>
                                  new Date(a.startDate) - new Date(b.startDate)
                              )
                              .map((event, index) => (
                                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                  <Link to={`/crm/events/${event.id}`}>
                                    <th
                                      scope="row"
                                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                      {event.name}
                                    </th>
                                  </Link>
                                  <td class="px-6 py-4">
                                    {getDateFormated(new Date(event.startDate))}{' '}
                                    - {getDateFormated(new Date(event.endDate))}
                                  </td>

                                  <td class="px-6 py-4">
                                    {Math.round(
                                      (new Date(
                                        new Date(event.startDate).setHours(23)
                                      ) -
                                        new Date()) /
                                        (24 * 60 * 60 * 1000)
                                    ) <= 0
                                      ? 'On going'
                                      : `${Math.round(
                                        (new Date(
                                          new Date(event.startDate).setHours(
                                            23
                                          )
                                        ) -
                                            new Date()) /
                                            (24 * 60 * 60 * 1000)
                                      )} Days`}{' '}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </Paper>
                  </Grid>
                )}
              </Grid>
              <Grid xs={12} lg={6} xl={6}>
                {user?.is_super_admin == 1 && (
                  <Grid xs={12} lg={12} xl={12}>
                    <Paper className="px-2 py-2">
                      <h3 class="text-2xl font-bold dark:text-white mb-3 ml-3 mt-1">
                        CRM
                      </h3>
                      <div className="flex-wrap flex">
                        <WelcomeCard
                          label={t('drawer.companies')}
                          link="/crm/companies"
                        />
                        <WelcomeCard
                          label={t('drawer.company_contacts')}
                          link="/crm/company-contacts"
                        />
                        <WelcomeCard
                          label={t('drawer.sponsorings')}
                          link="/crm/sponsorings"
                        />
                        <WelcomeCard
                          label="Dashboard Events"
                          link="/dashboards/events"
                        />
                        <WelcomeCard
                          label={t('drawer.exports')}
                          link="/exports/crm"
                        />
                        <WelcomeCard
                          label={t('drawer.projects')}
                          link="/crm/projects"
                        />
                        <WelcomeCard
                          label={t('drawer.events')}
                          link="/crm/events"
                        />
                        <WelcomeCard
                          label={t('drawer.timetracking')}
                          link="/crm/time-tracking"
                        />
                        <WelcomeCard
                          label={`Mobile ${  t('drawer.timetracking')}`}
                          link="/crm/mobile-time-tracking"
                        />
                      </div>
                    </Paper>
                  </Grid>
                )}

                <Grid xs={12} lg={12} xl={12}>
                  <Paper className="px-2 py-2">
                    <h3 class="text-2xl font-bold dark:text-white mb-3 ml-3 mt-1">
                      Toolbox
                    </h3>
                    <div className="flex-wrap flex">
                      <WelcomeCard
                        label="Mailvalidator"
                        link="/toolbox/mailvalidator"
                      />
                      {user?.is_super_admin == 1 && (
                        <WelcomeCard
                          label="Invoice"
                          link="/toolbox/create-invoice"
                        />
                      )}
                      <WelcomeCard
                        label="Namebadges"
                        link="/toolbox/create-namebadges"
                      />
                      <WelcomeCard
                        label="Tischschilder"
                        link="/toolbox/create-tablesigns"
                      />
                      <WelcomeCard
                        label="Zertifikate"
                        link="/toolbox/create-certificates"
                      />
                    </div>
                  </Paper>
                </Grid>
                {user?.is_super_admin == 1 && (
                  <Grid xs={12} lg={12} xl={12}>
                    <Paper className="px-2 py-2">
                      <h3 class="text-2xl font-bold dark:text-white mb-3 ml-3 mt-1">
                        User
                      </h3>
                      <div className="flex">
                        <WelcomeCard label="My Issues" link="/user/dashboard" />
                      </div>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Welcome

const parseAdjustStart = eventDate => {
  const date = new Date(new Date(eventDate).setHours(1))
  return date
}
const parseAdjustEnd = eventDate => {
  const date = new Date(new Date(eventDate).setHours(23))
  return date
}

const CustomItem = props => (
  <SchedulerItem {...props}>
    <span
      title="some aditional information"
      onClick={e => props.handleClick(props.description)}>
      {Children.map(props.children, c => (
        <c.type {...c.props} title={undefined} />
      ))}
      {!props.isAllDay && <SchedulerItemContent></SchedulerItemContent>}
    </span>
  </SchedulerItem>
)
