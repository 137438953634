import { Button } from '@mui/material'
import * as XLSX from 'xlsx'

const ExportCompanyContacts = props => {
  const { exportData, title } = props

  const handleClick = async () => {
    var data = []
    if (!exportData) {
      const expData = await props.getCompanyContacts('')
      data = parseData(expData.data)
    } else {
      data = parseData(exportData)
    }
    if (data?.length != 0) downloadExcel(data)
  }
  return <Button onClick={handleClick}>{title}</Button>
}

export default ExportCompanyContacts

const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sponsorings')
  XLSX.writeFile(workbook, 'CompanyContacts.xlsx')
}

const parseData = data =>
  data.map(companyContact => ({
    CompanyCategory: companyContact.company.category,
    Company: companyContact.company.name,
    Department: companyContact.department,
    Sex: companyContact.sex,
    Title: companyContact.title,
    FirstName: companyContact.firstname,
    LastName: companyContact.lastname,
    Email: companyContact.email,
    Phone: companyContact.phone,
    Mobile: companyContact.mobile,
    Remark: companyContact.remark,
    RemarkCompany: companyContact.company.remark
  }))
