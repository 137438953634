import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/* PanelBar need to be generateted automatic from this array */
const paths = [
  {
    path: '/welcome',
    index: '.0.0'
  },
  {
    path: '/user/dashboard',
    index: '.1.0'
  },
  {
    path: '/dashboard',
    index: '.2.0'
  },
  {
    path: '/toolbox/mailvalidator',
    index: '.2.1.0'
  },
  {
    path: '/toolbox/create-invoice',
    index: '.2.1.1'
  },
  {
    path: '/toolbox/create-namebadges',
    index: '.2.1.2'
  },
  {
    path: '/toolbox/create-tablesigns',
    index: '.2.1.3'
  },
  {
    path: '/toolbox/create-certificates',
    index: '.2.1.4'
  },
  {
    path: '/crm/projects',
    index: '.4.0'
  },
  {
    path: '/crm/events',
    index: '.4.1'
  },
  {
    path: '/crm/companies',
    index: '.4.2'
  },
  {
    path: '/crm/company-contacts',
    index: '.4.3'
  },
  {
    path: '/crm/sponsorings',
    index: '.4.4'
  },
  {
    path: '/crm/issues',
    index: '.4.5'
  },
  {
    path: '/crm/time-tracking',
    index: '.4.6'
  },
  {
    path: '/crm/mobile-time-tracking',
    index: '.4.7'
  },
  { path: '/exports/crm', index: '.5.0' },
  { path: '/exports/events-time-overview', index: '.5.1' },
  { path: '/dashboards/crm', index: '.6.0' }
]

const MainDrawer = ({ openedDrawer, toggleDrawer, user }) => (
  <div>
    <Drawer
      PaperProps={{
        className: 'rounded-r shadow-lg'
      }}
      variant="persistent"
      open={openedDrawer}
      onClose={toggleDrawer}
      className="hidden sm:block">
      <div style={{ width: 256 }}>
        <div
          className="w-full text-center text-lg"
          style={{ height: 70, lineHeight: '70px' }}>
          Portal
        </div>
        <Divider />
        <PanelItems user={user} />
      </div>
    </Drawer>
    <Drawer
      open={openedDrawer}
      onClose={toggleDrawer}
      className="block sm:hidden rounded-r">
      <div style={{ width: 256 }}>
        <div
          className="w-full text-center text-lg"
          style={{ height: 52, lineHeight: '52px' }}>
          Portal
        </div>
        <Divider />
        <PanelItems user={user} />
      </div>
    </Drawer>
  </div>
)

export default MainDrawer

const PanelItems = ({ user }) => {
  const { t } = useTranslation('common')
  const location = useLocation()
  const navigate = useNavigate()
  const onSelect = event => {
    if (event.target.props.route) {
      navigate(event.target.props.route)
    }
  }
  const setSelectedIndex = pathName => {
    let currentPath = paths.find(item => item.path === pathName)
    return currentPath?.index ?? '.'
  }

  const selectedRoute = setSelectedIndex(location.pathname)
  return (
    <PanelBar
      style={{
        borderStyle: 'none',
        padding: '5px 10px 5px 10px'
      }}
      className="border-none"
      selected={selectedRoute}
      onSelect={onSelect}>
      <PanelBarItem title={t('drawer.welcome')} route="/welcome" index=".0.0" />
      {user?.is_super_admin == 1 && (
        <PanelBarItem title={t('drawer.user')} expanded={true} index=".0.0">
          <PanelBarItem
            title={t('drawer.user.dashboard')}
            route="/user/dashboard"
            index=".1.0"
            onClick={() => {console.log('test')}}
          />
        </PanelBarItem>
      )}
      <PanelBarItem open title={'System'} expanded={false}>
        <PanelBarItem title={'Dashboard'} route="/dashboard" index=".1.0" />
        <PanelBarItem title={'Toolbox'} expanded={false}>
          <PanelBarItem
            title={'Mailvalidator'}
            route="/toolbox/mailvalidator"
          />
          {user?.is_super_admin == 1 && (
            <PanelBarItem
              title={'Create Invoice'}
              route="/toolbox/create-invoice"
            />
          )}
          <PanelBarItem
            title={'Create Nambebadges'}
            route="/toolbox/create-namebadges"
          />
          <PanelBarItem
            title={'Create Tablesigns'}
            route="/toolbox/create-tablesigns"
          />
          <PanelBarItem
            title={'Create Certificates'}
            route="/toolbox/create-certificates"
          />
          <PanelBarItem title={'Invoices'} disabled={true} />
        </PanelBarItem>
      </PanelBarItem>
      <PanelBarItem title={t('drawer.events')} disabled={true} />
      {user?.is_super_admin == 1 && (
        <PanelBarItem open title={'CRM'} expanded={true}>
          <PanelBarItem
            title={t('drawer.projects')}
            route="/crm/projects"
            index=".2.0"
          />
          <PanelBarItem
            title={t('drawer.events')}
            route="/crm/events"
            index=".2.1"
          />
          <PanelBarItem
            title={t('drawer.companies')}
            route="/crm/companies"
            index=".2.2"
          />
          <PanelBarItem
            title={t('drawer.company_contacts')}
            route="/crm/company-contacts"
            index=".2.3"
          />
          <PanelBarItem
            title={t('drawer.sponsorings')}
            route="/crm/sponsorings"
            index=".2.4"
          />
          <PanelBarItem
            title={t('drawer.issues')}
            route="/crm/issues"
            index=".2.5"
          />
          <PanelBarItem
            title={t('drawer.timetracking')}
            route="/crm/time-tracking"
            index=".2.6"
          />
          <PanelBarItem
            title={'Mobile '+ t('drawer.timetracking')}
            route="/crm/mobile-time-tracking"
            index=".2.7"
          />
        </PanelBarItem>
      )}
      {user?.is_super_admin == 1 && (
        <PanelBarItem open title={t('drawer.exports')} expanded={true}>
          <PanelBarItem title={'CRM'} route="/exports/crm" index=".3.0" />
          <PanelBarItem title={'Events Time Overview'} route="/exports/events-time-overview" index=".3.1" />
        </PanelBarItem>
      )}
      {user?.is_super_admin == 1 && (
        <PanelBarItem open title={t('drawer.dashboards')} expanded={true}>
          <PanelBarItem
            title={'Events'}
            route="/dashboards/events"
            index=".5.0"
          />
        </PanelBarItem>
      )}
    </PanelBar>
  )
}
