import React, { useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { useTranslation } from 'react-i18next'

const langs = [
  {
    label: 'EN',
    value: 'en'
  },
  {
    label: 'DE',
    value: 'de'
  }
]
const LANGUAGE_CODE = {
  'en-US': 'en',
  'de-DE': 'de',
  de: 'de',
  en: 'en'
}
const LanguageDropdown = () => {
  const { i18n } = useTranslation(['common'])
  const [lang, setLang] = useState(
    langs.filter(
      lang => lang.value === LANGUAGE_CODE[i18n.language] ?? ' en'
    )[0]
  )
  const handleChange = event => {
    i18n.changeLanguage(event.target.value.value)
    setLang(event.target.value)
  }
  return (
    <DropDownList
      style={{
        width: '80px'
      }}
      data={langs}
      textField="label"
      dataItemKey="value"
      value={lang}
      onChange={handleChange}
    />
  )
}
export default LanguageDropdown
