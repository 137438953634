import { useTranslation } from 'react-i18next'

const CellStateTranslator = props => {
  const { t } = useTranslation(props.ns)
  if (props.rowType === 'data') {
    const field = props.field || ''
    return (
      <td
        title={t(`${props.tbl}.${field}.${props.dataItem[field]}`)}
        {...props}
        style={{ whiteSpace: 'nowrap', width: '100%' }}>
        {t(`${props.tbl}.${field}.${props.dataItem[field]}`)}
      </td>
    )
  } else {
    return <td></td>
  }
}
export default CellStateTranslator
