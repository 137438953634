import { Button } from '@mui/material'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'

const ExportSponsorings = props => {
  const { exportData, pivotData, title } = props
  const { t } = useTranslation(['crm'])
  const handleClick = async () => {
    var data = []
    if (!exportData) {
      const expData = await props.getSponsorings('')
      const pivData = await props.getSponsoringBenefits('')
      data = parseData(expData.data, pivData.data, t)
    } else {
      data = parseData(exportData, pivotData, t)
    }

    downloadExcel(data)
  }
  return <Button onClick={handleClick}>{title}</Button>
}

export default ExportSponsorings
const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const information = XLSX.utils.json_to_sheet([
    {
      State: 0,
      Label: 'Contract pending'
    },
    {
      State: 1,
      Label: 'Invoice sufficient'
    },
    {
      State: 2,
      Label: 'Contract exists - invoice outstanding'
    },
    {
      State: 3,
      Label: 'Finished'
    }
  ])
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sponsorings')
  XLSX.utils.book_append_sheet(workbook, information, 'Weitere Informationen')
  XLSX.writeFile(workbook, 'Sponsorings.xlsx')
}

const parseData = (data, pivotData, t) =>
  data.map(sponsoring => {
    var pData = {}
    pivotData.map((pivot, key) => {
      pData[pivot.label] =
        sponsoring.sponsoringBenefits.filter(eq => eq.id == pivot.id)[0]
          ?.remark ?? ''
    })
    return {
      ID: sponsoring.id,
      Event: sponsoring.event?.name,
      Company: sponsoring.company.name,
      State: t(`sponsorings.form.state.${sponsoring.state}`),
      SponsoringAmount: sponsoring.sponsoringAmount,
      MainContact: sponsoring.mainContactId
        ? `${sponsoring.mainContact?.firstname} ${sponsoring.mainContact?.lastname}`
        : '',
      MainContactEmail: sponsoring.mainContactId
        ? sponsoring.mainContact?.email
        : '',
      MainContactNumber: sponsoring.mainContactId
        ? sponsoring.mainContact?.mobile ?? sponsoring.mainContact?.phone
        : '',
      AdministrationContact: sponsoring.administrationContactId
        ? `${sponsoring.administrationContact?.firstname} ${sponsoring.administrationContact?.lastname}`
        : '',
      AdministrationContactEmail: sponsoring.administrationContactId
        ? sponsoring.administrationContact?.email
        : '',
      AdministrationContactNumber: sponsoring.administrationContactId
        ? sponsoring.administrationContact?.mobile ??
          sponsoring.administrationContact?.phone
        : '',
      OtherContact: sponsoring.marketingContactId
        ? `${sponsoring.marketingContact?.firstname} ${sponsoring.marketingContact?.lastname}`
        : '',
      MarketingContactEmail: sponsoring.marketingContactId
        ? sponsoring.marketingContact?.email
        : '',
      MarketingContactNumber: sponsoring.marketingContactId
        ? sponsoring.marketingContact?.mobile ??
          sponsoring.marketingContact?.phone
        : '',
      Remark: sponsoring.remark,
      ...pData
    }
  })
