import {
  FormInput,
  FormTextArea,
  FormDatePicker,
  FormDropDownList
} from 'components/ReactFormComponents/Index'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'
import InputError from 'components/InputError'
import { requiredValidator } from 'components/ReactFormComponents/Validators'
import { useState, useEffect, cloneElement } from 'react'
import axios from 'lib/axios'
import { useTranslation } from 'react-i18next'
import { filterChange } from 'components/FormsDropdownFilter/Index'

const KendoForm = props => {
  const { t } = useTranslation(['crm', 'common'])
  const { errors, handleSubmit, item, mode, cancleEditModal } = props
  const [events, setEvents] = useState([])
  const [eventsFiltered, setEventsFiltered] = useState([])
  const [users, setUsers] = useState([])
  const [usersFiltered, setUsersFiltered] = useState([])
  const [categories, setCategories] = useState([])
  const [categoriesFiltered, setCategoriesFiltered] = useState([])
  const [subcategories, setSubcategories] = useState([])
  const [subcategoriesFiltered, setSubcategoriesFiltered] = useState([])

  useEffect(async () => {
    var dataEvents = await axios.get(
      '/api/v1/events?order_by=-name&includeProject=true'
    )
    setEvents(dataEvents.data.data)
    setEventsFiltered(dataEvents.data.data)
    var dataUsers = await axios.get(
      '/api/v1/users?order_by=-name&is_active[eq]=1'
    )
    setUsers(dataUsers.data.data)
    setUsersFiltered(dataUsers.data.data)
  }, [])
  useEffect(() => {
    if (item && item?.event?.id && item.length != 0) {
      var tmp = events.filter(event => item.event.id == event.id)
      var cats = tmp[0]?.project.projectType.issueCategories ?? []
      setCategories(cats)
      setCategoriesFiltered(cats)
      var tmp2 =
        cats.length != 0
          ? cats.filter(cat => item.issueCategoryId == cat.id)
          : []
      var subCats = tmp2[0]?.issueSubcategories ?? []
      setSubcategories(subCats)
      setSubcategoriesFiltered(subCats)
    } else {
      setCategories([])
      setCategoriesFiltered([])
      setSubcategories([])
      setSubcategoriesFiltered([])
    }
  }, [item, events])
  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      initialValues={
        item.id
          ? {
              ...item
            }
          : { state: '0', priority: '0' }
      }
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 650
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'eventId'}
                  component={FormDropDownList}
                  filterable={true}
                  onFilterChange={event =>
                    filterChange(event, events, setEventsFiltered)
                  }
                  label={t('issues.dataGrid.event')}
                  textField="name"
                  dataItemKey="id"
                  required
                  validator={requiredValidator}
                  onChange={event => {
                    setCategories(
                      event.value.project.projectType.issueCategories
                    )
                    setCategoriesFiltered(
                      event.value.project.projectType.issueCategories
                    )
                  }}
                  data={eventsFiltered}
                />
                <InputError
                  messages={errors.data?.errors?.eventId}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 flex-1">
                <Field
                  name={'userId'}
                  component={FormDropDownList}
                  itemRender={itemRender}
                  valueRender={valueRender}
                  filterable={true}
                  onFilterChange={event =>
                    filterChange(event, users, setUsersFiltered)
                  }
                  label={t('issues.dataGrid.assignedUser')}
                  textField="name"
                  dataItemKey="id"
                  data={usersFiltered}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.userId}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                name={'issueCategoryId'}
                component={FormDropDownList}
                label={t('issues.dataGrid.category')}
                textField="label"
                filterable={true}
                onFilterChange={event =>
                  filterChange(event, categories, setCategoriesFiltered)
                }
                dataItemKey="id"
                disabled={categories.length == 0}
                data={categoriesFiltered}
                onChange={category => {
                  setSubcategories(category.value.issueSubcategories)
                  setSubcategoriesFiltered(category.value.issueSubcategories)
                  formRenderProps.onChange('issueSubcategoryId', '')
                }}
              />
              <InputError
                messages={errors.data?.errors?.issueCategoryId}
                className="mt-2"
              />
            </div>
            <div className="mb-3">
              <Field
                name={'issueSubcategoryId'}
                component={FormDropDownList}
                label={t('issues.dataGrid.subcategory')}
                textField="label"
                filterable={true}
                onFilterChange={event =>
                  filterChange(event, subcategories, setSubcategoriesFiltered)
                }
                dataItemKey="id"
                disabled={subcategoriesFiltered.length == 0}
                data={subcategoriesFiltered}
              />
              <InputError
                messages={errors.data?.errors?.issueSubcategoryId}
                className="mt-2"
              />
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'dueDate'}
                  component={FormDatePicker}
                  format="dd.MM.yyyy"
                  label={t('issues.dataGrid.dueDate')}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.dueDate}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'state'}
                  component={FormDropDownList}
                  label={t('issues.dataGrid.state')}
                  textField="label"
                  dataItemKey="value"
                  required
                  validator={requiredValidator}
                  data={[
                    {
                      label: t('issues.state.0'),
                      value: '0'
                    },
                    {
                      label: t('issues.state.1'),
                      value: '1'
                    },
                    {
                      label: t('issues.state.2'),
                      value: '2'
                    },
                    {
                      label: t('issues.state.3'),
                      value: '3'
                    }
                  ]}
                />
                <InputError
                  messages={errors.data?.errors?.state}
                  className="mt-2"
                />
              </div>
              <div className="mb-3 mr flex-1">
                <Field
                  name={'priority'}
                  component={FormDropDownList}
                  label={t('issues.dataGrid.priority')}
                  textField="label"
                  dataItemKey="value"
                  required
                  validator={requiredValidator}
                  data={[
                    {
                      label: t('issues.priority.0'),
                      value: '0'
                    },
                    {
                      label: t('issues.priority.1'),
                      value: '1'
                    },
                    {
                      label: t('issues.priority.2'),
                      value: '2'
                    },
                    {
                      label: t('issues.priority.3'),
                      value: '3'
                    }
                  ]}
                />
                <InputError
                  messages={errors.data?.errors?.priority}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                name={'label'}
                component={FormInput}
                label={t('issues.dataGrid.label')}
                required
                validator={requiredValidator}
              />
              <InputError
                messages={errors.data?.errors?.label}
                className="mt-2"
              />
            </div>
            <div className="mb-3">
              <Field
                id={'remark'}
                name={'remark'}
                label={t('issues.dataGrid.remark')}
                optional={true}
                autoSize={true}
                component={FormTextArea}
              />
              <InputError
                messages={errors.data?.errors?.remark}
                className="mt-2"
              />
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <Button onClick={cancleEditModal} type={'button'}>
                {t('button.cancel', { ns: 'common' })}
              </Button>
              {mode == 'update' ? (
                <Button
                  themeColor={'primary'}
                  type={'submit'}
                  disabled={!formRenderProps.allowSubmit}>
                  {t('issues.updateBtn')}
                </Button>
              ) : (
                <Button
                  themeColor={'primary'}
                  type={'submit'}
                  disabled={!formRenderProps.allowSubmit}>
                  {t('issues.createBtn')}
                </Button>
              )}
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm

const itemRender = (li, itemProps) => {
  const itemChildren = (
    <span>
      {itemProps.dataItem.id} - {li.props.children}{' '}
      {itemProps.dataItem.lastname}
    </span>
  )
  return cloneElement(li, li.props, itemChildren)
}
const valueRender = (element, value) => {
  if (!value) {
    return element
  }
  const children = [
    <span key={2}>
      {value.id} - {element.props.children} {value.lastname}
    </span>
  ]
  return cloneElement(
    element,
    {
      ...element.props
    },
    children
  )
}
