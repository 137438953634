import React, { useState, useEffect } from 'react'
import {
  AppBar,
  AppBarSection,
  AppBarSpacer
} from '@progress/kendo-react-layout'
import LanguageDropdown from 'components/KendoAppBar/LanguageDropdown/Index'
import Button from '@mui/material/Button'
import { Menu, MenuItem } from '@mui/material'
import { useAuth } from 'hooks/auth'
import { FaBook, FaTypo3 } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'

const KendoAppBar = ({ toggleDrawer }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  const open = Boolean(anchorEl)
  const { user, logout } = useAuth()
  const toggleUserMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.altKey == true && e.key == 'a') {
        navigate('/welcome')
      }
      if (e.altKey == true && e.key == 's') {
        navigate('/crm/sponsorings')
      }
      if (e.altKey == true && e.key == 'q') {
        navigate('/crm/companies')
      }
      if (e.altKey == true && e.key == 'w') {
        navigate('/user/dashboard')
      }
    })
  }, [])
  return (
    <React.Fragment>
      <AppBar className="flex-1 bg-white" themeColor="inherit">
        <AppBarSection>
          <button
            onClick={toggleDrawer}
            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <span className="k-icon k-i-menu" />
          </button>
        </AppBarSection>

        <AppBarSection>
          <Link
            to="/welcome"
            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base">
            <span className="k-icon k-i-home" />
          </Link>
        </AppBarSection>

        <AppBarSpacer />

        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={() =>
            window.open(
              'https://www.conferencing.infinite-science.de/typo3/',
              '_blank'
            )
          }>
          <FaTypo3 className="mr-1" />
          Typo
        </Button>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={() => window.open('https://wiki.insci.de', '_blank')}>
          <FaBook className="mr-1" />
          Wiki
        </Button>
        <LanguageDropdown />
        <AppBarSection>
          <span className="k-appbar-separator" />
        </AppBarSection>

        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={toggleUserMenu}>
          Hi, {user?.name}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </AppBar>
      <style>{`
                  .flex-1{
                      flex:1
                  }
                  body {
                      background: #dfdfdf;
                  }
                  #basic-menu{
                    max-width:200px !important;
                  }
                  #basic-menu li{
                    overflow:hidden;
                    text-overflow: ellipsis;
                  }
              `}</style>
    </React.Fragment>
  )
}

export default KendoAppBar
