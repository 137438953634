import axios from 'lib/axios'
import useSWR from 'swr'

export const useUsers = ({ query }) => {
  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const {
    data: usersHook,
    error,
    mutate
  } = useSWR(`/api/v1/users${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/users${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  )

  return {
    usersHook
  }
}
