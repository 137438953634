import { PDFExport } from '@progress/kendo-react-pdf'
import './fonts.css'

const TablesignTemplate = ({ tablesignPdf, data }) => (
  <PDFExport
    forcePageBreak=".page-break"
    fileName={'Tablesigns.pdf'}
    ref={tablesignPdf}
    className="page-container hidden-on-narrow"
    style={{ display: 'none' }}>
    <div className="pdf-page size-a4">
      {data.map((item, index) => (
        <div className={`inner-page ${index != 0 ? 'page-break' : ''}`}>
          <div
            className="absolute leading-tight"
            style={{
              transform: 'rotate(90deg)',
              fontFamily: 'Open Sans',
              fontSize: 60,
              right: '-0mm',
              bottom: '50mm',
              top: '99mm',
              left: '-50mm',
              maxHeight: '1.25em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              textAlign: 'center',
              fontWeight: 'bold',
              overflow: 'hidden'
            }}>
            {item.name}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              transform: 'rotate(90deg)',
              fontFamily: 'Open Sans',
              fontSize: 43,
              right: '32mm',
              bottom: '50mm',
              top: '102.5mm',
              left: '-82mm',
              maxHeight: '1.25em',
              lineHeight: '1.25em',
              textAlign: 'center',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.description}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              transform: 'rotate(-90deg)',
              fontFamily: 'Open Sans',
              fontSize: 60,
              right: '-50mm',
              bottom: '50mm',
              top: '99mm',
              left: '-0mm',
              maxHeight: '1.25em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              textAlign: 'center',
              fontWeight: 'bold',
              overflow: 'hidden'
            }}>
            {item.name}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              transform: 'rotate(-90deg)',
              fontFamily: 'Open Sans',
              fontSize: 43,
              right: '-82mm',
              bottom: '50mm',
              top: '102.5mm',
              left: '32mm',
              maxHeight: '1.25em',
              lineHeight: '1.25em',
              textAlign: 'center',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.description}
          </div>
        </div>
      ))}
    </div>
  </PDFExport>
)

export default TablesignTemplate
