import { useState, useEffect } from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'
import {
  Notification,
  NotificationGroup
} from '@progress/kendo-react-notification'
import { Fade } from '@progress/kendo-react-animation'
import { FaPen } from 'react-icons/fa'

const EditModal = props => {
  const {
    editModalVisible,
    cancleEditModal,
    mode,
    errors,
    status,
    setStatus,
    children,
    title,
    setFormButtonsDisabled
  } = props
  const [notificationsState, setNotificationsState] = useState({
    success: { state: false, message: 'Your data was saved successfully' }
  })

  useEffect(() => {
    if (status?.status === 200) {
      setNotificationsState({
        ...notificationsState,
        success: { ...notificationsState.success, state: true }
      })
      cancleEditModal()
      setStatus(null)
    } else if (status?.status === 201) {
      setNotificationsState({
        ...notificationsState,
        success: { ...notificationsState.success, state: true }
      })
      cancleEditModal()
      setStatus(null)
    } else {
      setTimeout(() => {
        setNotificationsState({
          ...notificationsState,
          success: { ...notificationsState.success, state: false }
        })
      }, 10000)
    }
    if (status?.status || errors?.status) setFormButtonsDisabled(false)
  }, [status, errors])
  return (
    <div>
      {editModalVisible && (
        <Dialog
          className="edit-modal"
          title={
            <div className="text-center mt-4 w-full ml-7">
              <div className="text-3xl font-bold">
                {mode === 'update' ? `Edit ${title}` : `Add ${title}`}
              </div>
              <div
                className="p-1 absolute left-0 right-0 mr-auto ml-auto b rounded-full btn-blue w-min"
                style={{ top: -50 }}>
                <FaPen
                  //className="p-1 absolute left-0 right-0 mr-auto ml-auto b rounded-full btn-red"
                  className="m-2"
                  size={50}
                />
              </div>
            </div>
          }
          width={800}
          onClose={cancleEditModal}>
          {children}
        </Dialog>
      )}
      <NotificationGroup
        style={{
          right: 10,
          bottom: 4,
          alignItems: 'flex-start',
          flexWrap: 'wrap-reverse',
          zIndex: 100
        }}>
        {notificationsState.success.state && (
          <Fade transitionEnterDuration={800} transitionExitDuration={1000}>
            <Notification
              type={{
                style: 'success',
                icon: true
              }}
              closable={true}
              onClose={() =>
                setNotificationsState({
                  ...notificationsState,
                  success: {
                    ...notificationsState,
                    state: false
                  }
                })
              }>
              {notificationsState.success.message}
            </Notification>
          </Fade>
        )}
      </NotificationGroup>
    </div>
  )
}

export default EditModal
