import useSWR from 'swr'
import axios from 'lib/axios'

export const useCompanyContacts = ({ middleware, query } = {}) => {
  const {
    data: companyContactsHook,
    error,
    mutate
  } = useSWR(`/api/v1/company-contacts${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/company-contacts${query ? query : ''}`)
      .then(res => res.data)
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const putCompanyContact = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/company-contacts/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postCompanyContact = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/company-contacts', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }

  const getCompanyContacts = async () => {
    await csrf()
    return axios
      .get('/api/v1/company-contacts?includeCompany=true')
      .then(res => {
        return res.data
      })
      .catch(error => error.response)
  }

  const deleteCompanyContact = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)

    axios
      .delete(`/api/v1/company-contacts/${props.dataItem.id}`)
      .then(status => {
        setStatus(status)
        mutate()
      })
  }

  return {
    companyContactsHook,
    putCompanyContact,
    postCompanyContact,
    deleteCompanyContact,
    getCompanyContacts
  }
}
