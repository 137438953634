import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import * as xlsx from 'xlsx'
import CertificateTemplate from 'components/pages/toolbox/create-certificates/CertificateTemplate'
import { useRef, useState } from 'react'
import fileToDownload from 'components/pages/toolbox/create-certificates/ExcelTemplate/CertificateImportTemplate.xlsx'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { TextBox, TextArea } from '@progress/kendo-react-inputs'
import { Label as KLabel } from '@progress/kendo-react-labels'
import { DropDownList } from '@progress/kendo-react-dropdowns'

const CertificateCreation = () => {
  document.title = 'Create tablesigns'
  const [tablesignData, setTablesignData] = useState([])
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null)
  const [selctedCertificateTemplate, setSelctedCertificateTemplate] = useState({
    label: 'Stadard Ärztekammer Vorlage',
    value: '0'
  })
  const tablesignPdf = useRef(null)
  const [selected, setSelected] = useState(0)
  const handleSelect = e => {
    setSelected(e.selected)
  }
  const readUploadFile = async e => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = async e => {
        const data = e.target.result
        const workbook = xlsx.read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const tablesignDataArray = xlsx.utils.sheet_to_json(worksheet, {
          defval: ''
        })
        setTablesignData(tablesignDataArray)
      }
      reader.readAsArrayBuffer(e.target.files[0])
      e.target.value = null
    }
  }
  const readUploadBackgroundFile = async e => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = async e => {
        setSelectedBackgroundImage(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
      e.target.value = null
    }
  }
  const onChangeInvoiceData = event => {
    let id = event.target.element?.current?.id ?? event.target.element.id
    setTablesignData([{ ...tablesignData[0], [id]: event.value }])
  }
  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = fileToDownload
    link.download = 'CertificateTemplate.xlsx' // Set the desired name of the downloaded file
    link.click()
  }
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">
            Certificate generator
          </Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 shadow-lg mb-4">
                  <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                    Anleitung zum Erstellen der Zertifikate
                  </h2>
                  <ol className="mb-4 max-w-md space-y-1 list-decimal list-inside dark:text-gray-400">
                    <li>
                      Excel Vorlage herunterladen{' '}
                      <button
                        type="button"
                        onClick={handleDownload}
                        className="px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        Download
                      </button>
                    </li>
                    <li>
                      Excel-Datei mit den Daten der Personen hochladen. Es ist
                      möglich in der Tabelle zu prüfen ob die Daten korrekt
                      hochgeladen wurden.
                    </li>
                    <li>PDF mit den Tischschildern herunterladen</li>
                  </ol>
                  <span className="max-w-md space-y-1 list-decimal list-inside dark:text-gray-400 text-sm">
                    Tipps: Sollte der Name oder die Beschreibung zu lang sein,
                    wird diese einfach abgeschnitten.
                  </span>
                </Paper>
                <Paper className="p-2 flex shadow-lg">
                  <div>
                    <div
                      style={{
                        position: 'absolute',
                        left: '-1000px',
                        top: 0
                      }}>
                      <CertificateTemplate
                        tablesignPdf={tablesignPdf}
                        data={tablesignData}
                        backgroundImage={selectedBackgroundImage}
                      />
                    </div>
                    <TabStrip
                      style={{ width: '400px' }}
                      selected={selected}
                      onSelect={handleSelect}>
                      <TabStripTab title="Upload">
                        <div className="flex flex-col">
                          <form className="m-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              Upload Excel File:
                            </label>
                            <input
                              type="file"
                              onChange={readUploadFile}
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                            />
                          </form>
                        </div>
                      </TabStripTab>
                      <TabStripTab title="Form">
                        <div className="m-2">
                          <div className="mr-2" style={{ flex: 1 }}>
                            <KLabel editorId={'name'}>Name:</KLabel>
                            <TextBox
                              id="name"
                              value={tablesignData[0]?.name ?? ''}
                              onChange={onChangeInvoiceData}
                            />
                          </div>
                          <div style={{ flex: 3 }}>
                            <KLabel editorId={'location'}>Location:</KLabel>
                            <TextBox
                              id="location"
                              value={tablesignData[0]?.location ?? ''}
                              onChange={onChangeInvoiceData}
                            />
                          </div>
                          <div className="flex">
                            <button
                              type="button"
                              onClick={() => setTablesignData([])}
                              className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200">
                              Clear
                            </button>
                          </div>
                        </div>
                      </TabStripTab>
                    </TabStrip>
                    <div className="mb-3 flex-1">
                      <div>Choose Certificate Template:</div>
                      <DropDownList
                        data={[
                          { label: 'Stadard Ärztekammer Vorlage', value: '0' },
                          { label: 'Special Vorlage', value: '1' }
                        ]}
                        value={selctedCertificateTemplate}
                        textField="label"
                        dataItemKey="value"
                        onChange={e =>
                          setSelctedCertificateTemplate(e.target.value)
                        }
                        style={{
                          width: '300px'
                        }}
                      />
                    </div>
                    <form className="m-2">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload background file:
                      </label>
                      <input
                        type="file"
                        onChange={readUploadBackgroundFile}
                        accept=".jpg"
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      />
                      <p
                        class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                        id="file_input_help">
                        JPG, Export from A4 PDF.
                      </p>
                    </form>
                    {tablesignData.length != 0 && (
                      <button
                        className="m-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2"
                        disabled={tablesignData.length == 0}
                        onClick={() => tablesignPdf.current.save()}>
                        {' '}
                        Download Tabelsigns PDF
                      </button>
                    )}
                  </div>
                  {selectedBackgroundImage && (
                    <img
                      className="w-96"
                      src={selectedBackgroundImage}
                      alt="Selected"
                    />
                  )}
                  <div className="flex-1 ml-4 ">
                    <span className="text-xl font-bold">
                      Excel uploaded data:
                    </span>
                    <table className="w-full table-auto mt-4 min-w-full text-sm text-left">
                      <thead>
                        <tr>
                          <th className="border px-2 py-1">Name</th>
                          <th className="border px-2 py-1">Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tablesignData.length != 0 ? (
                          tablesignData.map((data, index) => (
                            <tr
                              className="border-b dark:border-neutral-500"
                              key={index}>
                              <td className="py-1 px-3">{data.name}</td>
                              <td className="py-1 px-3">{data.location}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>No Data</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default CertificateCreation
