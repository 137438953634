import {
  FormInput,
  FormTextArea,
  FormDropDownList,
  FormNumericTextBox
} from 'components/ReactFormComponents/Index'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import InputError from 'components/InputError'
import { requiredValidator } from 'components/ReactFormComponents/Validators'
import { useState, useEffect } from 'react'
import KendoFormButton from 'components/KendoFormButton/Index'

const KendoForm = props => {
  const {
    errors,
    handleSubmit,
    item,
    mode,
    cancleEditModal,
    projectTypesHook,
    formButtonsDisabled
  } = props
  const [projectTypes, setProjectTypes] = useState([])

  useEffect(() => {
    setProjectTypes(projectTypesHook)
  }, [projectTypesHook])
  return (
    <Form
      className="w-96"
      onSubmit={handleSubmit}
      initialValues={item}
      render={formRenderProps => (
        <FormElement
          style={{
            maxWidth: 800
          }}>
          <fieldset className={'k-form-fieldset'}>
            <div className="mb-3">
              <Field
                name={'projectTypeId'}
                component={FormDropDownList}
                label={'Project Type'}
                textField="label"
                dataItemKey="id"
                required
                validator={requiredValidator}
                data={projectTypes}
              />
              <InputError
                messages={errors.data?.errors?.projectTypeId}
                className="mt-2"
              />
            </div>
            <div className="flex justify-between">
              <div className="mb-3 mr-3 flex-1">
                <Field
                  name={'internId'}
                  component={FormNumericTextBox}
                  label={'Intern ID'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.internId}
                  className="mt-2"
                />
              </div>

              <div className="mb-3" style={{ flex: 3 }}>
                <Field
                  name={'name'}
                  component={FormInput}
                  label={'Project name'}
                  required
                  validator={requiredValidator}
                />
                <InputError
                  messages={errors.data?.errors?.name}
                  className="mt-2"
                />
              </div>
            </div>
            <div className="mb-3">
              <Field
                name={'projectEmails'}
                type={'email'}
                component={FormTextArea}
                autoSize={true}
                hint="Plese provide the emails seperated by a ';' e.g. 't@test.de;r@test.de'"
                label={'Project emails'}
              />
              <InputError
                messages={errors.data?.errors?.projectEmails}
                className="mt-2"
              />
              <InputError
                messages={errors.data?.errors['projectEmails.0'] ?? ''}
                className="mt-2"
              />
              <InputError
                messages={errors.data?.errors['projectEmails.1'] ?? ''}
                className="mt-2"
              />
              <InputError
                messages={errors.data?.errors['projectEmails.2'] ?? ''}
                className="mt-2"
              />
            </div>
            <div className="mb-3">
              <Field
                name={'website'}
                component={FormInput}
                label={'Website'}
                hint="Plese provide the website in folowing format 'https://example.de'"
              />
              <InputError
                messages={errors.data?.errors?.website}
                className="mt-2"
              />
            </div>
            <div className="mb-3">
              <Field
                id={'remark'}
                name={'remark'}
                label={'Comment'}
                optional={true}
                autoSize={true}
                component={FormTextArea}
              />
              <InputError
                messages={errors.data?.errors?.remark}
                className="mt-2"
              />
            </div>
            <div className="k-actions k-actions-horizontal k-window-actions k-dialog-actions k-actions-stretched">
              <KendoFormButton
                mode={mode}
                updateText={'Update project'}
                addText={'Create project'}
                formButtonsDisabled={formButtonsDisabled}
                disabled={!formRenderProps.allowSubmit}
                cancleEditModal={cancleEditModal}
              />
            </div>
          </fieldset>
        </FormElement>
      )}
    />
  )
}

export default KendoForm
