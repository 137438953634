import { GridColumn as Column } from '@progress/kendo-react-grid'
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter
} from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import TruncatedTooltipMailCell from 'components/KendoDataGrid/TruncatedTooltipMailCell/Index'
import { useSearchParams } from 'react-router-dom'
import NamebadgeTemplate from 'components/pages/toolbox/create-namebadges/NamebadgeTemplate'
import { useRef, useState, useEffect } from 'react'
import { FaAddressCard } from 'react-icons/fa'
import CompanyInfoCell from 'components/KendoDataGrid/CompanyInfoCell/Index'
import CompanyContactInfoCell from 'components/KendoDataGrid/CompanyContactInfoCell/Index'

const CompanyContactsDataGrid = props => {
  const [queryParameters] = useSearchParams()
  const namebadgePdf = useRef(null)
  const [namebadgeData, setNamebadgeData] = useState([])
  const handleDataItem = dataItem => {
    dataItem.title = dataItem.title?.value ?? dataItem.title
    dataItem.sex = dataItem.sex?.value ?? dataItem.sex
    dataItem.companyId = dataItem.companyId?.id ?? dataItem.companyId
    return dataItem
  }
  const handleDownload = props => {
    setNamebadgeData([
      {
        ...props.dataItem,
        id: props.dataItem.id + 10000,
        readyForExport: true,
        institution: props.dataItem.company?.name,
        location: `${props.dataItem.company?.city}, ${props.dataItem.company?.country}`
      }
    ])
  }
  useEffect(() => {
    if (namebadgeData[0]?.readyForExport) {
      namebadgePdf.current.save(() => {
        setNamebadgeData([])
      })
    }
  }, [namebadgeData])
  const CommandCellStart = props =>
    props.rowType === 'data' && (
      <td {...props}>
        <button className="btn btn-blue " onClick={() => handleDownload(props)}>
          <FaAddressCard />
        </button>
      </td>
    )
  return (
    <div className="w-full">
      <div
        style={{
          position: 'absolute',
          left: '-1000px',
          top: 0
        }}>
        <NamebadgeTemplate namebadgePdf={namebadgePdf} data={namebadgeData} />
      </div>
      <ItemKendoDataGrid
        {...props}
        initalFilterValue={queryParameters.get('initalFilterValue')}
        KendoForm={props1 =>
          KendoForm({ ...props1, companiesHook: props.companiesHook })
        }
        noRecords="No contacts found!"
        itemTitle="Company Contact"
        duplicate={props.duplicate}
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'title', label: 'Title:' },
          { id: 'firstname', label: 'Firstname:' },
          { id: 'lastname', label: 'Lastname:' },
          { id: 'phone', label: 'Phone:' },
          { id: 'mobile', label: 'Mobile:' },
          { id: 'department', label: 'Department:' }
        ]}
        initialGroups={
          queryParameters.get('company')
            ? []
            : [
                {
                  field: 'company.category'
                },
                {
                  field: 'company.name'
                }
              ]
        }
        initialFilter={
          queryParameters.get('company')
            ? {
                filters: [
                  {
                    filters: [
                      {
                        field: 'company.name',
                        operator: 'eq',
                        value: queryParameters.get('company')
                      }
                    ],
                    logic: 'or'
                  }
                ],
                logic: 'and'
              }
            : null
        }
        handleDataItem={handleDataItem}
        // eslint-disable-next-line max-len
        deleteDetailText="Also following tables are involved in deletion: Sponsoring contactpoints">
        <Column cell={CommandCellStart} width="45px" locked={true} />
        <Column
          field="id"
          title="ID"
          width="100px"
          columnMenu={ColumnMenu}
          cell={CompanyContactInfoCell}
        />
        <Column
          field="company.category"
          title="Category"
          width="200px"
          columnMenu={menuProps =>
            ColumnMenuCheckboxFilter(menuProps, props.itemHook?.data)
          }
        />
        <Column
          field="company.name"
          title="Company"
          width="250px"
          cell={CompanyInfoCell}
          className="truncate"
          columnMenu={menuProps =>
            ColumnMenuCheckboxFilter(menuProps, props.itemHook?.data)
          }
        />
        <Column
          field="department"
          title="Department"
          width="200px"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field="sex"
          title="Sex"
          width="100px"
          columnMenu={menuProps =>
            ColumnMenuCheckboxFilter(menuProps, props.itemHook?.data)
          }
        />
        <Column
          field="title"
          title="Title"
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="firstname"
          title="Firstname"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="lastname"
          title="Lastname"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="email"
          title="Email"
          width="200px"
          cell={TruncatedTooltipMailCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field="phone"
          title="Phone"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="mobile"
          title="Mobile"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="remark"
          title="Remark"
          width="300px"
          className="truncate"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default CompanyContactsDataGrid
