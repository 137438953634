import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import CompaniesDataGrid from 'components/pages/crm/companies/CompaniesDataGrid/Index'
import { useCompanies } from 'hooks/companies'

const Companies = () => {
  const { companiesHook, putCompany, postCompany, deleteCompany } =
    useCompanies({ query: '?includeCompanyContacts=true&order_by=nickname' })
  document.title = 'Companies'

  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Companies</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-col items-center shadow-lg">
                  <CompaniesDataGrid
                    itemHook={companiesHook}
                    putHook={putCompany}
                    postHook={postCompany}
                    deleteHook={deleteCompany}
                    initialGroups={[
                      {
                        field: 'category'
                      }
                    ]}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Companies
