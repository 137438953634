import { Button } from '@mui/material'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'

const ExportSponsoringsLite = props => {
  const { exportData, title } = props
  const { t } = useTranslation(['crm'])
  const handleClick = async () => {
    var data = []
    if (!exportData) {
      const expData = await props.getSponsorings('')
      const pivData = await props.getSponsoringBenefits('')
      data = parseData(expData.data, pivData.data, t)
    } else {
      data = parseData(exportData, t)
    }

    downloadExcel(data)
  }
  return <Button onClick={handleClick}>{title}</Button>
}

export default ExportSponsoringsLite

const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const information = XLSX.utils.json_to_sheet([
    {
      State: 0,
      Label: 'Contract pending'
    },
    {
      State: 1,
      Label: 'Invoice sufficient'
    },
    {
      State: 2,
      Label: 'Contract exists - invoice outstanding'
    },
    {
      State: 3,
      Label: 'Finished'
    }
  ])
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SponsoringsLite')
  XLSX.utils.book_append_sheet(workbook, information, 'Weitere Informationen')
  XLSX.writeFile(workbook, 'SponsoringsLite.xlsx')
}

const parseData = (data, t) =>
  data.map(sponsoring => {
    return {
      Company: sponsoring.company.name,
      State: t(`sponsorings.form.state.${sponsoring.state}`),
      SponsoringAmount: sponsoring.sponsoringAmount,
      Remark: sponsoring.remark,
    }
  })
