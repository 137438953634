import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import SponsoringsDataGrid from 'components/pages/crm/sponsorings/SponsoringsDataGrid/Index'
import { useSponsorings } from 'hooks/sponsorings'
import { useSponsoringBenefits } from 'hooks/sponsoringBenefits'
import { useEvents } from 'hooks/events'
import { useCompanies } from 'hooks/companies'
import { useCompanyContacts } from 'hooks/companyContacts'

const Sponsorings = () => {
  const { sponsoringsHook, putSponsoring, postSponsoring, deleteSponsoring } =
    useSponsorings({
      query: '?includeSponsoringBenefits=true&includeProject=true'
    })
  const { sponsoringBenefitsHook } = useSponsoringBenefits({
    query: '?order_by=seq'
  })
  const { eventsHook } = useEvents({ query: '?order_by=-name' })
  const { companiesHook } = useCompanies({
    query: '?includeCompanyContacts=true&order_by=name'
  })
  const { companyContactsHook } = useCompanyContacts({
    query: '?order_by=lastname'
  })
  document.title = 'Sponsorings'
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Sponsorings</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-col items-center shadow-lg">
                  <SponsoringsDataGrid
                    itemHook={sponsoringsHook}
                    putHook={putSponsoring}
                    postHook={postSponsoring}
                    deleteHook={deleteSponsoring}
                    sponsoringBenefitsHook={sponsoringBenefitsHook?.data ?? []}
                    eventsHook={eventsHook?.data ?? []}
                    companiesHook={companiesHook?.data ?? []}
                    companyContactsHook={companyContactsHook?.data ?? []}
                    initialGroups={[
                      {
                        field: 'event.project.name'
                      },
                      {
                        field: 'event.name'
                      }
                    ]}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Sponsorings
