import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import Invoice from 'components/pages/toolbox/create-invoice/invoice'

const InvoiceCreation = () => {
  document.title = 'Create invoice'
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Invoice generator</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-row items-center shadow-lg">
                  <Invoice />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default InvoiceCreation
