import {
  GridColumn as Column
} from '@progress/kendo-react-grid'
import { ColumnMenu } from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import UTCTimeCell from 'components/KendoDataGrid/UTCTimeCell/Index'

const TimerecordsDataGrid = props => {
  const handleDataItem = dataItem => {
    if (dataItem.sponsoringBenefits) {
      if (typeof dataItem.sponsoringBenefits === 'object') {
        dataItem.sponsoringBenefits = Object.values(dataItem.sponsoringBenefits)
      }
      dataItem.sponsoringBenefits.map(value => {
        if (value.id.id) {
          value.label = value.id.label
        }
        value.sponsoring_benefit_id = value.id.id ?? value.id
        return value
      })
    } else {
      dataItem.sponsoringBenefits = []
    }
    dataItem.state = dataItem.state.value ?? dataItem.state
    dataItem.companyId = dataItem.companyId.id ?? dataItem.companyId
    dataItem.eventId = dataItem.eventId.id ?? dataItem.eventId
    return dataItem
  }
  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        KendoForm={KendoForm}
        noRecords="No timerecords found!"
        itemTitle="TimeRecord"
        noEdit={true}
        disableAddItem={props.disableAddItem}
        initialGroups={props.initialGroups}
        deleteDetails={[{ id: 'id', label: 'ID:' }]}
        handleDataItem={handleDataItem}
        deleteDetailText="Also following tables are involved in deletion: -">
        <Column
          field="date"
          title="Date"
          filter="date"
          format="{0:dd.MM.yyyy}"
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="startTime"
          title="Start time"
          format="{0:HH:mm}"
          cell={UTCTimeCell}
          filter="date"
          width="100px"
        />
        <Column
          field="endTime"
          title="End time"
          format="{0:HH:mm}"
          cell={UTCTimeCell}
          filter="date"
          width="100px"
        />
        <Column
          field="workedTime"
          title="Worked time (min)"
          width="150px"
          filter="numeric"
          columnMenu={ColumnMenu}
        />
        <Column
          field="invoiceText"
          title="Invoice Text"
          width="350px"
          cell={TruncatedTooltipCell}
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="user.name"
          title="User"
          width="200px"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="issue.event.name"
          title="Event"
          width="120px"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="remark"
          title="Remark"
          width="400px"
          cell={TruncatedTooltipCell}
          className="truncate"
          columnMenu={ColumnMenu}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default TimerecordsDataGrid
