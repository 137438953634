import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useIssues = issueId => {
  const {
    data: issueHook,
    error,
    mutate
  } = useSWR(`/api/v1/issues/${issueId}`, () =>
    axios
      .get(`/api/v1/issues/${issueId}`)
      .then(res => {
        var parsedData = [res.data.data].map(event => {
          event.dueDate = event.dueDate ? new Date(event.dueDate) : null
          event.createdAt = event.createdAt ? new Date(event.createdAt) : null
          event.updatedAt = event.updatedAt ? new Date(event.updatedAt) : null
          event.timeRecords.map(timeRecord => {
            timeRecord.date = timeRecord.date ? new Date(timeRecord.date) : null
            timeRecord.startTime = timeRecord.startTime
              ? new Date(timeRecord.startTime)
              : null
            timeRecord.endTime = timeRecord.endTime
              ? new Date(timeRecord.endTime)
              : null
            return timeRecord
          })
          event.expenses.map(expense => {
            expense.date = expense.date ? new Date(expense.date) : null
            return expense
          })
          return event
        })
        return { data: parsedData[0] }
      })
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const putHook = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/issues/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postHook = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/issues', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }

  const patchHook = async ({ ...props }) => {
    await csrf()
    axios
      .patch(`/api/v1/issues/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        mutate()
      })
      .catch(error => {})
  }
  const deleteHook = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)

    axios.delete(`/api/v1/issues/${props.dataItem.id}`).then(status => {
      setStatus(status)
      mutate()
    })
  }

  const postTimeRecord = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/timerecords', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }

  const postExpense = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/expenses', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const deleteTimeRecordOrExpense = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)
    var deleteUrl =
      props.dataItem.action == 'deleteExpense' ? 'expenses' : 'timerecords'
    axios.delete(`/api/v1/${deleteUrl}/${props.dataItem.id}`).then(status => {
      setStatus(status)
      mutate()
    })
  }
  useEffect(() => {}, [issueHook, error])

  return {
    issueHook,
    putHook,
    postHook,
    patchHook,
    deleteHook,
    postTimeRecord,
    postExpense,
    deleteTimeRecordOrExpense
  }
}
