import { useState, useEffect } from 'react'
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Button } from '@progress/kendo-react-buttons'
import { FaTrashAlt } from 'react-icons/fa'
import {
  Notification,
  NotificationGroup
} from '@progress/kendo-react-notification'
import { Fade } from '@progress/kendo-react-animation'

const DeleteModal = props => {
  const {
    modalVisible,
    toggleModal,
    item,
    title,
    details,
    detailsText,
    deleteHook,
    setFormButtonsDisabled
  } = props
  const [status, setStatus] = useState(null)
  const [notificationsState, setNotificationsState] = useState({
    success: {
      state: false,
      message: 'Your data was deleted successfully'
    }
  })

  useEffect(() => {
    if (status?.data == 1 && status?.status == 200) {
      toggleModal()
      setNotificationsState({
        ...notificationsState,
        success: { ...notificationsState.success, state: true }
      })
    } else {
      setTimeout(() => {
        setNotificationsState({
          ...notificationsState,
          success: { ...notificationsState.success, state: false }
        })
      }, 10000)
    }
    setFormButtonsDisabled(false)
  }, [status])
  return (
    <div>
      {modalVisible && (
        <Dialog
          className="delete-modal"
          title={
            <div className="text-center mt-4 w-full ml-7">
              <div className="text-3xl font-bold">{title}</div>
              <div
                className="p-1 absolute left-0 right-0 mr-auto ml-auto b rounded-full color-red w-min"
                style={{ top: -50 }}>
                <FaTrashAlt
                  //className="p-1 absolute left-0 right-0 mr-auto ml-auto b rounded-full btn-red"
                  className="m-2"
                  size={50}
                />
              </div>
            </div>
          }
          width={600}
          onClose={toggleModal}>
          <table className="text-lg">
            {details.map((value, i) => (
              <tr key={i}>
                <td className="w-1/4 align-top">{value.label}</td>
                <td>{item[value.id]}</td>
              </tr>
            ))}
          </table>
          <div className="text-lg pt-2">{detailsText}</div>
          <DialogActionsBar>
            <Button onClick={toggleModal}>Cancel</Button>
            <Button
              onClick={() => {
                deleteHook({ setStatus, dataItem: item })
                setFormButtonsDisabled(true)
              }}
              className="btn-red">
              Delete
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <NotificationGroup
        style={{
          right: 10,
          bottom: 4,
          alignItems: 'flex-start',
          flexWrap: 'wrap-reverse',
          zIndex: 100
        }}>
        {notificationsState.success.state && (
          <Fade transitionEnterDuration={800} transitionExitDuration={1000}>
            <Notification
              type={{
                style: 'success',
                icon: true
              }}
              closable={true}
              onClose={() =>
                setNotificationsState({
                  ...notificationsState,
                  success: {
                    ...notificationsState,
                    state: false
                  }
                })
              }>
              {notificationsState.success.message}
            </Notification>
          </Fade>
        )}
      </NotificationGroup>
    </div>
  )
}

export default DeleteModal
