import {
  Grid as DataGrid,
  GridToolbar,
  GridNoRecords
} from '@progress/kendo-react-grid'
import { Input } from '@progress/kendo-react-inputs'
import {
  useState,
  useEffect,
  useCallback,
  cloneElement,
  Fragment,
  useRef
} from 'react'
import { process } from '@progress/kendo-data-query'
import { Button } from '@mui/material'
import DataGridSkeleton from 'components/DataGridSkeleton/Index'
import {
  setExpandedState,
  setGroupIds,
  getGroupIds
} from '@progress/kendo-react-data-tools'
import { Tooltip } from '@progress/kendo-react-tooltip'

const processWithGroups = (data, dataState) => {
  const newDataState = process(data, dataState)
  setGroupIds({
    data: newDataState.data,
    group: dataState.group
  })
  return newDataState
}

const KendoDataGrid = props => {
  const {
    itemHook,
    children,
    setEditItem,
    setEditModalSettings,
    noRecords,
    detailGrid,
    initialGroups,
    initialFilter,
    disableAddItem
  } = props
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)
  const [items, setItems] = useState(null)
  // Search
  const [filterValue, setFilterValue] = useState('')
  // Search
  const [filteredItems, setFilteredItems] = useState(null)
  const [dataState, setDataState] = useState({
    //  take: 10,
    //  skip: 0,
    filter: initialFilter,
    group: initialGroups ?? []
  })
  const input = useRef(null)
  const enterAddModal = () => {
    setEditItem([])
    setEditModalSettings({ mode: 'add', visible: true })
  }
  // Search
  const onFilterChange = ev => {
    let value = ev.value
    setDataState({ ...dataState, group: [] })
    setFilterValue(ev.value)
    let newData = items.filter(item => {
      let match = false
      for (const property in item) {
        if (
          item[property] &&
          item[property]
            .toString()
            .toLocaleLowerCase()
            .indexOf(value.toLocaleLowerCase()) >= 0
        ) {
          match = true
        }
        if (typeof item[property] === 'object') {
          for (const arprop in item[property]) {
            if (
              item[property][arprop] &&
              item[property][arprop]
                .toString()
                .toLocaleLowerCase()
                .indexOf(value.toLocaleLowerCase()) >= 0
            ) {
              match = true
            }
          }
        }
        //ensure that toLocaleDateString matches with the displayed format in the Column
        //if not, modify the logic so that you can compare same string from the cell with the input
        if (
          item[property] &&
          item[property].toLocaleDateString &&
          item[property].toLocaleDateString().indexOf(value) >= 0
        ) {
          match = true
        }
      }
      return match
    })
    let clearedPagerDataState = { ...dataState }
    let processedData = []
    if (newData.length != 0) {
      processedData = process(newData, dataState)
      setFilteredItems(newData)
    } else {
      setFilteredItems(processedData)
    }
  }

  // Search
  const cellRender = useCallback(
    (td, props) => {
      if (td && props.rowType === 'data') {
        const value = td.props.children
        if (
          filterValue &&
          filterValue.length > 0 &&
          value &&
          value.substr &&
          value.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >=
            0
        ) {
          const children = getHighlight(value, filterValue.toLocaleLowerCase())
          return cloneElement(td, [props], [children])
          return cloneElement(td, [props], [value])
        }
        return td
      }
      return td
    },
    [filterValue]
  )
  // Search
  const getHighlight = (value, filter) => {
    let index = value
      .toLocaleLowerCase()
      .indexOf(filterValue.toLocaleLowerCase())
    if (index >= 0) {
      let left = value.substr(0, index)
      let right = value.substring(index + filter.length, value.length)
      return (
        <Fragment>
          {left}
          <span className="highlight">
            {value.substr(index, filter.length)}
          </span>
          {getHighlight(right, filter)}
        </Fragment>
      )
    }
    return value
  }
  const [collapsedState, setCollapsedState] = useState([])
  const onExpandChange = useCallback(
    event => {
      const item = event.dataItem
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter(groupId => groupId !== item.groupId)
        setCollapsedState(newCollapsedIds)
      }
    },
    [collapsedState]
  )
  const expandChange = event => {
    if (event.dataIndex != -1) {
      let newData = filteredItems.map(item => {
        if (item.id === event.dataItem.id) {
          item.expanded = !event.dataItem.expanded
        }
        return item
      })
      setFilteredItems(newData)
    } else {
    }
  }
  if (filteredItems && filteredItems.length != 0) {
    var pdata = processWithGroups(filteredItems, dataState)
  } else {
    var pdata = { data: [] }
  }
  const onGroupsCollapse = () => {
    const dataStateWithoutPaging = processWithGroups(filteredItems, dataState)
    setCollapsedState(
      getGroupIds({
        data: dataStateWithoutPaging.data
      })
    )
  }
  const onGroupsExpand = () => {
    setCollapsedState([])
  }
  const newData = setExpandedState({
    ...pdata,
    collapsedIds: collapsedState
  })
  useEffect(() => {
    if (itemHook) {
      setInitialDataLoaded(true)
      setItems(itemHook.data)
      setFilteredItems(itemHook.data)
      const dataStateWithoutPaging = processWithGroups(itemHook.data, dataState)
      if (!initialDataLoaded) {
        setCollapsedState(
          getGroupIds({
            data: dataStateWithoutPaging.data
          })
        )
      }
    }
  }, [itemHook])

  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.ctrlKey === true && e.key == 'q') {
        if (input) {
          input.current.focus()
        }
      }
    })
  }, [])

  return (
    <Tooltip openDelay={100} position="top" anchorElement="target">
      <DataGrid
        size={'small'}
        detail={detailGrid}
        {...dataState}
        // Search
        cellRender={cellRender}
        groupable={true}
        //pageable={true}
        sortable={true}
        //total={filteredItems?.length}
        //lockGroups={true}
        rowRender={(trElement, props) => {
          var color = null
          if (
            props.dataItem.dueDate <= new Date() &&
            props.dataItem.state != 2 &&
            props.dataItem.priority != 3
          ) {
            color = {
              backgroundColor: 'rgb(255, 153, 51, 0.32)'
            }
          } else if (
            props.dataItem.dueDate > new Date() &&
            props.dataItem.priority == 3 &&
            props.dataItem.state != 2
          ) {
            color = {
              backgroundColor: 'rgb(255, 255, 204, 0.32)'
            }
          } else if (
            props.dataItem.dueDate <= new Date() &&
            props.dataItem.priority == 3 &&
            props.dataItem.state != 2
          ) {
            color = {
              backgroundColor: 'rgb(243, 23, 0, 0.32)'
            }
          }
          const trProps = {
            style: color
          }
          return cloneElement(
            trElement,
            {
              ...trProps
            },
            trElement.props.children
          )
        }}
        onExpandChange={onExpandChange}
        expandField="expanded"
        onDataStateChange={e => {
          setDataState(e.dataState)
        }}
        className="fill-page"
        style={{
          width: '100%',
          maxHeight: 'calc(100vh - 155px)',
          minHeight: '280px'
        }}
        data={newData}
        //data={filteredItems ? process(filteredItems, dataState) : []}
      >
        <GridToolbar>
          <div>
            {!disableAddItem && <Button onClick={enterAddModal}>Add</Button>}
            {(detailGrid || dataState.group.length) !== 0 && (
              <div>
                <Button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={onGroupsCollapse}>
                  {/* collapsedState.length ? 'Expand' : */ 'Collapse'} all
                </Button>
                <Button
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={onGroupsExpand}>
                  Expand all
                </Button>
              </div>
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              right: '10px'
            }}>
            {dataState.filter && (
              <Button
                onClick={() => setDataState({ ...dataState, filter: null })}>
                Clear Filter
              </Button>
            )}
            <span className="ml-3">
              <Input
                ref={input}
                value={filterValue}
                onChange={onFilterChange}
                disabled={dataState.group.length !== 0 && false}
                style={{
                  width: '250px',
                  border: '2px solid #ccc',
                  borderRadius: '10px',
                  boxShadow: 'inset 0px 0px 0.5px 0px rgba(0,0,0,0.0.1)'
                }}
                placeholder="Search"
              />
            </span>
            {filterValue ? (
              <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  bottom: '10px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  onFilterChange({ value: '' })
                }}>
                X
              </div>
            ) : (
              <div
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  bottom: '10px'
                }}>
                STRG + Q
              </div>
            )}
          </div>
        </GridToolbar>
        <GridNoRecords>
          {!filteredItems ? <DataGridSkeleton /> : noRecords}
        </GridNoRecords>
        {children}
      </DataGrid>
    </Tooltip>
  )
}

export default KendoDataGrid
