import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import ProjectsDataGrid from 'components/pages/crm/projects/ProjectsDataGrid/Index'
import { useProjects } from 'hooks/projects'
import { useProjectTypes } from 'hooks/projectTypes'

const Projects = () => {
  const { projectsHook, putProject, postProject, deleteProject } = useProjects({
    query: '?includeEvents=true'
  })
  const { projectTypesHook } = useProjectTypes({ query: '' })
  document.title = 'Projects'
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Projects</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-col items-center shadow-lg">
                  <ProjectsDataGrid
                    itemHook={projectsHook}
                    putHook={putProject}
                    postHook={postProject}
                    deleteHook={deleteProject}
                    projectTypesHook={projectTypesHook?.data ?? []}
                    initialGroups={[
                      {
                        field: 'projectType.label'
                      }
                    ]}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Projects
