import {
  Grid as DataGrid,
  GridColumn as Column
} from '@progress/kendo-react-grid'
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter
} from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import { useTranslation } from 'react-i18next'
import CellStateTranslator from 'components/DataGridCellTranslator/Index'
import CompanyContactCell from 'components/KendoDataGrid/CompanyContactCell/Index'

const SponsoringsDataGrid = props => {
  const { t } = useTranslation(['crm'])
  const handleDataItem = dataItem => {
    var value = []
    if (dataItem.sponsoringBenefitss) {
      Object.keys(dataItem.sponsoringBenefitss).map((keyName, i) => {
        if (dataItem.sponsoringBenefitss[keyName]) {
          value.push({
            sponsoring_benefit_id: keyName,
            remark:
              dataItem.sponsoringBenefitss[keyName] === true
                ? '1'
                : dataItem.sponsoringBenefitss[keyName]
          })
        }
      })
      dataItem.sponsoringBenefits = value
    } else {
      dataItem.sponsoringBenefits = []
    }
    dataItem.state = dataItem.state.value ?? dataItem.state
    dataItem.companyId = dataItem.companyId.id ?? dataItem.companyId
    dataItem.mainContactId =
      dataItem.mainContactId?.id ?? dataItem.mainContactId
    dataItem.administrationContactId =
      dataItem.administrationContactId?.id ?? dataItem.administrationContactId
    dataItem.marketingContactId =
      dataItem.marketingContactId?.id ?? dataItem.marketingContactId
    dataItem.onSite1Id = dataItem.onSite1Id?.id ?? dataItem.onSite1Id
    dataItem.onSite2Id = dataItem.onSite2Id?.id ?? dataItem.onSite2Id
    dataItem.eventId = dataItem.eventId.id ?? dataItem.eventId
    return dataItem
  }
  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        DetailGrid={DetailGrid}
        KendoForm={props1 =>
          KendoForm({
            ...props1,
            sponsoringBenefitsHook: props.sponsoringBenefitsHook,
            companyContactsHook: props.companyContactsHook,
            eventsHook: props.eventsHook,
            companiesHook: props.companiesHook
          })
        }
        noRecords={t('sponsorings.dataGrid.noRecords')}
        itemTitle={t('sponsorings.dataGrid.title')}
        initialGroups={props.initialGroups}
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'sponsoringAmount', label: 'Sponsoring amount:' },
          { id: 'state', label: 'State:' },
          { id: 'remark', label: 'Remark:' }
        ]}
        handleDataItem={handleDataItem}
        deleteDetailText="Also following tables are involved in deletion: Sponsorings contactpoints">
        <Column field="id" title="ID" width="80px" />
        <Column
          field="event.project.name"
          title={t('sponsorings.dataGrid.projectName')}
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="event.name"
          title={t('sponsorings.dataGrid.eventName')}
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="company.name"
          title={t('sponsorings.dataGrid.companyName')}
          width="250px"
          className="truncate"
          columnMenu={ColumnMenu}
        />
        <Column
          field="category"
          title={t('sponsorings.dataGrid.category')}
          width="100px"
          className="truncate"
          columnMenu={props =>
            ColumnMenuCheckboxFilter(props, [
              { category: '1' },
              { category: '2' },
              { category: '3' },
              { category: '4' },
              { category: '5' }
            ])
          }
        />
        <Column
          field="state"
          title={t('sponsorings.dataGrid.state')}
          width="200px"
          className="truncate"
          columnMenu={props =>
            ColumnMenuCheckboxFilter(props, [
              { state: '0' },
              { state: '1' },
              { state: '2' },
              { state: '3' },
              { state: '4' },
              { state: '5' }
            ])
          }
          cell={props => (
            <CellStateTranslator {...props} tbl="sponsorings.form" ns="crm" />
          )}
        />
        <Column
          field="sponsoringAmount"
          title={t('sponsorings.dataGrid.sponsoringAmount')}
          width="130px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="mainContact"
          title={t('sponsorings.dataGrid.mainContact')}
          width="200px"
          columnMenu={ColumnMenu}
          cell={CompanyContactCell}
        />
        <Column
          field="administrationContact"
          title={t('sponsorings.dataGrid.administrationContact')}
          width="200px"
          columnMenu={ColumnMenu}
          cell={CompanyContactCell}
        />
        <Column
          field="marketingContact"
          title={t('sponsorings.dataGrid.marketingContact')}
          width="200px"
          columnMenu={ColumnMenu}
          cell={CompanyContactCell}
        />
        <Column
          field="onSite1"
          title={t('sponsorings.dataGrid.onSite1')}
          width="200px"
          columnMenu={ColumnMenu}
          cell={CompanyContactCell}
        />
        <Column
          field="onSite2"
          title={t('sponsorings.dataGrid.onSite2')}
          width="200px"
          columnMenu={ColumnMenu}
          cell={CompanyContactCell}
        />
        <Column
          field="remark"
          title={t('sponsorings.dataGrid.remark')}
          width="600px"
          className="truncate"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default SponsoringsDataGrid

const DetailGrid = props => {
  const { t } = useTranslation(['crm'])
  return (
    <DataGrid
      sortable={true}
      filterable={false}
      scrollable="scrollable"
      style={{
        width: '100%'
      }}
      data={props.dataItem.sponsoringBenefits}>
      <Column field="id" title="ID" width="100px" columnMenu={ColumnMenu} />
      <Column
        field="label"
        title={t('sponsorings.dataGrid.detailGrid.label')}
        width="300px"
        columnMenu={ColumnMenu}
      />
      <Column
        field="remark"
        title={t('sponsorings.dataGrid.remark')}
        width="500px"
        columnMenu={ColumnMenu}
      />
    </DataGrid>
  )
}
