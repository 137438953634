import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import IssuesDataGrid from 'components/pages/crm/issues/IssuesDataGrid/Index'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import { useIssues } from 'hooks/issues'
import { useTimeRecords } from 'hooks/timeRecords'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/auth'
import { useEffect, useState } from 'react'
import TimerecordsDataGrid from 'components/pages/user/dashboard/TimerecordDataGrid/Index'
import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { useUsers } from 'hooks/user'
import { useEvents } from 'hooks/events'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import './index.css'

const UserDashboard = () => {
  const { t } = useTranslation('common')
  document.title = 'User Dashboard'
  const { user } = useAuth()
  const [timeRecordFilter, setTimeRecordFilter] = useState({
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    user: user
  })
  const [timeRecordFilterToday, setTimeRecordFilterToday] = useState({
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    user: user
  })
  const { usersHook } = useUsers({
    query: '?is_active[eq]=1&order_by=id'
  })
  const { eventsHook } = useEvents({
    query: '?order_by=-name&includeProject=true'
  })
  const { issuesHook, putHook, postHook, deleteHook, patchHook } = useIssues({
    query: '?includeProject=true'
  })
  const { timerecordsHook } = useTimeRecords(
    `date[gte]=${timeRecordFilter.startDate?.toISOString()}&` +
      `date[lte]=${timeRecordFilter.endDate?.toISOString()}&` +
      `user_id[eq]=${timeRecordFilter.user?.id}`
  )
  const { timerecordsHook: timerecordsTodayHook } = useTimeRecords(
    `date[gte]=${timeRecordFilterToday.startDate?.toISOString()}&` +
      `date[lte]=${timeRecordFilterToday.endDate?.toISOString()}&` +
      `user_id[eq]=${timeRecordFilter.user?.id}`
  )
  const [totalTime, setTotalTime] = useState(0)
  const [totalTimeToday, setTotalTimeToday] = useState(0)
  const [selected, setSelected] = useState(0)
  const handleDateRangePickerChange = event => {
    setTimeRecordFilter({
      ...timeRecordFilter,
      startDate: event.value.start,
      endDate: event.value.end
        ? new Date(event.value.end.setHours(23))
        : event.value.end
    })
  }
  const handleUserDropDownChange = event => {
    setTimeRecordFilter({
      ...timeRecordFilter,
      user: event.value
    })
  }
  const handleSelect = e => {
    setSelected(e.selected)
  }
  useEffect(() => {
    if (timerecordsHook?.data && timerecordsHook?.data.length != 0) {
      var tempTime = 0
      timerecordsHook.data.map(tr => (tempTime = tempTime + tr.workedTime))
      setTotalTime((tempTime / 60).toFixed(1))
    }
  }, [timerecordsHook])
  useEffect(() => {
    if (timerecordsTodayHook?.data && timerecordsTodayHook?.data.length != 0) {
      var tempTime = 0
      timerecordsTodayHook.data.map(tr => (tempTime = tempTime + tr.workedTime))
      setTotalTimeToday((tempTime / 60).toFixed(1))
    }
  }, [timerecordsTodayHook])
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">User Dashboard</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 shadow-lg">
                  <TabStrip selected={selected} onSelect={handleSelect}>
                    <TabStripTab
                      title="Time Today"
                      contentClassName="k-animation-container-w-full child-div-max-w-full">
                      <div className="text-lg">
                        Gesamte Zeit heute: {totalTimeToday}h
                      </div>
                      <TimerecordsDataGrid
                        itemHook={timerecordsTodayHook}
                        disableAddItem={true}
                        //duplicate={true}
                        initialGroups={[]}
                        /* initialFilter={{
                          filters: [
                            {
                              field: 'user.name',
                              operator: 'contains',
                              value: user.name
                            }
                          ],
                          logic: 'and'
                        }} */
                      />
                    </TabStripTab>
                    <TabStripTab
                      title="Issues"
                      contentClassName="k-animation-container-w-full child-div-max-w-full">
                      <IssuesDataGrid
                        itemHook={issuesHook}
                        putHook={putHook}
                        postHook={postHook}
                        deleteHook={deleteHook}
                        patchHook={patchHook}
                        disableAddItem={true}
                        usersHook={usersHook?.data ?? []}
                        eventsHook={eventsHook?.data ?? []}
                        duplicate={true}
                        initialGroups={[]}
                        initialFilter={{
                          filters: [
                            {
                              field: 'user.name',
                              operator: 'contains',
                              value: user.name
                            },
                            {
                              filters: [
                                {
                                  field: 'state',
                                  operator: 'eq',
                                  value: '0'
                                },
                                {
                                  field: 'state',
                                  operator: 'eq',
                                  value: '1'
                                },
                                {
                                  field: 'state',
                                  operator: 'eq',
                                  value: '3'
                                }
                              ],
                              logic: 'or'
                            }
                          ],
                          logic: 'and'
                        }}
                      />
                    </TabStripTab>
                    <TabStripTab
                      title="Timerecords"
                      contentClassName="k-animation-container-w-full child-div-max-w-full">
                      <Grid container spacing={2}>
                        <Grid xs={12} lg={12} xl={12}>
                          <DateRangePicker
                            className=""
                            onChange={handleDateRangePickerChange}
                            value={{
                              start: timeRecordFilter.startDate,
                              end: timeRecordFilter.endDate
                            }}
                            format="dd.MM.yyyy"
                          />
                        </Grid>
                        {(user?.id == 2 || user?.id == 1) && (
                          <Grid xs={4} lg={4} xl={4}>
                            {/* Filter by user disbaled for now */}
                            <DropDownList
                              className=""
                              data={usersHook?.data}
                              textField="name"
                              onChange={handleUserDropDownChange}
                              dataItemKey="id"
                              value={timeRecordFilter.user}
                            />
                          </Grid>
                        )}
                      </Grid>
                      <div className="text-lg">Gesamte Zeit: {totalTime}h</div>
                      <TimerecordsDataGrid
                        itemHook={timerecordsHook}
                        //duplicate={true}
                        disableAddItem={true}
                        initialGroups={[]}
                        /* initialFilter={{
                          filters: [
                            {
                              field: 'user.name',
                              operator: 'contains',
                              value: user.name
                            }
                          ],
                          logic: 'and'
                        }} */
                      />
                    </TabStripTab>
                  </TabStrip>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default UserDashboard
