import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import CompanyContactsDataGrid from 'components/pages/crm/companyContacts/CompanyContactsDataGrid/Index'
import { useCompanyContacts } from 'hooks/companyContacts'
import { useCompanies } from 'hooks/companies'

const CompanyContacts = () => {
  const {
    companyContactsHook,
    putCompanyContact,
    postCompanyContact,
    deleteCompanyContact
  } = useCompanyContacts({ query: '?includeCompany=true' })
  const { companiesHook } = useCompanies({
    query: '?order_by=name&includeCompanyContacts=true'
  })
  document.title = 'Company Contacts'
  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Company contacts</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="p-2 flex justify-center flex-col items-center shadow-lg">
                  <CompanyContactsDataGrid
                    itemHook={companyContactsHook}
                    putHook={putCompanyContact}
                    postHook={postCompanyContact}
                    deleteHook={deleteCompanyContact}
                    companiesHook={companiesHook?.data ?? []}
                    duplicate={true}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default CompanyContacts
