const ApplicationLogo = props => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 204.77 74.27"
    {...props}>
    <text className="cls-1" transform="translate(0 67.91)">
      I
      <tspan className="cls-2" x="7.77" y="0">
        n
      </tspan>
      <tspan x="24.81" y="0">
        fini
      </tspan>
      <tspan className="cls-3" x="66.16" y="0">
        t
      </tspan>
      <tspan x="76.78" y="0">
        e Science
      </tspan>
    </text>
    <path
      className="cls-4"
      d="M200.39,19.71l-9.78-5.65a3.14,3.14,0,0,0-3.14,0L173.36,22.2,162,15.65a9.3,9.3,0,0,0-4.34-1,9.93,9.93,0,0,0-5,1.38l-9.78,5.64a3.14,3.14,0,0,0-1.57,2.72V35.81a9.73,9.73,0,0,0,4.37,8.13l10.38,6a3.14,3.14,0,0,0,3.14,0l14.14-8.16L184.07,48a9.92,9.92,0,0,0,5,1.38,9.35,9.35,0,0,0,4.34-1l10.37-6a3.14,3.14,0,0,0,1.57-2.72v-12a9.67,9.67,0,0,0-5-7.94m3.15,21.36-10.71,6.19a8.2,8.2,0,0,1-3.74.87,8.74,8.74,0,0,1-4.41-1.22l-10.13-5.84,10.72-6.2A8.19,8.19,0,0,1,189,34a8.72,8.72,0,0,1,4.24,1.12l10.3,6M189.65,27.3V14.91l10.13,5.85a8.57,8.57,0,0,1,4.37,6.88V40l-10.3-5.95a8.57,8.57,0,0,1-4.2-6.77M158.23,37.38s.05-5,4.24-7.49l26-15V27.28a8.55,8.55,0,0,1-4.28,6.83l-25.91,15ZM172.12,22.9l-10.26,5.92A8.74,8.74,0,0,1,157.58,30a8.22,8.22,0,0,1-3.73-.87L143.12,22.9l10.14-5.85a8.75,8.75,0,0,1,4.41-1.22,8.19,8.19,0,0,1,3.74.87ZM157,49.08l-10.73-6.19a8.55,8.55,0,0,1-3.77-7.08V24l10.72,6.19A8.57,8.57,0,0,1,157,37.38Z"
      transform="translate(-70.74 -13.64)"
    />
  </svg>
)

export default ApplicationLogo
