import { Skeleton } from '@progress/kendo-react-indicators'

const DataGridSkeleton = () => (
  <div>
    {[...Array(10)].map((i, test) => (
      <Skeleton
        shape={'text'}
        key={test}
        style={{
          width: '100%',
          height: '37px'
        }}
      />
    ))}
  </div>
)
export default DataGridSkeleton
