import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import InfiniteLoader from 'components/InfiniteLoader/Index'

function Crm() {
  const { user } = useAuth({ middleware: 'auth' })
  // eslint-disable-next-line no-nested-ternary
  return user ? (
    user?.is_super_admin ? (
      <Outlet />
    ) : (
      <Navigate to="/user/dashboard" />
    )
  ) : (
    <InfiniteLoader />
  )
}

export default Crm
