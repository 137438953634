import { InfinitySpin, TailSpin } from 'react-loader-spinner'
import ApplicationLogo from 'components/ApplicationLogo'

const InfiniteLoader = () => (
  <div className="flex flex-col w-full h-full justify-center items-center h-screen">
    {/* <InfinitySpin width="1000" color="#007cbb" /> */}
    <ApplicationLogo className="w-80 fill-current text-gray-500" />
    <TailSpin
      height="100"
      width="100"
      color="#007cbb"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass="mt-4"
      visible={true}
    />
  </div>
)
export default InfiniteLoader
