import useSWR from 'swr'
import axios from 'lib/axios'
import { useEffect } from 'react'

export const useProjects = ({ query } = {}) => {
  const {
    data: projectsHook,
    error,
    mutate
  } = useSWR(`/api/v1/projects${query ? query : ''}`, () =>
    axios
      .get(`/api/v1/projects${query ? query : ''}`)
      .then(res => {
        var parsedData = res.data.data.map(project => {
          if (project?.event) {
            project?.events.map(event => {
              event.startDate = event.startDate
                ? new Date(event.startDate)
                : null
              event.endDate = event.endDate ? new Date(event.endDate) : null
              return event
            })
          }
          return project
        })
        return { data: parsedData }
      })
      .catch(error => error.response)
  )

  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const putProject = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    axios
      .put(`/api/v1/projects/${props.dataItem.id}`, props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }
  const postProject = async ({ setErrors, setStatus, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/api/v1/projects', props.dataItem)
      .then(status => {
        setStatus(status)
        mutate()
      })
      .catch(error => {
        setErrors(error.response)
      })
  }

  const deleteProject = async ({ setStatus, ...props }) => {
    await csrf()

    setStatus(null)

    axios.delete(`/api/v1/projects/${props.dataItem.id}`).then(status => {
      setStatus(status)
      mutate()
    })
  }

  useEffect(() => {}, [projectsHook, error])

  return {
    projectsHook,
    putProject,
    postProject,
    deleteProject
  }
}
