import { GridColumn as Column } from '@progress/kendo-react-grid'
import { ColumnMenu } from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'

const SponsoringContactPointsDataGrid = props => {
  const handleDataItem = dataItem => {
    dataItem.userId = dataItem.userId.id ?? dataItem.userId
    dataItem.companyContactId =
      dataItem.companyContactId.id ?? dataItem.companyContactId
    dataItem.eventId = dataItem.eventId.id ?? dataItem.eventId
    dataItem.contactType = dataItem.contactType.value ?? dataItem.contactType
    dataItem.companyId = dataItem.companyId.id ?? dataItem.companyId
    dataItem.date = new Date(dataItem.date.setHours(12))
    dataItem.resubmissionDate = dataItem.resubmissionDate
      ? new Date(dataItem.resubmissionDate.setHours(12))
      : undefined
    return dataItem
  }
  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        KendoForm={KendoForm}
        noRecords="No contactpoints found!"
        itemTitle="Sponsoring Contactpoint"
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'remark', label: 'Remark:' }
        ]}
        handleDataItem={handleDataItem}
        deleteDetailText="Also following tables are involved in deletion: -">
        <Column field="id" title="ID" width="80px" />
        <Column
          field="event.name"
          title="Event Name"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="companyContact.company.nickname"
          title="Company"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="companyContact.firstname"
          title="Contact firstname"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="companyContact.lastname"
          title="Contact lastname"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="companyContact.email"
          title="Contact email"
          width="250px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="companyContact.phone"
          title="Phone"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="user.name"
          title="User name"
          width="250px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="date"
          title="Date"
          width="200px"
          format="{0:dd.MM.yyyy}"
          columnMenu={ColumnMenu}
        />
        <Column
          field="resubmissionDate"
          title="Resubmissiondate"
          width="200px"
          format="{0:dd.MM.yyyy}"
          columnMenu={ColumnMenu}
        />
        <Column
          field="contactType"
          title="Contact type"
          width="150px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="remark"
          title="Remark"
          width="400px"
          className="truncate"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default SponsoringContactPointsDataGrid
