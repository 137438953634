import Drawer from 'components/MainDrawer/Index'
import KendoAppBar from 'components/KendoAppBar/Index'
import React, { useState } from 'react'
import { useAuth } from 'hooks/auth'

const Navigation = ({ children }) => {
  const [openedDrawer, setOpenedDrawer] = useState(localStorage.getItem('openedDrawer') === 'true')
  const { user } = useAuth({ middleware: 'auth' })
  const toggleDrawer = () => {
    localStorage.setItem('openedDrawer', !openedDrawer)
    setOpenedDrawer(!openedDrawer)
  }
  return (
    <main>
      <div
        className={`px-4 pt-2 flex shrink-0 ${
          openedDrawer ? 'sm:ml-64 duration-200' : ''
        } `}>
        <KendoAppBar toggleDrawer={toggleDrawer} />
      </div>
      <Drawer
        user={user}
        openedDrawer={openedDrawer}
        toggleDrawer={toggleDrawer}
      />
      <div
        style={{ height: 'calc(100vh - 63px)' }}
        className={`overflow-y-scroll ${
          openedDrawer ? 'sm:ml-64 duration-200' : ''
        } `}>
        {children}
      </div>
    </main>
  )
}
export default Navigation
