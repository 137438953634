import { getTimeFormated } from 'lib/convinience'

const UTCTimeCell = props => {
  return props.rowType === 'data' ? (
    <td {...props}>{getTimeFormated(props.dataItem[props.field])}</td>
  ) : (
    <td></td>
  )
}

export default UTCTimeCell
