import { TailSpin } from 'react-loader-spinner'
import { Button } from '@progress/kendo-react-buttons'
import { useTranslation } from 'react-i18next'

const KendoFormButton = props => {
  const {
    mode,
    formButtonsDisabled,
    disabled,
    updateText,
    addText,
    cancleEditModal
  } = props
  const { t } = useTranslation('common')
  return (
    <>
      <Button
        onClick={cancleEditModal}
        disabled={formButtonsDisabled}
        type={'button'}>
        {t('button.cancel')}
      </Button>
      {!formButtonsDisabled ? (
        <Button themeColor={'primary'} type={'submit'} disabled={disabled}>
          {mode == 'update' ? updateText : addText}
        </Button>
      ) : (
        <Button themeColor={'primary'} type={'button'} disabled>
          <TailSpin
            height="20"
            width="40"
            color="#3f51b5"
            ariaLabel="tail-spin-loading"
            radius="1"
            visible={true}
          />
        </Button>
      )}
    </>
  )
}
export default KendoFormButton
