import { PDFExport } from '@progress/kendo-react-pdf'
import { QRCode } from '@progress/kendo-react-barcodes'
import './fonts.css'

const NamebadgeTemplate = ({ namebadgePdf, data }) => (
  <PDFExport
    forcePageBreak=".page-break"
    fileName={'Namebadges.pdf'}
    ref={namebadgePdf}
    className="page-container hidden-on-narrow"
    style={{ display: 'none' }}>
    <div className="pdf-page size-a4">
      {data.map((item, index) => (
        <div className={`inner-page ${index != 0 ? 'page-break' : ''}`}>
          <div
            className="absolute leading-tight"
            style={{
              top: '163.9mm',
              fontFamily: 'Open Sans',
              fontSize: 11.65,
              left: '13mm',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.id}
          </div>
          <div
            className="absolute"
            style={{
              left: '129mm',
              top: '10.8mm',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <QRCode
              value={
                Math.floor(1000 + Math.random() * 9000) + item.id
                  ? item.id.toString()
                  : `${Math.floor(1000 + Math.random() * 9000)}`
              }
              style={{
                width: '12mm',
                height: '12mm'
              }}
            />
          </div>
          <div
            className="absolute leading-tight"
            style={{
              transform: 'rotate(90deg)',
              fontSize: 12.78,
              right: '-37.59mm',
              top: '56.39mm',
              left: '100mm',
              maxHeight: '1.25em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.id} | {item.lastname}, {item.firstname}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              top: '163.9mm',
              fontFamily: 'Open Sans',
              fontSize: 11.65,
              left: '80.2mm',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.id}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              top: '163.9mm',
              fontFamily: 'Open Sans',
              fontSize: 9.77,
              left: '53.38mm',
              width: '23.3mm',
              textOverflow: 'hidden',
              textAlign: 'right',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden',
              whiteSpace: 'pre-wrap'
            }}>
            {item.special}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              top: '163.9mm',
              fontFamily: 'Open Sans',
              fontSize: 9.77,
              left: '121mm',
              width: '23.3mm',
              textOverflow: 'hidden',
              textAlign: 'right',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden',
              whiteSpace: 'pre-wrap'
            }}>
            {item.special}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              top: '174.44mm',
              fontFamily: 'Open Sans',
              fontSize: 17.3,
              left: '79.7mm',
              width: '66.16mm',
              maxLines: 2,
              textAlign: 'center',
              fontWeight: 'bold',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.title
              ? `${item.title} ${item.firstname} ${item.lastname}`
              : `${item.firstname} ${item.lastname}`}
          </div>
          <div
            className="absolute"
            style={{
              width: '66.16mm',
              left: '79.7mm',
              top: '190mm',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <QRCode
              value={
                Math.floor(1000 + Math.random() * 9000) + item.id
                  ? item.id.toString()
                  : `${Math.floor(1000 + Math.random() * 9000)}`
              }
              style={{
                width: '18.8mm',
                height: '18.8mm'
              }}
            />
          </div>
          <div
            className="absolute leading-tight"
            style={{
              top: '174.44mm',
              fontFamily: 'Open Sans',
              fontSize: 17.3,
              left: '12mm',
              width: '66.16mm',
              maxLines: 2,
              textAlign: 'center',
              fontWeight: 'bold',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.title
              ? `${item.title} ${item.firstname} ${item.lastname}`
              : `${item.firstname} ${item.lastname}`}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              top: '188mm',
              fontFamily: 'Open Sans',
              fontSize: 11.3,
              left: '12mm',
              width: '66.16mm',
              maxLines: 2,
              textAlign: 'center',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden',
              whiteSpace: 'pre-wrap'
            }}>
            {item.institution}
          </div>
          <div
            className="absolute leading-tight"
            style={{
              top: '198.5mm',
              fontFamily: 'Open Sans',
              fontSize: 14.3,
              left: '12mm',
              width: '66.16mm',
              maxLines: 2,
              textAlign: 'center',
              whiteSpace: 'pre-wrap',
              maxHeight: '2.5em',
              lineHeight: '1.25em',
              wordWrap: 'break-word',
              overflow: 'hidden'
            }}>
            {item.location}
          </div>
        </div>
      ))}
    </div>
  </PDFExport>
)

export default NamebadgeTemplate
