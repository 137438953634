import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import Card from '@mui/material/Card'
import { CardActionArea } from '@mui/material'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { useEvents } from 'hooks/events'
import { useIssues } from 'hooks/issues'
import { useEffect, useState } from 'react'
import { filterChange } from 'components/FormsDropdownFilter/Index'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

//import './index.css'

const MobileTimeTracking = () => {
  const [queryParameters] = useSearchParams()
  const { t } = useTranslation(['common'])
  document.title = 'Mobile Time Tracking'
  const { eventsHook, getEvent } = useEvents({
    query:
      '?includeProject=true&includeDashboard=true&includeIssues=true&order_by=-start_date'
  })
  const navigate = useNavigate()
  const [eventsFiltered, setEventsFiltered] = useState(eventsHook?.data)
  const [event, setEvent] = useState(null)
  const [TimeTracking, setTimeTracking] = useState([])
  const [loading, setLoading] = useState(false)
  const { issuesHook} = useIssues({
    query: `?includeProject=true&event_id[eq]=${event?.id}&order_by=label`
  })
  const [selected, setSelected] = useState(0)
  const handleSelect = e => {
    setSelected(e.selected)
  }
  const handleChange = ev => {
    setLoading(true)
    setEvent(ev.value)
  }
  useEffect(() => {
    if (eventsHook?.data) {
      setEventsFiltered(eventsHook.data)
    }
  }, [eventsHook])

  useEffect(() => {
    if (issuesHook) {
      setTimeTracking(issuesHook.data.filter(issue => issue.priority == 4))
      getEvent({ eventId: event?.id ?? queryParameters.get('eventId'), setEvent: setEvent })
      setLoading(false)
    }
  }, [issuesHook])

  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">
            Mobile {t('drawer.timetracking')} {queryParameters.get('eventId') && `: ${ event?.name}`}
          </Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} className="flex flex-col">
              <Grid xs={12} lg={6} xl={3}>
                <Paper className="px-2 py-2">
                  Please choose an event:
                  <DropDownList
                    data={eventsFiltered}
                    textField="name"
                    dataItemKey="id"
                    onChange={handleChange}
                    filterable={true}
                    onFilterChange={ev =>
                      filterChange(ev, eventsHook.data, setEventsFiltered)
                    }></DropDownList>
                </Paper>
              </Grid>
              {event?.id && (
                <Grid xs={12} lg={6} xl={9} display={{ xs: 'none', lg: 'block' }}>
                  <Paper className="px-2 py-2 h-full flex flex-col justify-center">
                    <p className="text-3xl align-center text-center font-bold">
                      {event.name}
                    </p>
                  </Paper>
                </Grid>
              )}
              {TimeTracking.length > 0 && (
                <Grid xs={12} lg={12} xl={12} display={{ xs: 'none', lg: 'block' }}>
                  <Paper className="px-2 py-2 h-full flex flex-col justify-center">
                    <p className="text-2xl align-center text-center font-bold">
                      {t('drawer.timetracking')}:
                    </p>
                  </Paper>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2} className="flex flex-col py-2">
             
              {TimeTracking.length > 0 &&
                TimeTracking.map(timetrack => (
                  <Grid xs={12} lg={6} xl={4}>
                    <Card>
                      <CardActionArea
                        onClick={() => navigate(`/crm/issues/${  timetrack.id}`)}>
                        <Paper className="px-2 py-2 h-full">
                          <p className="text-xl font-bold">
                            {timetrack.label}
                          </p>{' '}
                          <Grid container spacing={2} className="flex flex-col">
                            <Grid xs={6} lg={6} xl={6}>
                              <p className="text-xl ">
                                Total Time:{' '}
                                {(timetrack.totalWorkedTime / 60).toFixed(1)} h
                              </p>
                            </Grid>
                            {/*  <Grid xs={6} lg={6} xl={6}>
                          <p className="text-xl">
                            Expenses: {timetrack.totalExpenses.toFixed(2)} €
                          </p>
                        </Grid> */}
                          </Grid>
                        </Paper>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>

        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default MobileTimeTracking
