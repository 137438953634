import {
  Grid as DataGrid,
  GridColumn as Column
} from '@progress/kendo-react-grid'
import { ColumnMenu } from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import TruncatedTooltipLinkCell from 'components/KendoDataGrid/TruncatedTooltipLinkCell/Index'
import TruncatedTooltipMailCell from 'components/KendoDataGrid/TruncatedTooltipMailCell/Index'

const ProjectsDataGrid = props => {
  const handleDataItem = dataItem => {
    dataItem.projectTypeId = dataItem.projectTypeId.id ?? dataItem.projectTypeId
    dataItem.projectEmails = dataItem.projectEmails
      ? String(dataItem.projectEmails).split(';')
      : []
    return dataItem
  }
  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        DetailGrid={DetailGrid}
        KendoForm={props1 =>
          KendoForm({ ...props1, projectTypesHook: props.projectTypesHook })
        }
        noRecords="No projects found!"
        itemTitle="Project"
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'name', label: 'Name:' },
          { id: 'projectEmails', label: 'Emails:' },
          { id: 'remark', label: 'Remark:' }
        ]}
        handleDataItem={handleDataItem}
        deleteDetailText="Also following tables are involved in deletion: Issues, Sponsorings, Events">
        <Column field="id" title="ID" filterable={false} width="80px" />
        <Column
          field="internId"
          title="Intern ID"
          width="120px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="name"
          title="Name"
          width="250px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="projectType.label"
          title="Type"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="website"
          title="Website"
          width="200px"
          className="truncate"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipLinkCell}
        />
        <Column
          field="projectEmails"
          title="Project Emails"
          width="250px"
          className="truncate"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipMailCell}
        />
        <Column
          field="remark"
          title="Remark"
          width="600px"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default ProjectsDataGrid

const DetailGrid = props => (
  <DataGrid
    size={'small'}
    sortable={true}
    filterable={false}
    scrollable="scrollable"
    style={{
      width: '100%'
    }}
    data={props.dataItem.events}>
    <Column field="id" title="Event ID" filterable={false} width="100px" />
    <Column field="name" title="Name" width="200px" />
    <Column field="location" title="Location" width="200px" />
    <Column
      field="participantNumber"
      title="Participant Number"
      width="200px"
    />
    <Column
      field="startDate"
      title="Start Date"
      filter="date"
      width="200px"
      format="{0:dd.MM.yyyy}"
    />
    <Column
      field="endDate"
      title="End Date"
      filter="date"
      width="200px"
      format="{0:dd.MM.yyyy}"
    />
    <Column
      field="remark"
      title="Remark"
      width="400px"
      className="truncate"
      cell={TruncatedTooltipCell}
    />
  </DataGrid>
)
