import * as React from 'react'
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid'
import { FaPen, FaTrashAlt, FaExternalLinkAlt, FaCopy } from 'react-icons/fa'

export const MyCommandCell = props => {
  const { dataItem, redirectUrl } = props
  if (props.rowType === 'data') {
    return (
      <td
        //{...props}
        style={{
          ...props.style,
          paddingLeft: '5px',
          paddingRight: '5px'
        }}
        className={`${props.className} k-command-cell px-1`}
        colSpan={props.colSpan}
        role={'gridcell'}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...{
          [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex
        }}>
        <div className="flex rounded-md shadow-sm justify-center" role="group">
          {props.edit && (
            <button
              className="btn btn-blue mr-1"
              onClick={() => props.edit(dataItem)}>
              <FaPen />
            </button>
          )}
          {props.duplicate && (
            <button
              className="btn btn-secondary-blue mr-1"
              onClick={() => props.duplicate(dataItem)}>
              <FaCopy />
            </button>
          )}
          {props.goToUrl && (
            <button
              className="btn btn-secondary-blue mr-1"
              onClick={() => props.goToUrl(dataItem.id, redirectUrl)}>
              <FaExternalLinkAlt />
            </button>
          )}
          {props.delete && !props.noDelete && (
            <button
              className="btn btn-secondary-red"
              onClick={() => props.delete(dataItem)}>
              <FaTrashAlt />
            </button>
          )}
        </div>
      </td>
    )
  } else {
    return <td></td>
  }
}
