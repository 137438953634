export const requiredValidator = value =>
  value ? '' : 'Error: This field is required.'

export const requiredValidatorMaskedTime = value =>
  value &&
  !value.includes('_') &&
  0 <= parseInt(value.slice(0, 2)) &&
  parseInt(value.slice(0, 2)) <= 23 &&
  0 <= parseInt(value.slice(3, 5)) &&
  parseInt(value.slice(3, 5)) <= 59
    ? ''
    : 'Error: Please provide a valid time'

export const requiredValidatorNumeric = value =>
  value || value == 0 ? '' : 'Error: This field is required.'
