import React, { useEffect, useRef, useState } from 'react'
import './index.css'
import './fonts.css'
import { Button } from '@progress/kendo-react-buttons'
import {leadingZero } from 'lib/convinience'
import { TextArea, NumericTextBox } from '@progress/kendo-react-inputs'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Label } from '@progress/kendo-react-labels'
import { filterChange } from 'components/FormsDropdownFilter/Index'
import { useCompanies } from 'hooks/companies'
import { useOrders } from 'hooks/orders'
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout'
import InvoicesDataGrid from '../InvoicesDataGrid'
import InvoiceTemplate from './template'
import axios from 'lib/axios'

const INITIAL_INVOICE_DATA = {
  orderId: '?',
  recipientData: '',
  companyData:
    'Infinite Science GmbH\nMaria-Goeppert-Straße 1\nMFC1 - Technikzentrum Lübeck\n23562 Lübeck\nGermany\n\ninvoices@infinite-science.de\nwww.infinite-science.de',
  companyDataOneLine:
    'Infinite Science GmbH · Maria-Goeppert-Straße 1 · D - 23562 Lübeck',
  date: new Date(),
  lang: 'DE',
  invoiceDescription: '',
  vat: 19,
  accountingArea: new Date().getFullYear() % 100,
  periodOfService: new Date().toLocaleDateString('de-DE', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit'
  }),
  selectedPaymentMethod: 'Vorkasse',
  selectedPaymentMethodDescription:
    'Wir bitten um Überweisung ohne Abzug innerhalb von 10 Tagen.\nKosten für internationale Überweisungen sind vom Kunden zu tragen.\n\nEmpfänger: Infinite Science GmbH\nBIC: BYLADEM1001 (Deutsche Kreditbank Berlin)\nIBAN: DE23 1203 0000 1020 2838 40',
  items: [
    {
      description: '',
      quantity: 1,
      price: 0
    }
  ],
  readyForExport: false,
  state: '0',
  reference: ''
}
function Invoice() {
  const { companiesHook } = useCompanies({
    query: '?includeCompanyContacts=true'
  })
  const pdfExportComponent = useRef(null)
  const [companiesFiltered, setCompaniesFiltered] = useState([])
  const [invoiceData, setInvoiceData] = useState(INITIAL_INVOICE_DATA)
  const { ordersHook, getOrders, postOrder, putOrder } = useOrders(
    `accounting_area[eq]=${invoiceData.accountingArea}&order_by=-order_id`
  )
  const [selected, setSelected] = useState(0)
  const [buttonDisabled, setButtonDisabled] = useState(0)
  const handleSelect = e => {
    setSelected(e.selected)
  }
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const onChangeInvoiceData = event => {
    let id = event.target.element?.current?.id ?? event.target.element.id
    setInvoiceData({ ...invoiceData, [id]: event.value })
  }
  const handleExportWithComponent = event => {
    pdfExportComponent.current.save()
  }
  const removeItem = index => {
    let newItems = invoiceData.items
    newItems.splice(index, 1)
    setInvoiceData({
      ...invoiceData,
      items: [...newItems]
    })
  }
  const changeItem = (index, key, value) => {
    let newItems = invoiceData.items
    newItems[index][key] = value
    setInvoiceData({
      ...invoiceData,
      items: [...newItems]
    })
  }
  const donwloadPDF = async props => {
    try {
      const filename = `${
        props.storno ? props.dataItem.filenameStorno : props.dataItem.filename
      }.pdf`
      await axios.get('/sanctum/csrf-cookie')
      const response = await axios.get(
        `/api/v1/download-invoice/${
          props.storno ? props.dataItem.filenameStorno : props.dataItem.filename
        }`,
        {
          responseType: 'blob' 
        }
      )

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }
  useEffect(() => {
    setCompaniesFiltered(companiesHook?.data)
  }, [companiesHook])

  useEffect(() => {
    if (status?.status == 201) {
      donwloadPDF({ dataItem: { filename: status.data.data.filename } })
      setInvoiceData(INITIAL_INVOICE_DATA)
      setStatus([])
    }
    if (status?.status || errors?.status) setButtonDisabled(false)
  }, [status, errors])

  useEffect(() => {
    if (invoiceData.readyForExport)
      pdfExportComponent.current.save(() => {
        setInvoiceData(INITIAL_INVOICE_DATA)
      })
  }, [invoiceData])
  useEffect(() => {
    if (ordersHook?.data)
      setInvoiceData({
        ...invoiceData,
        orderId: ordersHook?.data[0] ? ordersHook.data[0].orderId + 1 : 1,
        reference:
          invoiceData.accountingArea +
          leadingZero(
            ordersHook?.data[0] ? ordersHook.data[0].orderId + 1 : 1,
            5
          )
      })
  }, [ordersHook])
  return (
    <div className="flex max-w-full">
      <div className="ml-5 max-w-full">
        <div
          style={{
            position: 'absolute',
            left: '-1000px',
            top: 0
          }}>
          <InvoiceTemplate
            pdfExportComponent={pdfExportComponent}
            invoiceData={invoiceData}
            ordersHook={ordersHook}
          />
        </div>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title="Create">
            <div className="flex flex-row flex-wrap">
              <InvoiceTemplate
                invoiceData={invoiceData}
                ordersHook={ordersHook}
              />
              <div className="ml-3">
                <div className="flex">
                  <div div className="m-2 flex-1">
                    <Label editorId={'lang'} editorValue={invoiceData.lang}>
                      Language:
                    </Label>
                    <DropDownList
                      id="lang"
                      disabled={true}
                      data={['DE', 'EN']}
                      value={invoiceData.lang}
                      onChange={onChangeInvoiceData}
                    />
                  </div>
                  <div div className="m-2 flex-1">
                    <Label editorId={'vat'} editorValue={invoiceData.vat}>
                      VAT:
                    </Label>
                    <DropDownList
                      id="vat"
                      data={[0, 7, 19]}
                      value={invoiceData.vat}
                      onChange={onChangeInvoiceData}
                    />
                  </div>
                  <div div className="m-2 flex-1">
                    <Label editorId={'accountingArea'} editorValue={invoiceData.accountingArea}>
                      Nummernkreis:
                    </Label>
                    <DropDownList
                      id="accountingArea"
                      disabled={false}
                      data={[2023, 24, 25, 26, 27, 28, 29, 30]}
                      value={invoiceData.accountingArea}
                      onChange={onChangeInvoiceData}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <Label
                    editorId={'recipientData'}
                    editorValue={invoiceData.recipientData}>
                    Recipient data:
                  </Label>
                  <DropDownList
                    style={{ width: '100%' }}
                    name={'companyId'}
                    label={'Company'}
                    filterable={true}
                    onFilterChange={event =>
                      filterChange(
                        event,
                        companiesHook?.data,
                        setCompaniesFiltered
                      )
                    }
                    textField="name"
                    onChange={e => {
                      setInvoiceData({
                        ...invoiceData,
                        recipientData: getRecipentData(e.value)
                      })
                    }}
                    dataItemKey="id"
                    data={companiesFiltered}
                  />
                </div>
                <div className="mb-3">
                  <TextArea
                    rows={6}
                    id="recipientData"
                    value={invoiceData.recipientData}
                    onChange={onChangeInvoiceData}
                  />
                </div>
                <div div className="mb-3">
                  <Label
                    editorId={'invoiceDescription'}
                    editorValue={invoiceData.invoiceDescription}>
                    Invoice description (under the invoice number):
                  </Label>
                  <TextArea
                    rows={2}
                    id="invoiceDescription"
                    value={invoiceData.invoiceDescription}
                    onChange={onChangeInvoiceData}
                  />
                </div>
                <div className="mb-3">
                  Items:
                  {invoiceData.items.map((item, index) => (
                    <div>
                      <div>
                        <DropDownList
                          style={{ width: '100%' }}
                          label={'Items'}
                          onChange={event =>
                            changeItem(index, 'description', event.value)
                          }
                          data={[
                            'Sponsoring',
                            'Organisationsleistungen sowie Durchführung\n\nDetailaufstellung der erbrachten Leistungen siehe Anlage.'
                          ]}
                        />
                      </div>
                      <div className="flex">
                        <div style={{ flex: 10, margin: 5 }}>
                          <Label>Description:</Label>
                          <TextArea
                            rows={3}
                            value={item.description}
                            onChange={event =>
                              changeItem(index, 'description', event.value)
                            }></TextArea>
                        </div>
                        <div className="flex flex-col" style={{ flex: 7 }}>
                          <div className="flex" style={{ flex: 7 }}>
                            <div
                              style={{
                                flex: 3,
                                marginTop: 5,
                                marginBottom: 5
                              }}>
                              <Label>Quantity:</Label>
                              <NumericTextBox
                                value={item.quantity}
                                onChange={event =>
                                  changeItem(index, 'quantity', event.value)
                                }></NumericTextBox>
                            </div>
                            <div style={{ flex: 4, margin: 5 }}>
                              <Label>Netto price:</Label>
                              <NumericTextBox
                                format="n2"
                                value={item.price}
                                onChange={event =>
                                  changeItem(index, 'price', event.value)
                                }></NumericTextBox>
                            </div>
                          </div>
                          <Button
                            className="btn btn-secondary-red"
                            onClick={event => removeItem(index)}>
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <Button
                    className="mt-3"
                    onClick={ev =>
                      setInvoiceData({
                        ...invoiceData,
                        items: [
                          ...invoiceData.items,
                          { description: '', quantity: 1, price: 0 }
                        ]
                      })
                    }>
                    Add Item
                  </Button>
                </div>
                <div className="mb-3">
                  <Label
                    editorId={'periodOfService'}
                    editorValue={invoiceData.invoiceDescription}>
                    Period of service:
                  </Label>
                  <TextArea
                    rows={1}
                    id="periodOfService"
                    value={invoiceData.periodOfService}
                    onChange={onChangeInvoiceData}
                  />
                </div>
                <div className="mb-3">
                  <Label
                    editorId={'selectedPaymentMethodDescription'}
                    editorValue={invoiceData.selectedPaymentMethodDescription}>
                    Description of payment method:
                  </Label>
                  <TextArea
                    rows={8}
                    id="selectedPaymentMethodDescription"
                    value={invoiceData.selectedPaymentMethodDescription}
                    onChange={onChangeInvoiceData}
                  />
                </div>
                <div className="mb-3">
                  <Label
                    editorId={'reference'}
                    editorValue={invoiceData.invoiceDescription}>
                    Reference:
                  </Label>
                  <TextArea
                    rows={1}
                    id="reference"
                    disabled={true}
                    value={invoiceData.reference}
                    onChange={onChangeInvoiceData}
                  />
                </div>
                <Button
                  className="m-3 btn btn-primary"
                  primary={true}
                  disabled={buttonDisabled}
                  onClick={event => {
                    setButtonDisabled(true)
                    postOrder({
                      setErrors,
                      setStatus,
                      dataItem: {
                        accountingArea: invoiceData.accountingArea,
                        invoiceDetails: JSON.stringify(invoiceData),
                        state: '0',
                        remark: ''
                      }
                    })
                  }}>
                  Save & Export
                </Button>
              </div>
            </div>
          </TabStripTab>
          <TabStripTab title="List">
            <InvoicesDataGrid
              itemHook={ordersHook}
              putHook={putOrder}
              downloadPDF={donwloadPDF}></InvoicesDataGrid>
          </TabStripTab>
        </TabStrip>
      </div>
    </div>
  )
}

export default Invoice

const getRecipentData = companyData => {
  return `${companyData.name}\n${companyData.street}\n${companyData.areaCode} ${companyData.city}\n${companyData.country}`
}
