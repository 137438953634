import { filterBy } from '@progress/kendo-data-query'

export const filterData = (filter, allItems) => {
  const data = allItems.slice()
  return filterBy(data, filter)
}
export const filterChange = (event, allItems, setFilteredItems) => {
  const newData =
    event.filter.value.length > 1
      ? filterData(event.filter, allItems)
      : allItems.slice()
  setFilteredItems(newData)
}
