import {
  Grid as DataGrid,
  GridColumn as Column
} from '@progress/kendo-react-grid'
import { ColumnMenu } from 'components/DataGridColumnMenu/Index'
import KendoForm from '../Form/Index'
import ItemKendoDataGrid from 'components/ItemKendoDataGrid/Index'
import TruncatedTooltipCell from 'components/KendoDataGrid/TruncatedTooltipCell/Index'
import TruncatedTooltipLinkCell from 'components/KendoDataGrid/TruncatedTooltipLinkCell/Index'
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa'

const CompaniesDataGrid = props => {
  const handleDataItem = dataItem => {
    dataItem.category = dataItem.category?.value ?? dataItem.category
    dataItem.keywords = dataItem.keywords
      ? String(dataItem.keywords).split(';')
      : ''
    return dataItem
  }

  const CommandCellStart = props => (
    <td {...props}>
      <button
        className="btn btn-secondary-blue"
        title="Go to company contacts"
        onClick={() =>
          window.open(
            `/crm/company-contacts?company=${props.dataItem.name}`,
            '_blank'
          )
        }>
        <FaExternalLinkAlt />
      </button>
    </td>
  )
  return (
    <div className="w-full">
      <ItemKendoDataGrid
        {...props}
        KendoForm={KendoForm}
        DetailGrid={DetailGrid}
        noRecords="No companies found!"
        itemTitle="Company"
        deleteDetails={[
          { id: 'id', label: 'ID:' },
          { id: 'name', label: 'Name:' },
          { id: 'category', label: 'Category:' },
          { id: 'street', label: 'Street:' },
          { id: 'city', label: 'City:' },
          { id: 'country', label: 'Country:' }
        ]}
        handleDataItem={handleDataItem}
        // eslint-disable-next-line max-len
        deleteDetailText="Also following tables are involved in deletion: Company contacts, Sponsorings, Sponsoring contactpoints">
        <Column cell={CommandCellStart} width="48px" locked={true} />
        <Column field="id" title="ID" filterable={false} width="50px" />
        <Column width="55px" cell={CopyAdressCell} />
        <Column
          field="nickname"
          title="Short name"
          width="250px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="name"
          title="Name"
          width="200px"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipCell}
        />
        <Column
          field="category"
          title="Category"
          width="200px"
          cell={TruncatedTooltipCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field="website"
          title="Website"
          width="200px"
          className="truncate"
          cell={TruncatedTooltipLinkCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field="keywords"
          title="Keywords"
          width="200px"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipCell}
        />
        <Column
          field="city"
          title="City"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="areaCode"
          title="Postal Code"
          width="200px"
          columnMenu={ColumnMenu}
        />
        <Column
          field="country"
          title="Country"
          width="200px"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipCell}
        />
        <Column
          field="street"
          title="Street"
          width="200px"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipCell}
        />
        <Column
          field="remark"
          title="Remark"
          width="300px"
          columnMenu={ColumnMenu}
          cell={TruncatedTooltipCell}
        />
      </ItemKendoDataGrid>
    </div>
  )
}
export default CompaniesDataGrid

const CopyAdressCell = props => {
  var address = `${props.dataItem.name}\n${props.dataItem.street}\n${props.dataItem.areaCode} ${props.dataItem.city}\n${props.dataItem.country}`

  return (
    <td>
      <button
        className="btn btn-primary"
        title="Copy company address"
        onClick={() => {
          navigator.clipboard.writeText(address)
        }}>
        <FaCopy size={22} title="Copy company address" />
      </button>
    </td>
  )
}

const DetailGrid = props => (
  <DataGrid
    sortable={true}
    filterable={false}
    scrollable="scrollable"
    style={{
      width: '100%'
    }}
    size={'small'}
    data={props.dataItem.companyContacts}>
    <Column field="id" title="ID" filterable={false} width="100px" />
    <Column field="sex" title="Sex" width="100px" columnMenu={ColumnMenu} />
    <Column field="title" title="Title" width="150px" columnMenu={ColumnMenu} />
    <Column
      field="firstname"
      title="Firstname"
      width="200px"
      columnMenu={ColumnMenu}
    />
    <Column
      field="lastname"
      title="Lastname"
      width="200px"
      columnMenu={ColumnMenu}
    />
    <Column
      field="email"
      title="Email"
      width="300px"
      columnMenu={ColumnMenu}
      cell={TruncatedTooltipCell}
    />
    <Column field="phone" title="Phone" width="200px" columnMenu={ColumnMenu} />
    <Column
      field="mobile"
      title="Mobile"
      width="200px"
      columnMenu={ColumnMenu}
    />
    <Column
      field="department"
      title="Department"
      width="400px"
      columnMenu={ColumnMenu}
    />
  </DataGrid>
)
