import { Button, Typography } from '@mui/material'
import * as XLSX from 'xlsx'
import { useState } from 'react'
import { InfinitySpin, TailSpin } from 'react-loader-spinner'

const ExportVimeoVideos = props => {
  const [loading, setLoading] = useState(false)
  const [currentData, setCurrentData] = useState(0)
  const [maxData, setMaxData] = useState('?')
  const { exportData, title } = props

  const handleClick = async () => {
    var data = []
    if (!exportData) {
      setLoading(true)
      const expData = await props.getVimeoExportData({ query: '/1' })
      data = [...data, ...expData.data]
      setMaxData(expData.total)
      setCurrentData(data.length)
      for (let i = 2; i <= Math.ceil(expData.total / expData?.per_page); i++) {
        const expData = await props.getVimeoExportData({ query: `/${i}` })
        data = [...data, ...expData.data]
        setCurrentData(data.length)
      }
      data = parseData(data)
    } else {
      data = parseData(exportData)
    }
    setLoading(false)
    if (data?.length != 0) downloadExcel(data)
  }
  return (
    <>
      <Button onClick={handleClick} disabled={loading}>
        {loading ? (
          <>
            <TailSpin width="20" height={20} color="#007cbb" />{' '}
            <Typography sx={{ marginLeft: 1 }}>
              Exported {currentData} from{' '}
              {maxData}
            </Typography>
          </>
        ) : (
          title
        )}
      </Button>
    </>
  )
}

export default ExportVimeoVideos

const downloadExcel = data => {
  const worksheet = XLSX.utils.json_to_sheet(data)

  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'VimeoVideos')
  XLSX.writeFile(workbook, 'VimeoVideoExport.xlsx')
}

const parseData = data =>
  data.map(vimeoData => ({
    uri: vimeoData?.uri,
    name: vimeoData?.name,
    description: vimeoData?.description,
    type: vimeoData?.type,
    link: vimeoData?.link,
    duration_in_s: vimeoData?.duration,
    width: vimeoData?.width,
    height: vimeoData?.height,
    created_time: new Date(vimeoData?.created_time),
    status: vimeoData?.status,
    parent_folder_name: vimeoData?.parent_folder?.name,
    privacy_add: vimeoData?.privacy?.add,
    privacy_download: vimeoData?.privacy?.download,
    privacy_comments: vimeoData?.privacy?.comments,
    privacy_embed: vimeoData?.privacy?.embed,
    privacy_view: vimeoData?.privacy?.view,
    stats_plays: vimeoData?.stats?.plays
  }))
