import { FaInfo} from 'react-icons/fa'
import { Popover, PopoverActionsBar } from '@progress/kendo-react-tooltip'
import { Button } from '@progress/kendo-react-buttons'
import { useState, useRef } from 'react'

const CompanyInfoCell = props => {
  const [show, setShow] = useState(false)
  const refTd = useRef(null)
  if (props.rowType === 'data') {
    const field = 'company'
    const companyData = props.dataItem[field]

    return (
      <td {...props} ref={refTd}>
        <span className="flex flex-row items-center">
          <FaInfo
            className="mr-2 cursor-pointer"
            onClick={(test, e) => {
              setShow(true)
            }}
          />
          <span
            className="truncate"
            style={{ whiteSpace: 'nowrap', width: '100%' }}>
            {props.dataItem.company.name}
          </span>
        </span>
        <Popover
          show={show}
          className={'w-96'}
          position={'left'}
          anchor={refTd && refTd.current}
          title={
            <>
              <b className="text-lg">{companyData.name}</b>
              <span className="text-lg"> ({companyData.category})</span>
            </>
          }>
          <a href={companyData.website} target="_blank">
            {companyData.website}
          </a>
          <br />
          <br />
          {companyData.street}
          <br />
          {companyData.areaCode} {companyData.city}
          <br />
          {companyData.country}
          <br />
          <br />
          {companyData.remark}
          <br />
          <PopoverActionsBar>
            <Button onClick={() => setShow(false)}>Close</Button>
          </PopoverActionsBar>
        </Popover>
      </td>
    )
  } else {
    return <td></td>
  }
}
export default CompanyInfoCell
