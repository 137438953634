import { GridColumn as Column } from '@progress/kendo-react-grid'
import { useState } from 'react'
import { MyCommandCell } from 'components/DataGridCommandCell/Index'
import EditModal from 'components/EditModal/Index'
import DeleteModal from 'components/DeleteModal/Index'
import KendoDataGrid from 'components/KendoDataGrid/Index'
import { useNavigate, useLocation } from 'react-router-dom'
// eslint-disable-next-line no-restricted-imports
//import KendoForm from '../Form/Index'

const ItemKendoDataGrid = props => {
  //const navigate = useNavigate()
  const location = useLocation()
  const {
    itemHook,
    putHook,
    postHook,
    deleteHook,
    KendoForm,
    children,
    itemTitle,
    deleteDetails,
    deleteDetailText,
    noRecords,
    DetailGrid,
    handleDataItem,
    redirect,
    initialGroups,
    initialFilter,
    redirectUrl,
    duplicate,
    initalFilterValue,
    disableAddItem
  } = props

  const [editItem, setEditItem] = useState(null)

  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const [editModalTitle, setEditModalTitle] = useState(itemTitle)
  const [editModalSettings, setEditModalSettings] = useState({
    visible: false,
    mode: 'update'
  })
  const [formButtonsDisabled, setFormButtonsDisabled] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const toggleDeleteModel = item => {
    setEditItem(item)
    setDeleteModalVisible(!deleteModalVisible)
  }
  const enterEditModal = item => {
    setEditModalTitle(itemTitle)
    setEditItem(item)
    setEditModalSettings({ mode: 'update', visible: true })
  }
  const enterDuplicateModal = item => {
    setEditModalTitle(`${itemTitle} (Duplicate)`)
    setEditItem(item)
    setEditModalSettings({ mode: 'add', visible: true })
  }
  const cancleEditModal = () => {
    setEditModalSettings({ ...editModalSettings, visible: false })
  }

  const goToProjectPage = (id, url) => {
    var redirectionPath = url ?? location.pathname
    window.open(`${redirectionPath}/${id}`, '_blank')
    //navigate(`${redirectionPath}/${id}`)
  }

  const handleSubmit = async dataItem => {
    setFormButtonsDisabled(true)
    dataItem = handleDataItem(dataItem)
    if (editModalSettings.mode == 'update') {
      putHook({ setErrors, setStatus, dataItem })
    } else {
      postHook({ setErrors, setStatus, dataItem })
    }
  }

  const CommandCell = props2 => (
    <MyCommandCell
      {...props2}
      noDelete={props.noDelete}
      edit={enterEditModal}
      duplicate={duplicate ? enterDuplicateModal : null}
      editField="inEdit"
      delete={toggleDeleteModel}
      redirectUrl={redirectUrl}
      goToUrl={redirect ? goToProjectPage : null}
    />
  )
  return (
    <div className="w-full">
      <EditModal
        editModalVisible={editModalSettings.visible}
        cancleEditModal={cancleEditModal}
        mode={editModalSettings.mode}
        errors={errors}
        status={status}
        title={editModalTitle}
        setFormButtonsDisabled={setFormButtonsDisabled}
        setStatus={setStatus}>
        <KendoForm
          mode={editModalSettings.mode}
          handleSubmit={handleSubmit}
          errors={errors}
          item={editItem}
          cancleEditModal={cancleEditModal}
          setItem={setEditItem}
          formButtonsDisabled={formButtonsDisabled}
        />
      </EditModal>
      <DeleteModal
        modalVisible={deleteModalVisible}
        toggleModal={toggleDeleteModel}
        item={editItem}
        formButtonsDisabled={formButtonsDisabled}
        setFormButtonsDisabled={setFormButtonsDisabled}
        deleteHook={deleteHook}
        details={deleteDetails}
        detailsText={deleteDetailText}
        title={`Delete ${itemTitle}?`}
      />
      <KendoDataGrid
        disableAddItem={disableAddItem}
        initalFilterValue={initalFilterValue}
        initialGroups={initialGroups}
        initialFilter={initialFilter}
        detailGrid={DetailGrid}
        itemHook={itemHook}
        setEditItem={setEditItem}
        noDelete={props.noDelete}
        setEditModalSettings={setEditModalSettings}
        noRecords={noRecords}>
        {children}
        {!props.noEdit && (
          <Column cell={CommandCell} width="110px" locked={true} />
        )}
      </KendoDataGrid>
    </div>
  )
}
export default ItemKendoDataGrid
