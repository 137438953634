import AuthenticatedLayout from 'components/Layouts/AuthenticatedLayout'
import Label from 'components/Label'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Unstable_Grid2'
import { useProjects } from 'hooks/projects'
import { MonthView } from '@progress/kendo-react-scheduler'
import { Scheduler } from '@progress/kendo-react-scheduler'
import { useEffect, useState } from 'react'

const Dashboard = () => {
  const { projectsHook } = useProjects({
    query: '?includeEvents=true&project_type_id[contains]=1'
  })
  const [events, setEvents] = useState([])
  var sampleData = []
  if (events) {
    sampleData = events.map(dataItem => ({
      id: dataItem.id,
      start: parseAdjustStart(dataItem.startDate),
      startTimezone: null,
      end: parseAdjustEnd(dataItem.endDate),
      endTimezone: null,
      isAllDay: true,
      title: dataItem.name,
      description: dataItem.reamark,
      recurrenceRule: '',
      recurrenceId: null,
      recurrenceExceptions: null,
      roomId: 1,
      ownerID: 1,
      personId: 1
    }))
  }
  useEffect(() => {
    var events = []
    if (projectsHook?.data) {
      projectsHook.data.map(project => {
        project.events.map(event => {
          events.push(event)
        })
      })
    }
    setEvents(events)
  }, [projectsHook])

  return (
    <div>
      <AuthenticatedLayout>
        <div className="m-4">
          <Label className="text-3xl font-bold mb-2">Dashboard</Label>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={12} xl={12}>
                <Paper className="px-2 py-2">
                  <Scheduler data={sampleData} defaultDate={new Date()}>
                    <MonthView />
                  </Scheduler>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </div>
      </AuthenticatedLayout>
    </div>
  )
}

export default Dashboard

const parseAdjustStart = eventDate => {
  const date = new Date(new Date(eventDate).setHours(1))
  return date
}
const parseAdjustEnd = eventDate => {
  const date = new Date(new Date(eventDate).setHours(23))
  return date
}
